<template>
  <div v-if="contents.status">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl my-5">
      <!--begin::Hero card-->
      <div class="card mb-12 shadow-sm">
        <!--begin::Hero body-->
        <div class="card-body flex-column p-5 mb-5">
          <!--begin::Hero content-->
          <div
            class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center h-lg-300px p-5 p-lg-15 overlay-wrapper gap-10"
          >
            <!--begin::Wrapper-->
            <div
              class="d-flex flex-column align-items-start justift-content-center flex-equal me-5 order-1 order-md-0"
            >
              <!--begin::Title-->
              <h1 class="fw-bolder fs-4 fs-lg-1 text-gray-800 mb-5 mb-lg-5">
                {{ contents.data.page.locale.title }}
              </h1>
              <!--end::Title-->
              <!--begin::Input group-->
              <div class="position-relative w-100 mb-lg-10">
                <span class="text-gray-600 fs-6">
                  {{ contents.data.page.locale.subTitle }}
                </span>
                <span class="ms-3 text-gray-900 fs-4">
                  {{ subscribers.length }}
                </span>
              </div>
              <!--end::Input group-->
              <!--begin::Action-->
              <!-- <a
                href="#"
                class="btn btn-primary fw-bolder fs-5"
                @click="setModalConfigs('create')"
                ><i
                  class="bi bi-plus-circle me-2 fs-4"
                  :class="contents.data.page.setting.buttonIcon"
                ></i
                >{{ contents.data.page.locale.buttonLabel }}</a
              > -->
              <!--end::Action-->
            </div>
            <!--end::Wrapper-->
            <!--begin::Wrapper-->
            <div
              class="flex-equal d-flex align-self-center justify-content-end align-items-center order-0 order-md-1"
            >
              <!--begin::Illustration-->
              <img
                :src="`${backendHost}/images/themes/${contentTheme}/${
                  $store.state.sidebar[contents.name].routeImage
                }`"
                alt=""
                class="mw-100 min-h-100px mh-md-125px mh-lg-225pxs"
              />
              <!--end::Illustration-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Hero content-->
        </div>
        <!--end::Hero body-->
      </div>
      <!--end::Hero card-->
      <!--begin::Card-->
      <div class="card">
        <!--begin::Card body-->
        <div v-if="dataIsEmpty" class="card-body p-20">
          <div class="d-flex flex-center">
            <img
              class="col-md-3"
              :src="`/assets/images/svg/no_data.svg`"
              alt=""
            />
          </div>
        </div>
        <div v-else class="card-body pt-0">
          <!--begin::Table-->
          <div class="d-flex flex-column scroll-y me-n7 pe-7">
            <table
              class="table align-middle table-row-dashed fs-6 gy-5"
              id="kt_table_user"
            >
              <!--begin::Table head-->
              <thead>
                <!--begin::Table row-->
                <tr class="text-start text-muted fw-bolder fs-7 gs-0">
                  <!-- <th class="w-10px pe-2">
                  <div
                    class="form-check form-check-sm form-check-custom form-check-solid me-3"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      data-kt-check="true"
                      data-kt-check-target="#kt_table_users .form-check-input"
                      value="1"
                    />
                  </div>
                </th> -->
                  <th
                    v-for="(header, index) in contents.data.page.table.header"
                    :key="index"
                    :class="header.class"
                  >
                    {{ header.title }}
                  </th>
                </tr>
                <!--end::Table row-->
              </thead>
              <!--end::Table head-->
              <!--begin::Table body-->
              <tbody class="text-gray-600 fw-bold">
                <!--begin::Table row-->
                <tr v-for="(subscriber, index) in subscribers" :key="index">
                  <!--begin::Checkbox-->
                  <!-- <td>
                  <div
                    class="form-check form-check-sm form-check-custom form-check-solid"
                  >
                    <input class="form-check-input" type="checkbox" />
                  </div>
                </td> -->
                  <!--end::Checkbox-->
                  <!--begin::User=-->
                  <td class="d-flex align-items-center">
                    <!--begin::User details-->
                    <a
                      :href="`mailto: ${subscriber.subscriberEmail} `"
                      class="text-gray-800 text-hover-primary mb-1"
                      >{{ subscriber.subscriberEmail }}</a
                    >
                    <!--begin::User details-->
                  </td>
                  <!--end::User=-->
                  <!--begin::Status=-->
                  <td class="text-end">
                    <label
                      class="form-check form-switch form-switch-sm form-check-custom form-check-solid d-flex justify-content-end"
                    >
                      <input
                        :checked="subscriber.subscriberStatus"
                        class="form-check-input"
                        :name="`${subscriber.subscriberEmail}`"
                        type="checkbox"
                        disabled
                      />
                    </label>
                  </td>
                  <!--end::Status=-->
                </tr>
                <!--end::Table row-->
              </tbody>
              <!--end::Table body-->
            </table>
          </div>
          <div v-if="totalPages > 1" class="w-100 text-center mt-5">
          <pagination
          :pages="totalPages"
          v-model="currentPage"
          @update:modelValue="setCurrentPage"
          ></pagination>
        </div>
          <!--end::Table-->
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
    <create-update
      v-if="modalDisplay"
      :contents="contents.data.modal"
      :modalInfo="subscriberInfo"
      @close-modal="setModalConfigs"
    ></create-update>
  </div>
</template>

<script>
import dataService from "../../../services/data";
import serverConfigs from "../../../configs/server.json";

// Tools
import Pagination from "../../tools/Pagination.vue";

import CreateUpdate from "../../modals/CreateUpdate.vue";

export default {
  setup() {},
  components: {
    Pagination,
    CreateUpdate,
  },
  data() {
    return {
      totalPages: 0,
      currentPage: 1,
      subscriberInfo: Object,
      modalDisplay: false,
      dataIsEmpty: true,

      subscribers: [],
      contents: {
        name: "",
        data: {},
        status: false,
      },
      loaderStatus: [false, false],
    };
  },
  computed: {
    contentTheme() {
      return this.$store.state.themeFile.replace(".css", "");
    },
    backendHost() {
      return serverConfigs["serverDomain"]["host"];
    },
    routeLocale() {
      return this.$route.params.locale;
    },
    bodyNode() {
      return document.getElementById("kt_body");
    },
  },
  methods: {
    setModalConfigs(opt, index) {
      this.$store.commit("setLoader", true);
      switch (opt) {
        case "update":
          this.subscriberInfo = { ...this.subscribers[index], status: false };
          this.modalDisplay = !this.modalDisplay;
          this.$store.commit("setLoader", false);
          break;
        case "create":
          this.setupSubscriberInfo();
          this.modalDisplay = !this.modalDisplay;
          this.$store.commit("setLoader", false);
          break;
        case "validate":
          
          if (this.inputValidation(index)) {
            index.id
              ? this.updateSubscriber(index)
              : this.createNewSubscriber(index);
          } else {
            this.$store.commit("setLoader", false);
          }
          break;
        default:
          this.resetSubscriberInfo();
          this.$store.commit("setLoader", false);
          break;
      }
    },
    setCurrentPage(page) {
      
      this.$store.commit("setLoader", true);
      this.readSubscribers(page)
    },
    loaderDone() {
      if (
        this.loaderStatus[0] &&
        this.loaderStatus[1]
        // this.loaderStatus[2]
      ) {
        this.$store.commit("setLoader", false);
      }
    },
    getContents() {
      let data = {
        endPoint: `contents/r/subscribers`,
        locale: `${this.routeLocale}`,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          this.contents = {
            name: res.data.data[0].contentName,
            data: res.data.data[0].contentJson,
            status: true,
          };
          // this.readRoles();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[0] = true;
          this.loaderDone();
        });
    },
    setupSubscriberInfo() {
      for (let counter in this.contents.data.modal.fields) {
        switch (this.contents.data.modal.fields[counter].type) {
          case "text":
            this.subscriberInfo[this.contents.data.modal.fields[counter].name] =
              "";
            break;
          case "combobox":
            this.subscriberInfo[this.contents.data.modal.fields[counter].name] =
              [];
            break;
          case "file":
            this.subscriberInfo[this.contents.data.modal.fields[counter].name] =
              [];
            break;
          case "imageInput":
            this.subscriberInfo[this.contents.data.modal.fields[counter].name] =
              [];
            break;
          default:
            break;
        }
      }
      this.subscriberInfo["status"] = true;
    },
    resetSubscriberInfo() {
      this.subscriberInfo = {};
      this.modalDisplay = false;
      if (!this.modalDisplay) {
        this.bodyNode.classList.remove("modal-open");
        this.bodyNode.style.overflow = "visible";
      }
      for (let index in this.contents.data.modal.fields) {
        if (this.contents.data.modal.fields[index].required) {
          this.contents.data.modal.fields[index].errorCode = 0;
        }
      }
    },
    inputValidation(obj) {
      let errorCount = 0;
      for (
        let index = 0;
        index < this.contents.data.modal.fields.length;
        index++
      ) {
        if (this.contents.data.modal.fields[index].required) {
          for (let expression in this.contents.data.modal.fields[index]
            .validation) {
            switch (
              this.contents.data.modal.fields[index].validation[expression]
                .expression
            ) {
              case "isEmpty":
                this.contents.data.modal.fields[index].errorCode =
                  !obj[this.contents.data.modal.fields[index].name] ||
                  obj[this.contents.data.modal.fields[index].name].length == 0
                    ? this.contents.data.modal.fields[index].validation[
                        expression
                      ].id
                    : 0;
                if (this.contents.data.modal.fields[index].errorCode > 0) {
                  errorCount += 1;
                }
                break;
              case "isSpace":
                this.contents.data.modal.fields[index].errorCode =
                  this.contents.data.modal.fields[index].validation.length >
                    1 && this.contents.data.modal.fields[index].errorCode > 0
                    ? this.contents.data.modal.fields[index].errorCode
                    : String(
                        obj[this.contents.data.modal.fields[index].name]
                      ).trim().length == 0
                    ? this.contents.data.modal.fields[index].validation[
                        expression
                      ].id
                    : 0;
                if (this.contents.data.modal.fields[index].errorCode > 0) {
                  errorCount += 1;
                }
                break;
              default:
                this.contents.data.modal.fields[index].errorCode = 0;
                break;
            }
          }
        }
      }
      return errorCount == 0 ? true : false;
    },
    createNewSubscriber(obj) {
      let data = {
        endPoint: `subscribers/i`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {
          role: obj["subscriberRole"],
          email: obj["subscriberEmail"],
          phoneNumber: obj["subscriberPhoneNumber"],
          active: "active",
          verify: false,
          keys: {},
          tokens: {},
        },
      };
      dataService
        .post(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.resetSubscriberInfo();
          this.readSubscribers();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
        });
    },
    readSubscribers(page) {
      let data = {
        endPoint: `subscribers`,
        pageNo: page,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          res.data.data.length > 0
            ? (this.dataIsEmpty = false)
            : (this.dataIsEmpty = true);
          for (let d in res.data.data) {
            res.data.data[d].subscriberDate = new Date(
              res.data.data[d].subscriberDate
            ).toLocaleDateString(this.routeLocale, {
              year: "numeric",
              month: "long",
              day: "numeric",
            });
          }
          this.subscribers = res.data.data;
          this.subscribers = this.subscribers.reverse();
          if (page == 1) {
            
            this.totalPages = res.data.totalRes
          }
        })
        .catch((error) => {
          this.$store.commit("setLoader", false);
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[1] = true;
          this.loaderDone();
        });
    },
    updateSubscriber(obj) {
      let data = {
        endPoint: `subscribers/u/${obj.id}`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {
          role: obj["subscriberRole"],
          active: obj["subscriberActive"],
          verify: obj["subscriberVerify"],
          password: "Test1234",
          keys: {},
          tokens: {},
          authType: "none",
        },
      };
      
      dataService
        .post(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.resetSubscriberInfo();
          this.readSubscribers();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
        });
    },
    deleteSubscribers(index) {
      let data = {
        endPoint: `subscribers/d/${this.subscribers[index].id}`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.readSubscribers();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
        });
    },
    readRoles() {
      let data = {
        endPoint: `roles`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          let tempRoles = this.contents.data.modal.fields[2];
          tempRoles.options = [];
          for (let role in res.data.data) {
            tempRoles.options.push({
              title: res.data.data[role].roleName,
              value: res.data.data[role].id,
            });
          }
          
          
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[2] = true;
          this.loaderDone();
        });
    },
  },
  mounted() {
    this.$store.commit("setLoader", true);
    this.getContents();
    this.readSubscribers(1);
  },
};
</script>
