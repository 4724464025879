<template>
  <div v-if="contents.status">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl my-5">
      <!--begin::Hero card-->
      <div class="card mb-12 shadow-sm">
        <!--begin::Hero body-->
        <div class="card-body flex-column p-5 mb-5">
          <!--begin::Hero content-->
          <div
            class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center h-lg-300px p-5 p-lg-15 overlay-wrapper gap-10">
            <!--begin::Wrapper-->
            <div class="d-flex flex-column align-items-start justift-content-center flex-equal me-5 order-1 order-md-0">
              <!--begin::Title-->
              <h1 class="fw-bolder fs-4 fs-lg-1 text-gray-800 mb-5 mb-lg-5">
                {{ contents.data.page.locale.title }}
              </h1>
              <!--end::Title-->
              <!--begin::Input group-->
              <div class="position-relative w-100 mb-5 mb-lg-10">
                <span class="text-gray-600 fs-6">{{
                  contents.data.page.locale.subTitle
                }}</span>
              </div>
              <!--end::Input group-->
              <!--begin::Action-->
              <a href="#" class="btn btn-primary fw-bolder fs-5" @click="setModalConfigs('create')"><i class="me-2 fs-4"
                  :class="`${contents.data.page.setting.buttonIcon}`"></i>{{ contents.data.page.locale.buttonLabel }}</a>
              <!--end::Action-->
            </div>
            <!--end::Wrapper-->
            <!--begin::Wrapper-->
            <div class="flex-equal d-flex align-self-center justify-content-end align-items-center order-0 order-md-1">
              <!--begin::Illustration-->
              <img :src="`${backendHost}/images/themes/${contentTheme}/${$store.state.sidebar[contents.name].routeImage
                }`" alt="" class="mw-100 min-h-100px mh-md-125px mh-lg-225px" />
              <!--end::Illustration-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Hero content-->
        </div>
        <!--end::Hero body-->
      </div>
      <!--end::Hero card-->
      <div v-if="dataIsEmpty" class="card-body p-20">
        <div class="d-flex flex-center">
          <img class="col-md-3" :src="`/assets/images/svg/no_data.svg`" alt="" />
        </div>
      </div>
      <!--begin::Row-->
      <div v-else class="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-5 g-xl-9">
        <!--begin::Col-->
        <div v-for="(upload, index) in uploads" :key="index" class="col-md-6">
          <!--begin::Card-->
          <div class="card card-flush">
            <div class="card-header ribbon ribbon-start ribbon-clip">
              <a v-if="!copyToggle[index]" @click="copyToClipboard(index)" class="ribbon-label cursor-pointer">
                {{ contents.data.page.locale.copyLabel }}
                <span class="ribbon-inner bg-primary"></span>
              </a>
              <a v-else class="ribbon-label cursor-pointer">{{ contents.data.page.locale.copiedLabel
              }}<span class="ribbon-inner bg-success"></span></a>
            </div>
            <!--begin::Card body-->
            <div class="card-body d-flex flex-column py-0">
              <div class="d-flex flex-column align-items-center mb-3">
                <!--begin::Avatar-->
                <div class="col-8 mb-5 middle_">
                  <div class="d-block overlay cursor-pointer">
                    <!--begin::Image-->
                    
                    <div v-if="upload.uploadInfo.mimetype.includes('image')"
                      class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-contain card-rounded h-150px bg-secondary"
                      :style="`
                        background-image: url(${backendHost}/uploads/${$store.state.uploadsPath}/${encodeURIComponent(upload.uploadName)});`">
                    </div>
                    <video v-else-if="upload.uploadInfo.mimetype.includes('video')" controls class="w-100 h-150px rounded"
                      style="object-fit: fill">
                      <source :src="`${backendHost}/uploads/${$store.state.uploadsPath}/${upload.uploadName}`"
                        :type="upload.uploadInfo.mimetype" />
                    </video>
                    <div v-else-if="upload.uploadInfo.mimetype.includes('pdf')"
                      class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-contain card-rounded h-150px bg-secondary"
                      :style="`background-image: url(${backendHost}/images/fileLogo.jpg);`">
                    </div>
                    <div v-else-if="upload.uploadInfo.mimetype.includes('audio')"
                      class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-contain card-rounded h-150px bg-secondary"
                      >
                      <audio :type="`${upload.uploadInfo.mimetype}`" controls class="audio_">
                        <source :src="`${backendHost}/uploads/${$store.state.uploadsPath}/${encodeURIComponent(upload.uploadName)}`"
                        :type="upload.uploadInfo.mimetype" />
                      </audio>
                    </div>

                    <!--end::Image-->
                    <!--begin::Action-->
                    <div @click="softBox(index, upload.uploadInfo.mimetype)" v-if="upload.uploadInfo.mimetype.includes('image') || upload.uploadInfo.mimetype.includes('video')"
                      class="overlay-layer card-rounded bg-dark bg-opacity-25" style="opacity: 1 !important;">
                      <i class="bi bi-eye-fill fs-2x text-white"></i>
                    </div>
                    <!--end::Action-->
                  </div>
                </div>
                <!--end::Avatar-->
              </div>
              <!-- <label
                  class="fs-4 text-gray-800 text-hover-primary fw-bolder mb-1"
                  >{{ upload.uploadName }}</label
                > -->
              <p class="text-gray-600 mb-8">
                {{ upload.uploadDescription }}
              </p>
              <!--end::Position-->
            </div>
            <!--end::Card body-->
            <!--begin::Card footer-->
            <div class="card-footer d-flex justify-content-center flex-wrap pt-0">
              <a @click="softBox(index)" class="btn btn-sm btn-light btn-active-primary my-1 me-2">{{
                contents.data.page.card.buttons[0].label }}</a>
              <button type="button" class="btn btn-sm btn-light btn-light-primary my-1 me-2"
                @click="setModalConfigs('update', index)">
                {{ contents.data.page.card.buttons[1].label }}
              </button>
              <button type="button" class="btn btn-sm btn-light btn-light-danger my-1" data-bs-toggle="modal"
                data-bs-target="#kt_modal_update_role" @click="setModalConfigs('delete', index)">
                {{ contents.data.page.card.buttons[2].label }}
              </button>
            </div>
            <!--end::Card footer-->
          </div>
          <!--end::Card-->
        </div>
        <div v-if="totalPages > 1" class="w-100 text-center mt-5">
          <pagination :pages="totalPages" v-model="currentPage" @update:modelValue="setCurrentPage"></pagination>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->
      <!--begin::Modals-->
      <create-update v-if="modalDisplay" :contents="contents.data.modal" :modalInfo="uploadInfo" :mode="'multi'"
        :modalType="'upload'" @close-modal="setModalConfigs"></create-update>
      <dialog-box v-if="dialogDisplay" :contents="contents.data.modal" :modalInfo="deleteInfo"
        @close-modal="setModalConfigs">
      </dialog-box>
      <!--end::Modals-->
      <!--begin::SoftBox-->
      <div>
        <FsLightbox :toggler="toggler" :sources="[`${softBoxSource}`]" />
      </div>
      <!--end::SoftBox-->
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
import dataService from "../../../services/data";

// Tools
import Pagination from "../../tools/Pagination.vue";

// Modals
import CreateUpdate from "../../modals/CreateUpdate.vue";
import DialogBox from "../../modals/DialogBox.vue";
import serverConfigs from "../../../configs/server.json";
import FsLightbox from "fslightbox-vue/v3";

export default {
  computed: {
    contentTheme() {
      return this.$store.state.themeFile.replace(".css", "");
    },
    backendHost() {
      return serverConfigs["serverDomain"]["host"];
    },
    routeLocale() {
      return this.$route.params.locale;
    },
    bodyNode() {
      return document.getElementById("kt_body");
    },
  },
  components: {
    Pagination,
    CreateUpdate,
    FsLightbox,
    DialogBox,
  },
  setup() { },
  data() {
    return {
      totalPages: 0,
      currentPage: 1,
      copiedToggle: null,
      copyToggle: [],
      uploadInfo: Object,
      deleteInfo: {},
      modalDisplay: false,
      dialogDisplay: false,
      dataIsEmpty: true,
      toggler: false,
      softBoxSource: "",
      uploads: [],
      contents: {
        name: "",
        data: {},
        status: false,
      },
      loaderStatus: [false, false, false],
    };
  },
  methods: {
    setModalConfigs(opt, index) {
      this.$store.commit("setLoader", true);

      switch (opt) {
        case "update":
          this.uploadInfo = { ...this.uploads[index], status: false };
          this.modalDisplay = !this.modalDisplay;
          this.$store.commit("setLoader", false);

          break;
        case "create":
          this.setupUploadInfo();
          this.modalDisplay = !this.modalDisplay;
          this.$store.commit("setLoader", false);

          break;
        case "delete":
          this.deleteInfo = {
            id: this.uploads[index].id,
            name: this.uploads[index].uploadName,
            status: false,
            delete: true,
          };
          this.dialogDisplay = !this.dialogDisplay;
          this.$store.commit("setLoader", false);

          break;
        case "validate":
          if (index.delete) {
            this.deleteUploads(index);
          } else {
            index.uploadName = index.status
              ? index.photos
                ? index.photos
                : []
              : index.uploadName;
            if (this.inputValidation(index)) {
              index.id ? this.updateUploads(index) : this.updateUploads(index);
            } else {
              this.$store.commit("setLoader", false);

            }
          }
          break;
        default:
          this.resetUploadInfo();
          this.$store.commit("setLoader", false);

          this.getUploads();
          break;
      }
    },
    setCurrentPage(page) {

      this.$store.commit("setLoader", true);
      this.getUploads(page)
    },
    softBox(index, type) {
      if (type.includes("image") || type.includes('video')) {
        this.toggler = !this.toggler;
        this.softBoxSource = `${this.backendHost}/uploads/${this.$store.state.uploadsPath}/${this.uploads[index].uploadName}`;
      } else if (type.includes("pdf")) {
        window.open(`http://docs.google.com/gview?url=${this.backendHost}/uploads/${this.$store.state.uploadsPath}/${this.uploads[index].uploadName}`, "_blank")
      }
    },
    copyToClipboard(index) {
      navigator.clipboard.writeText(
        `${this.backendHost}/uploads/${this.$store.state.uploadsPath}/${this.uploads[index].uploadName}`
      );
      if (this.copiedToggle != null) {
        this.copyToggle[this.copiedToggle] = false
      }
      this.copyToggle[index] = true
      this.copiedToggle = index
      setTimeout(() => {
        if (this.copiedToggle != null) {
          this.copyToggle[this.copiedToggle] = false
        }
      }, 5000)
    },
    loaderDone() {
      if (
        this.loaderStatus[0] &&
        this.loaderStatus[1] &&
        this.loaderStatus[2]
      ) {
        this.$store.commit("setLoader", false);
      }
    },
    getContents() {
      let data = {
        endPoint: `contents/r/uploads`,
        locale: `${this.routeLocale}`,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          this.contents = {
            name: res.data.data[0].contentName,
            data: res.data.data[0].contentJson,
            status: true,
          };
          this.readCategories();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[0] = true;
          this.loaderDone();
        });
    },
    getUploads(page) {
      let data = {
        endPoint: `uploads`,
        pageNo: page,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          res.data.data.length > 0
            ? (this.dataIsEmpty = false)
            : (this.dataIsEmpty = true);

          this.uploads = res.data.data;
          this.uploads = this.uploads.reverse();
          this.uploads.forEach(() => {
            this.copyToggle.push(false)
          })
          if (page == 1) {

            this.totalPages = res.data.totalRes
          }
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[1] = true;
          this.loaderDone();
        });
    },
    setupUploadInfo() {
      for (let counter in this.contents.data.modal.fields) {
        switch (this.contents.data.modal.fields[counter].type) {
          case "text":
            this.uploadInfo[this.contents.data.modal.fields[counter].name] = "";
            break;
          case "textarea":
            this.uploadInfo[this.contents.data.modal.fields[counter].name] = "";
            break;
          case "file":
            this.uploadInfo[this.contents.data.modal.fields[counter].name] = [];
            break;
          case "imageInput":
            this.uploadInfo[this.contents.data.modal.fields[counter].name] = [];
            break;
          default:
            break;
        }
      }
      this.uploadInfo["status"] = true;
    },
    resetUploadInfo() {
      this.uploadInfo = {};
      this.modalDisplay = false;
      this.dialogDisplay = false;
      if (!this.modalDisplay) {
        this.bodyNode.classList.remove("modal-open");
        this.bodyNode.style.overflow = "visible";
      }
      for (let index in this.contents.data.modal.fields) {
        if (this.contents.data.modal.fields[index].required) {
          this.contents.data.modal.fields[index].errorCode = 0;
        }
      }
    },
    inputValidation(obj) {

      let errorCount = 0;
      for (
        let index = 0;
        index < this.contents.data.modal.fields.length;
        index++
      ) {
        if (this.contents.data.modal.fields[index].required) {
          for (let expression in this.contents.data.modal.fields[index]
            .validation) {
            switch (
            this.contents.data.modal.fields[index].validation[expression]
              .expression
            ) {
              case "isEmpty":
                this.contents.data.modal.fields[index].errorCode =
                  !obj[this.contents.data.modal.fields[index].name] ||
                    obj[this.contents.data.modal.fields[index].name].length == 0
                    ? this.contents.data.modal.fields[index].validation[
                      expression
                    ].id
                    : 0;
                if (this.contents.data.modal.fields[index].errorCode > 0) {
                  errorCount += 1;
                }
                break;
              case "isSpace":
                this.contents.data.modal.fields[index].errorCode =
                  this.contents.data.modal.fields[index].validation.length >
                    1 && this.contents.data.modal.fields[index].errorCode > 0
                    ? this.contents.data.modal.fields[index].errorCode
                    : String(
                      obj[this.contents.data.modal.fields[index].name]
                    ).trim().length == 0
                      ? this.contents.data.modal.fields[index].validation[
                        expression
                      ].id
                      : 0;
                if (this.contents.data.modal.fields[index].errorCode > 0) {
                  errorCount += 1;
                }
                break;
              default:
                this.contents.data.modal.fields[index].errorCode = 0;
                break;
            }
          }
        }
      }
      return errorCount == 0 ? true : false;
    },
    readCategories() {
      let data = {
        endPoint: `categories`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          let tempCategories = this.contents.data.modal.fields[0];
          tempCategories.options = [];
          for (let category in res.data.data) {
            tempCategories.options.push({
              title: res.data.data[category].categoryName,
              value: res.data.data[category].id,
            });
          }
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[2] = true;
          this.loaderDone();
        });
    },
    createUploads(obj) {
      let data = {
        endPoint: `uploads`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {
          name: obj["uploadName"],
          description: obj["uploadDescription"],
          category: obj["uploadCategory"],
        },
      };
      dataService
        .post(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.resetUploadInfo();
          this.getUploads();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);

        });
    },
    updateUploads(obj) {
      this.$store.commit("setLoader", true);
      let data = {
        endPoint: `uploads/u/${obj.id ? obj.id : 0}`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {
          name: obj["uploadName"],
          description: obj["uploadDescription"],
          category: obj["uploadCategory"],
        },
      };

      dataService
        .post(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.resetUploadInfo();
          this.getUploads();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);

        });
    },
    deleteUploads(obj) {

      this.$store.commit("setLoader", true);

      let data = {
        endPoint: `uploads/d/${obj.id}`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.resetUploadInfo();
          this.getUploads();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);

        });
    },
  },
  mounted() {
    this.$store.commit("setLoader", true);
    this.getUploads(1);
    this.getContents();
  },
};
</script>
<style>
.audio_{
  width: 100%;
  
}
.middle_{
  
  
}
</style>
