<template>
  <div class="d-flex justify-content-center align-items-center h-100 my-5 my-lg-0 h-100">
    <form @keydown.enter="userLogin" class="login__form col-11 col-md-8 col-lg-7">
      <h3 class="fw-boldest fs-2x mb-3 pb-4">ورود فروشندگان و خریداران</h3>
      <div v-if="step == 0">
        <div class="form__input mb-4">
          <label class="mb-1" for="">شماره تماس <span class="text-danger">*</span></label>
          <div class="input__container d-flex justify-content-between" :class="phoneNumberErrorClass">
            <i class="bi bi-phone px-5"></i>
            <input v-model="userInfo.phoneNumber" class="flex-grow-1 fs-5 me-3" type="tel" />
          </div>
          <div :class="phoneNumberErrorClass.length > 0
            ? `${phoneNumberErrorClass} d-block`
            : 'd-none'
            ">

          </div>
        </div>
        <div class="pt-5 mb-4 d-flex justify-content-center flex-column flex-xl-row">
          <button ref="testFocus" class="btn btn-primary px-15 m-1 w-100" type="button" @click="sendResendCode">
            ارسال رمزعبور یکبار مصرف
          </button>
        </div>
      </div>
      <div v-if="step == 1">
        <h3 class="fw-boldest fs-2x mb-3 pb-4">تایید حساب کاربری</h3>
        <div class="form__input mb-4">
          <label class="mb-1" for="">کد اعتبار سنجی <span class="text-danger">*</span></label>
          <div class="input__container d-flex justify-content-between" :class="vcodeErrorClass">
            <i class="bi bi-123 px-5"></i>
            <input v-model="userInfo.vcode" class="text-center flex-grow-1 fs-5 me-3" type="number"
              placeholder="لطفا کد ارسال شده را وارد نمایید" />
          </div>
          <div :class="vcodeErrorClass.length > 0
            ? `${vcodeErrorClass} d-block`
            : 'd-none'
            ">

          </div>
        </div>
        <div class="pt-5 mb-4 d-flex justify-content-center flex-column flex-md-row">
          <button ref="testFocus" class="col-12 col-md-6 btn btn-primary px-15 m-1" type="button" @click="userVerification">
            تایید کد
          </button>
          <button class="col-12 col-md-6 btn btn-secondary px-15 m-1" type="button" @click="previousStep">
            بازگشت
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import isMobilePhone from "validator/lib/isMobilePhone";
import dataService from "../../../services/data";

export default {
  computed: {
    routePath() {
      return this.$route.path;
    },
    routeLocale() {
      return this.$route.params.locale;
    },
  },
  data() {
    return {
      step: 0,
      toggler: false,
      dialogDisplay: true,

      userInfo: {
        id: "",
        vcode: "",
        phoneNumber: "",
        fullName: "",
        idNumber: "",
        state: "",
        city: "",
        address: "",
        acceptPhoto: "",
        userKind: "",
        type: "",
        token: null,
      },
      
      phoneNumberErrorClass: "",
      vcodeErrorClass: "",
      userKindErrorClass: "",
      fullNameErrorClass: "",
      idNumberErrorClass: "",
      stateErrorClass: "",
      cityErrorClass: "",
      addressErrorClass: "",
      typeErrorClass: "",
      acceptPhotoErrorClass: "",
    }
  },
  methods: {
    previousStep() {
      this.step -= 1;
    },
    nextStep() {
      this.step += 1
    },
    userVerification() {
      if (this.userInfo.vcode > 0) {
        let data = {
          endPoint: `users/l`,
          locale: `${this.routeLocale}`,
          // token: this.$store.state.token,
          uuid: this.$store.state.projectUUID,
          data: {
            password: this.userInfo.vcode,
            userName: this.userInfo.phoneNumber,
          }
        };
        dataService
          .post(data)
          .then((res) => {
            this.$store.commit("setToken", res.data.data);
            window.location = `/${this.routeLocale}/profile`;
          })
          .catch((error) => {
            this.$notify({
              type: error.response.data.type,
              title: error.response.data.type,
              text: error.response.data.message,
            });
            this.vcodeErrorClass = "wrong-input"
          })
          .finally(() => {
            this.$store.commit("setLoader", false);
            
          });
      } else {
        this.vcodeErrorClass = "wrong-input"
      }
    },
    sendResendCode() {
      if (this.userInfo.phoneNumber.length > 0 && isMobilePhone(this.userInfo.phoneNumber, ["fa-IR"])) {
        let data = {
          endPoint: `users/vc`,
          locale: `${this.routeLocale}`,
          // token: this.$store.state.token,
          uuid: this.$store.state.projectUUID,
          data: {
            userName: this.userInfo.phoneNumber,
          }
        };
        dataService
          .post(data)
          .then((res) => {
            this.$notify({
              type: res.data.type,
              title: res.data.type,
              text: res.data.message,
            });
            this.phoneNumberErrorClass = ""
            this.nextStep()
          })
          .catch((error) => {
            this.$notify({
              type: error.response.data.type,
              title: error.response.data.type,
              text: error.response.data.message,
            });
            this.phoneNumberErrorClass = "wrong-input"
          })
          .finally(() => {
            this.$store.commit("setLoader", false);  
          });
      } else {
        this.phoneNumberErrorClass = "wrong-input"
      }
    },
  },
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input::-webkit-input-placeholder {
  text-align: right;
}

.wrong-input {
  border-color: red !important;
  color: red !important;
}
</style>