<template>
  <div class="modal fade" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content rounded">
        <div class="modal-header py-3">
          <h2 class="fw-bolder">
            مشاهده پاسخ
          </h2>
          <div
            class="btn btn-icon btn-sm btn-active-icon-primary"
            @click="closeModal"
          >
            <span class="svg-icon svg-icon-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.5"
                  x="6"
                  y="17.3137"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-45 6 17.3137)"
                  fill="black"
                />
                <rect
                  x="7.41422"
                  y="6"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(45 7.41422 6)"
                  fill="black"
                />
              </svg>
            </span>
          </div>
        </div>
        <div class="modal-body d-flex flex-center flex-column">
          <i class="fs-3hx bi" :class="modalInfo.status ? 'text-success bi-check-circle' : 'text-danger bi-x-circle'"></i>
          <p class="fs-1" :class="modalInfo.status ? 'text-success' : 'text-danger'">
            {{ modalInfo.status ? 'ایولا! پاسخ شما درست است.' : 'متاسفم! پاسخ شما نادرست است.' }}
          </p>
          <p v-if="!modalInfo.status" class="fs-1 text-success fw-boldest mt-20">پاسخ درست:</p>
          <div class="col-12 mt-5 d-flex flex-column flex-center">
            <div v-if="modalInfo.sessionDescription == 'question4'" class="row text-center">
              <img class="col-6" :src="teachers[modalInfo.sessionDescription][0]">
              <img class="col-6" :src="teachers[modalInfo.sessionDescription][1]">
              <div class="col-12 d-flex flex-center flex-column mt-5">
                <img class="col-6" :src="teachers[modalInfo.sessionDescription][2]">
              </div>
            </div>
            <img v-else class="col-9" :src="teachers[modalInfo.sessionDescription]">
          </div>
        </div>
        <div class="modal-footer py-3 d-flex justify-content-center">
          <div class="text-center">
            <button class="btn btn-light me-3" @click="closeModal">
              بستن
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import serverConfigs from "../../configs/server.json";

export default {
  components: {},
  setup() {},
  data() {
    return {
      displayStatus: false,
      value: null,
      modalDisplay: false,
      info: {},
      reason: "",
      teachers: {
        "question1": "https://websiran.com/uploads/hafezli.websiran.com/1714459203076_تکتم-حسینی.png",
        "question2": "https://websiran.com/uploads/hafezli.websiran.com/1714459203199_روانه-قاسمی.png",
        "question3": "https://websiran.com/uploads/hafezli.websiran.com/1714458960760_دکتر-حق-پناه.png",
        "question4": ["https://websiran.com/uploads/hafezli.websiran.com/1714547504183_صباکهیازی.jpg", "https://websiran.com/uploads/hafezli.websiran.com/1714547509281_مصطفیمحمدی.jpg", "https://websiran.com/uploads/hafezli.websiran.com/1714547505381_میثاققرایی.jpg"]
      }
    };
  },
  props: {
    contents: {
      required: true,
    },
    modalInfo: {
      required: false,
    },
  },
  computed: {
    backendHost() {
      return serverConfigs["serverDomain"]["host"];
    },
    modalOverlay() {
      let elem = document.createElement("div");
      elem.style.cssText = "z-index:109";
      elem.classList.add("drawer-overlay");
      return elem;
    },
    modalNode() {
      return document.getElementsByClassName("modal fade")[0];
    },
    bodyNode() {
      return document.getElementById("kt_body");
    },
  },
  methods: {
    closeModal(opt, index) {
      this.$emit(
        "close-modal",
        opt.length > 0 ? opt : null,
        index ? index : null
      );
    },
  },
  mounted() {
    Object.assign(this.info, this.modalInfo);
    document.addEventListener("keyup", this.detectTabKey);
    document.body.appendChild(this.modalOverlay);
    
    this.modalNode.classList.add("show");
    this.modalNode.classList.add("d-block");
    this.bodyNode.classList.add("modal-open");
    this.bodyNode.style.overflow = "hidden";
  },
  unmounted() {
    document.body.removeChild(this.modalOverlay);
    document.body.style.overflow = "";
  },
  updated() {},
};
</script>

<style scoped>
.wrong-inputs {
  border: 2px solid !important;
  border-color: red !important;
  /* background-color: #fff; */
}
</style>
