<template>
  <div v-if="contents.status">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl my-5">
      <!--begin::Hero card-->
      <div class="card mb-12 shadow-sm">
        <!--begin::Hero body-->
        <div class="card-body flex-column p-5 mb-5">
          <!--begin::Hero content-->
          <div
            class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center h-lg-300px p-5 p-lg-15 overlay-wrapper gap-10"
          >
            <!--begin::Wrapper-->
            <div
              class="d-flex flex-column align-items-start justift-content-center flex-equal me-5 order-1 order-md-0"
            >
              <!--begin::Title-->
              <h1 class="fw-bolder fs-4 fs-lg-1 text-gray-800 mb-5 mb-lg-5">
                {{ contents.data.page.locale.title }}
              </h1>
              <!--end::Title-->
              <!--begin::Input group-->
              <div class="position-relative w-100 mb-5 mb-lg-10">
                <span class="text-gray-600 fs-6"
                  >{{ contents.data.page.locale.subTitle }}
                </span>
              </div>
              <!--end::Input group-->
              <!--begin::Action-->
              <a
                href="#"
                class="btn btn-primary fw-bolder fs-5"
                @click="setModalConfigs('create')"
                ><i
                  class="bi bi-plus-circle me-2 fs-4"
                  :class="`${contents.data.page.setting.buttonIcon}`"
                ></i
                >{{ contents.data.page.locale.buttonLabel }}</a
              >
              <!--end::Action-->
            </div>
            <!--end::Wrapper-->
            <!--begin::Wrapper-->
            <div
              class="flex-equal d-flex align-self-center justify-content-end align-items-center order-0 order-md-1"
            >
              <!--begin::Illustration-->
              <img
                :src="`${backendHost}/images/themes/${contentTheme}/${
                  $store.state.sidebar[contents.name].routeImage
                }`"
                alt=""
                class="mw-100 min-h-100px mh-md-125px mh-lg-225px"
              />
              <!--end::Illustration-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Hero content-->
        </div>
        <!--end::Hero body-->
      </div>
      <!--end::Hero card-->
      <!--begin::Card-->
      <div class="card">
        <!--begin::Card body-->
        <div class="card-body">
          <!--begin::Layout-->
          <div class="d-flex flex-column flex-xxl-row p-7">
            <!--begin::Content-->
            <div class="flex-lg-row-fluid me-xxl-15 mb-10 mb-xxl-0">
              <!--begin::Tickets-->
              <div class="mb-0">
                <div v-if="dataIsEmpty" class="p-20">
                  <div class="d-flex flex-center">
                    <img
                      class="col-md-6"
                      :src="`/assets/images/svg/no_data.svg`"
                      alt=""
                    />
                  </div>
                </div>
                <!--begin::Tickets List-->
                <div v-else>
                  <!--begin::Heading-->
                  <h1 class="text-dark mb-10">
                    {{ contents.data.page.card.title }}
                  </h1>
                  <!--end::Heading-->
                  <div class="mb-10">
                    <!--begin::Ticket-->
                    <!--begin::Table-->
                    <div class="d-flex flex-column scroll-y me-n7 pe-7">
                      <table
                        class="table align-middle table-row-dashed fs-6 gy-5"
                        id="kt_table_user"
                      >
                        <!--begin::Table head-->
                        <thead>
                          <!--begin::Table row-->
                          <tr
                            class="text-start text-muted fw-bolder fs-7 text-uppercase gs-0"
                          >
                            <th
                              v-for="(header, index) in contents.data.page.table
                                .header"
                              :key="index"
                              :class="header.class"
                            >
                              {{ header.title }}
                            </th>
                          </tr>
                          <!--end::Table row-->
                        </thead>
                        <!--end::Table head-->
                        <!--begin::Table body-->
                        <tbody class="text-gray-600 fw-bold">
                          <!--begin::Table row-->
                          <tr v-for="(ticket, index) in tickets" :key="index">
                            <!--begin::Subject-->
                            <td>
                              <span
                                class="cursor-pointer text-hover-primary"
                                @click="setModalConfigs('update', index)"
                                >{{ ticket.ticketSubject }}</span
                              >
                            </td>
                            <!--end::Subject-->
                            <!--begin::Department-->
                            <td class="fs-8">
                              {{ ticket.ticketUser }}
                            </td>
                            <!--end::Department-->
                            <!--begin::Department-->
                            <td class="fs-8">
                              {{ ticket.ticketDepartment }}
                            </td>
                            <!--end::Department-->
                            <!--begin::Date-->
                            <td class="fs-8">{{ ticket.ticketDate }}</td>
                            <!--begin::Date-->
                            <!--begin::Date-->
                            <td>
                              <span
                                class="badge"
                                :class="
                                  ticket.ticketSituation == 'open'
                                    ? 'badge-light-success'
                                    : ticket.ticketSituation == 'close'
                                    ? 'badge-light-primary'
                                    : 'badge-light-danger'
                                "
                              >
                                {{ ticket.ticketSituation }}</span
                              >
                            </td>
                            <!--begin::Date-->
                            <!--begin::Action=-->
                            <td>
                              <div
                                class="d-flex justify-content-end flex-shrink-0"
                              >
                                <a
                                  href="#"
                                  class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                                  @click="setModalConfigs('update', index)"
                                  data-bs-toggle="tooltip"
                                  title="See ticket details"
                                >
                                  <!--begin::Svg Icon | path: icons/duotune/art/art005.svg-->
                                  <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-12-26-231111/core/html/src/media/icons/duotune/communication/com007.svg-->
                                  <span class="svg-icon svg-icon-2"
                                    ><svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        opacity="0.3"
                                        d="M8 8C8 7.4 8.4 7 9 7H16V3C16 2.4 15.6 2 15 2H3C2.4 2 2 2.4 2 3V13C2 13.6 2.4 14 3 14H5V16.1C5 16.8 5.79999 17.1 6.29999 16.6L8 14.9V8Z"
                                        fill="currentColor"
                                      />
                                      <path
                                        d="M22 8V18C22 18.6 21.6 19 21 19H19V21.1C19 21.8 18.2 22.1 17.7 21.6L15 18.9H9C8.4 18.9 8 18.5 8 17.9V7.90002C8 7.30002 8.4 6.90002 9 6.90002H21C21.6 7.00002 22 7.4 22 8ZM19 11C19 10.4 18.6 10 18 10H12C11.4 10 11 10.4 11 11C11 11.6 11.4 12 12 12H18C18.6 12 19 11.6 19 11ZM17 15C17 14.4 16.6 14 16 14H12C11.4 14 11 14.4 11 15C11 15.6 11.4 16 12 16H16C16.6 16 17 15.6 17 15Z"
                                        fill="currentColor"
                                      />
                                    </svg>
                                  </span>
                                  <!--end::Svg Icon-->
                                  <!--end::Svg Icon-->
                                </a>
                                <a
                                  href="#"
                                  class="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                                  @click="setModalConfigs('delete', index)"
                                  data-bs-toggle="tooltip"
                                  title="Cancel ticket"
                                >
                                  <!--begin::Svg Icon | path: icons/duotune/general/gen027.svg-->
                                  <span class="svg-icon svg-icon-2">
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                                        fill="currentColor"
                                      ></path>
                                      <path
                                        opacity="0.5"
                                        d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                                        fill="currentColor"
                                      ></path>
                                      <path
                                        opacity="0.5"
                                        d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </span>
                                  <!--end::Svg Icon-->
                                </a>
                              </div>
                            </td>
                            <!--end::Action=-->
                          </tr>
                          <!--end::Table row-->
                        </tbody>
                        <!--end::Table body-->
                      </table>
                    </div>
                    <!--end::Table-->
                    <!--end::Ticket-->
                  </div>
                  <div v-if="totalPages > 1" class="w-100 text-center mt-5">
                    <pagination
                      :pages="totalPages"
                      v-model="currentPage"
                      @update:modelValue="setCurrentPage"
                    ></pagination>
                  </div>
                </div>
                <!--end::Tickets List-->
              </div>
              <!--end::Tickets-->
            </div>
            <!--end::Content-->
            <!--begin::Sidebar-->
            <div
              class="flex-column flex-lg-row-auto w-100 mw-lg-300px mw-xxl-350px"
            >
              <!--begin::Documentations-->
              <div class="card-rounded bg-primary bg-opacity-5 p-10 mb-15">
                <!--begin::Title-->
                <h1 class="fw-bolder text-dark mb-9">
                  {{ contents.data.page.list.title }}
                </h1>
                <!--end::Title-->
                <!--begin::Item-->
                <div
                  v-for="(faq, index) in faqs"
                  :key="index"
                  class="d-flex align-items-center mb-6"
                >
                  <!--begin::Icon-->
                  <!--begin::Svg Icon | path: icons/duotune/arrows/arr071.svg-->
                  <span class="svg-icon svg-icon-2 ms-n1 me-3">
                    <!-- <img :src="`./assets/images/svg/${field.icon}`" alt="" /> -->
                  </span>
                  <!--end::Svg Icon-->
                  <!--end::Icon-->
                  <!--begin::Subtitle-->
                  <a
                    href="#"
                    class="fw-bold text-gray-800 text-hover-primary fs-5 m-0"
                    >{{ faq.faqQuestion }}</a
                  >
                  <!--end::Subtitle-->
                </div>
                <!--end::Item-->
              </div>
              <!--end::Documentations-->
            </div>
            <!--end::Sidebar-->
          </div>
          <!--end::Layout-->
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
      <create-update
        v-if="modalDisplay"
        :contents="contents.data.modal"
        :modalInfo="ticketInfo"
        :mode="'multi'"
        :modalType="'uploadselect'"
        :firstErrorName="firstErrorName"
        @close-modal="setModalConfigs"
      ></create-update>
      <dialog-box
        v-if="dialogDisplay"
        :contents="contents.data.modal"
        :modalInfo="deleteInfo"
        @close-modal="setModalConfigs"
      >
      </dialog-box>
      <!--end::Modals-->
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
import dataService from "../../../services/data";
import serverConfigs from "../../../configs/server.json";

// Tools
import Pagination from "../../tools/Pagination.vue";

// Modals
import CreateUpdate from "../../modals/CreateUpdate.vue";
import DialogBox from "../../modals/DialogBox.vue";

export default {
  components: {
    Pagination,
    CreateUpdate,
    DialogBox,
  },
  computed: {
    contentTheme() {
      return this.$store.state.themeFile.replace(".css", "");
    },
    backendHost() {
      return serverConfigs["serverDomain"]["host"];
    },
    routeLocale() {
      return this.$route.params.locale;
    },
    bodyNode() {
      return document.getElementById("kt_body");
    },
  },
  setup() {},
  data() {
    return {
      totalPages: 0,
      currentPage: 1,
      ticketInfo: Object,
      deleteInfo: {},
      dialogDisplay: false,
      ticketHistory: "",
      modalDisplay: false,
      modalStatus: 0,
      dataIsEmpty: true,
      tickets: [],
      faqs: [],
      firstErrorName: "",
      contents: {
        name: "",
        data: {},
        status: false,
      },
      loaderStatus: [false, false, false, false],
    };
  },
  methods: {
    async setModalConfigs(opt, index) {
      this.$store.commit("setLoader", true);
      
      switch (opt) {
        case "update":
          
          await this.setupTicketInfo();
          
          await this.readSourses(index);
          
          this.ticketInfo = {
            ...this.ticketInfo,
            ticketHistory: this.ticketHistory,
            ticketSource: this.tickets[index],
            status: false,
          };
          
          this.modalDisplay = !this.modalDisplay;
          this.$store.commit("setLoader", false);
          
          break;
        case "create":
          this.setupTicketInfo();
          this.modalDisplay = !this.modalDisplay;
          this.$store.commit("setLoader", false);
          
          
          break;
        case "delete":
          this.deleteInfo = {
            id: this.tickets[index].id,
            name: this.tickets[index].ticketSubject,
            status: false,
            delete: true,
          };
          this.dialogDisplay = !this.dialogDisplay;
          this.$store.commit("setLoader", false);
          
          break;
        case "validate":
          if (index.delete) {
            this.deleteTickets(index);
          } else {
            
            index["ticketAttachments"] = index.photos ? index.photos : [];
            if (this.inputValidation(index)) {
              index.ticketSource
                ? this.updateTickets(index)
                : this.createTickets(index);
            } else {
              this.$store.commit("setLoader", false);
              
            }
          }
          break;
        default:
          this.resetTicketInfo();
          this.$store.commit("setLoader", false);
          
          break;
      }
    },
    setCurrentPage(page) {
      
      this.$store.commit("setLoader", true);
      this.readTickets(page);
    },
    loaderDone() {
      if (
        this.loaderStatus[0] &&
        this.loaderStatus[1] &&
        this.loaderStatus[2] &&
        this.loaderStatus[3]
      ) {
        this.$store.commit("setLoader", false);
      }
    },
    getContents() {
      let data = {
        endPoint: `contents/r/tickets`,
        locale: `${this.routeLocale}`,
        data: {},
      };

      dataService
        .get(data)
        .then((res) => {
          this.contents = {
            name: res.data.data[0].contentName,
            data: res.data.data[0].contentJson,
            status: true,
          };
          this.readProducts();
          this.readDepartments();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[0] = true;
          this.loaderDone();
        });
    },
    async setupTicketInfo() {
      for (let counter in this.contents.data.modal.fields) {
        switch (this.contents.data.modal.fields[counter].type) {
          case "text":
            this.ticketInfo[this.contents.data.modal.fields[counter].name] = "";
            break;
          case "array":
            this.ticketInfo[this.contents.data.modal.fields[counter].name] = [];
            for (let item in this.contents.data.modal.fields[counter].value) {
              this.ticketInfo.ticketNotification[
                this.contents.data.modal.fields[counter].value[item].name
              ] = [];
              for (let accessStatus in this.contents.data.modal.fields[counter]
                .value[item].items) {
                this.ticketInfo.ticketNotification[
                  this.contents.data.modal.fields[counter].value[item].name
                ].push(
                  this.contents.data.modal.fields[counter].value[item].items[
                    accessStatus
                  ].value
                );
              }
            }
            break;
          case "combobox":
            this.ticketInfo[this.contents.data.modal.fields[counter].name] = [];
            break;
          case "file":
            this.ticketInfo[this.contents.data.modal.fields[counter].name] = [];
            break;
          default:
            break;
        }
      }
      this.ticketInfo["status"] = true;
    },
    resetTicketInfo() {
      this.ticketInfo = {};
      this.modalDisplay = false;
      this.dialogDisplay = false;
      if (!this.modalDisplay) {
        this.bodyNode.classList.remove("modal-open");
        this.bodyNode.style.overflow = "visible";
      }
      for (let index in this.contents.data.modal.fields) {
        if (this.contents.data.modal.fields[index].required) {
          this.contents.data.modal.fields[index].errorCode = 0;
        }
      }
    },
    inputValidation(obj) {
      let errorCount = 0;
      let tempError = "";
      for (
        let index = 0;
        index < this.contents.data.modal.fields.length;
        index++
      ) {
        if (this.contents.data.modal.fields[index].required) {
          for (let expression in this.contents.data.modal.fields[index]
            .validation) {
            switch (
              this.contents.data.modal.fields[index].validation[expression]
                .expression
            ) {
              case "isEmpty":
                this.contents.data.modal.fields[index].errorCode =
                  !obj[this.contents.data.modal.fields[index].name] ||
                  obj[this.contents.data.modal.fields[index].name].length == 0
                    ? this.contents.data.modal.fields[index].validation[
                        expression
                      ].id
                    : 0;
                if (this.contents.data.modal.fields[index].errorCode > 0) {
                  errorCount += 1;
                  tempError =
                    tempError.length == 0
                      ? this.contents.data.modal.fields[index].name
                      : tempError;
                }
                break;
              case "isSpace":
                this.contents.data.modal.fields[index].errorCode =
                  this.contents.data.modal.fields[index].validation.length >
                    1 && this.contents.data.modal.fields[index].errorCode > 0
                    ? this.contents.data.modal.fields[index].errorCode
                    : String(
                        obj[this.contents.data.modal.fields[index].name]
                      ).trim().length == 0
                    ? this.contents.data.modal.fields[index].validation[
                        expression
                      ].id
                    : 0;
                if (this.contents.data.modal.fields[index].errorCode > 0) {
                  errorCount += 1;
                  tempError =
                    tempError.length == 0
                      ? this.contents.data.modal.fields[index].name
                      : tempError;
                }
                break;
              default:
                this.contents.data.modal.fields[index].errorCode = 0;
                break;
            }
          }
        }
      }
      this.firstErrorName =
        tempError.length > 0 ? tempError : this.firstErrorName;
      return errorCount == 0 ? true : false;
    },
    createTickets(obj) {
      let data = {
        endPoint: `tickets`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {
          department: obj["ticketDepartment"],
          product: obj["ticketProduct"],
          subject: obj["ticketSubject"],
          message: obj["ticketMessage"],
          attachments: obj["ticketAttachments"],
        },
      };
      dataService
        .post(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.readTickets();
          this.resetTicketInfo();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
          
        });
    },
    readProducts() {
      let data = {
        endPoint: `products`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          let tempProducts = this.contents.data.modal.fields[2];
          for (let product in res.data.data) {
            tempProducts.options.push({
              title: res.data.data[product].productName,
              value: res.data.data[product].id,
            });
          }
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[1] = true;
          this.loaderDone();
        });
    },
    readDepartments() {
      let data = {
        endPoint: `departments`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          
          let tempDepartments = this.contents.data.modal.fields[3];
          for (let department in res.data.data) {
            tempDepartments.options.push({
              title: res.data.data[department].departmentName,
              value: res.data.data[department].id,
            });
          }
          
          
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[2] = true;
          this.loaderDone();
        });
    },
    readTickets(page) {
      let data = {
        endPoint: `tickets`,
        pageNo: page,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          res.data.data.length > 0
            ? (this.dataIsEmpty = false)
            : (this.dataIsEmpty = true);
          this.loaderStatus[1] = true;
          for (let d in res.data.data) {
            res.data.data[d].ticketDate = new Date(
              res.data.data[d].ticketDate
            ).toLocaleDateString(this.routeLocale, {
              year: "numeric",
              month: "long",
              day: "numeric",
            });
          }
          this.tickets = res.data.data;
          this.tickets = this.tickets.reverse();
          if (page == 1) {
            
            this.totalPages = res.data.totalRes;
          }
        })
        .catch((error) => {
          this.$store.commit("setLoader", false);
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[3] = true;
          this.loaderDone();
        });
    },
    async readSourses(index) {
      
      let data = {
        endPoint: `tickets/r/${this.tickets[index].id}`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {},
      };
      await dataService
        .get(data)
        .then((res) => {
          
          for (let d in res.data.data) {
            res.data.data[d].ticketDate = new Date(
              res.data.data[d].ticketDate
            ).toLocaleDateString(this.routeLocale, {
              year: "numeric",
              month: "long",
              day: "numeric",
            });
          }
          this.ticketHistory = res.data.data;
          
        })
        .catch((error) => {
          this.$store.commit("setLoader", false);
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[3] = true;
          this.loaderDone();
        });
    },
    readFAQs() {
      let data = {
        endPoint: `faqs`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          this.faqs = res.data.data;
          
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[1] = true;
          this.loaderDone();
        });
    },
    updateTickets(obj) {
      let data = {
        endPoint: `tickets`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        data: {
          department: obj["ticketDepartment"],
          product: obj["ticketProduct"],
          subject: obj["ticketSubject"],
          message: obj["ticketMessage"],
          attachments: obj["ticketAttachments"],
          source: obj["ticketSource"].id,
        },
      };
      dataService
        .post(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.readTickets();
          this.resetTicketInfo();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
          
        });
    },
    deleteTickets(obj) {
      this.$store.commit("setLoader", true);

      let data = {
        endPoint: `tickets/d/${obj.id}`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.resetRoleInfo();
          this.readRoles();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
        });
    },
  },
  mounted() {
    this.$store.commit("setLoader", true);
    this.getContents();
    this.readTickets(1);
    this.readFAQs();
  },
  updated() {
    this.loaderDone();
  },
};
</script>
