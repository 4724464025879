<template>
  <div v-if="contents.status">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl my-5">
      <!--begin::Hero card-->
      <div class="card mb-12 shadow-sm">
        <!--begin::Hero body-->
        <div class="card-body flex-column p-5 mb-5">
          <!--begin::Hero content-->
          <div
            class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center h-lg-300px p-5 p-lg-15 overlay-wrapper gap-10"
          >
            <!--begin::Wrapper-->
            <div
              class="d-flex flex-column align-items-start justift-content-center flex-equal me-5 order-1 order-md-0"
            >
              <!--begin::Title-->
              <h1 class="fw-bolder fs-4 fs-lg-1 text-gray-800 mb-5 mb-lg-5">
                {{ contents.data.page.locale.title }}
              </h1>
              <!--end::Title-->
              <!--begin::Input group-->
              <div class="position-relative w-100 mb-5 mb-lg-10">
                <span class="text-gray-600 fs-6">{{
                  contents.data.page.locale.subTitle
                }}</span>
                <span class="ms-3 text-gray-900 fs-4">
                  {{ contacts.length }}
                </span>
              </div>
              <!--end::Input group-->
            </div>
            <!--end::Wrapper-->
            <!--begin::Wrapper-->
            <div
              class="flex-equal d-flex align-self-center justify-content-end align-items-center order-0 order-md-1"
            >
              <!--begin::Illustration-->
              <img
                :src="`${backendHost}/images/themes/${contentTheme}/${
                  $store.state.sidebar[contents.name].routeImage
                }`"
                alt=""
                class="mw-100 min-h-100px mh-md-125px mh-lg-225px"
              />
              <!--end::Illustration-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Hero content-->
        </div>
        <!--end::Hero body-->
      </div>
      <!--end::Hero card-->
      <!--begin::Content-->
      <div class="flex-lg-row-fluid">
        <!--begin::Card-->
        <div class="card">
          <!--begin::Card header-->
          <!-- <div class="card-header border-0 pt-6"> -->
          <!--begin::Card title-->
          <!-- <div class="card-title"> -->
          <!--begin::Search-->
          <!-- <div class="d-flex align-items-center position-relative my-1">
                <span
                  class="
                    svg-icon svg-icon-primary svg-icon-1
                    position-absolute
                    ms-6
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x="17.0365"
                      y="15.1223"
                      width="8.15546"
                      height="2"
                      rx="1"
                      transform="rotate(45 17.0365 15.1223)"
                      fill="black"
                    />
                    <path
                      d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                      fill="black"
                    />
                  </svg>
                </span>
                <input
                  type="text"
                  class="form-control form-control-solid w-250px ps-14"
                  :placeholder="contents.data.page.list.search.placeholder"
                />
              </div> -->
          <!--end::Search-->
          <!-- </div> -->
          <!--begin::Card title-->
          <!--begin::Card toolbar-->
          <!-- <div class="card-toolbar"> -->
          <!--begin::Toolbar-->
          <!-- <div class="d-flex justify-content-end dropstart">
                <a
                  v-for="toolbar in contents.data.page.list.toolbar"
                  :key="toolbar"
                  type="button"
                  id="dropdownMenuButtonFilter"
                  aria-expanded="false"
                  :data-bs-toggle="toolbar.action"
                  data-bs-target="#kt_modal_export_users"
                  class="btn btn-light-primary me-3"
                >
                  <span class="svg-icon svg-icon-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  {{ toolbar.title }}
                </a>
                <filter-users-modal></filter-users-modal>
              </div> -->
          <!--end::Toolbar-->
          <!-- </div> -->
          <!--end::Card toolbar-->
          <!-- </div> -->
          <!--end::Card header-->
          <div class="separator"></div>
          <div v-if="dataIsEmpty" class="card-body p-20">
            <div class="d-flex flex-center">
              <img
                class="col-md-3"
                :src="`/assets/images/svg/no_data.svg`"
                alt=""
              />
            </div>
          </div>
          <div v-else class="card-body p-0">
            <!--begin::Table-->
            <div
              id="kt_inbox_listing_wrapper"
              class="dataTables_wrapper no-footer"
            >
              <div class="table-responsive">
                <table
                  class="table table-hover table-row-dashed fs-6 my-0 dataTable no-footer align-middle"
                  id="kt_inbox_listing"
                >
                  <!--begin::Table body-->
                  <tbody>
                    <tr
                      v-for="(contact, index) in contacts"
                      :key="index"
                      class="cursor-pointer"
                      @click="setModalConfigs('update', index)"
                    >
                      <!--begin::Author-->
                      <td class="ps-9">
                        <div class="d-flex align-items-center text-dark">
                          <!--begin::Name-->
                          <span class="fw-bold">{{
                            contact.contactFullName == null
                              ? "Unknown"
                              : contact.contactFullName
                          }}</span>
                          <!--end::Name-->
                        </div>
                      </td>
                      <!--end::Author-->
                      <!--begin::Title-->
                      <td class="w-50">
                        <div class="text-dark">
                          <!--begin::Heading-->
                          <a class="text-dark">
                            <span class="fw-bolder">{{
                              contact.contactSubject
                            }}</span>
                          </a>
                          <!--end::Heading-->
                        </div>
                      </td>
                      <!--end::Title-->
                      <td class="w-40px">
                        <!--begin::Star-->
                        <a
                          href="#"
                          class="btn btn-icon btn-color-gray-400 btn-sm btn-active-color-primary"
                          data-bs-toggle="tooltip"
                          data-bs-placement="right"
                          title=""
                          data-bs-original-title="Star"
                        >
                          <!--begin::Svg Icon | path: icons/duotune/general/gen029.svg-->
                          <i
                            class="bi fs-3"
                            :class="
                              contact.contactResponse.length > 0
                                ? 'bi-chat-right-text'
                                : 'bi-chat-right-text-fill text-primary'
                            "
                          ></i>
                          <!--end::Svg Icon-->
                        </a>
                        <!--end::Star-->
                      </td>
                      <!--begin::Date-->
                      <td class="w-100px fs-7 pe-9">
                        <span class="fw-bold">{{ contact.contactDate }}</span>
                      </td>
                      <!--end::Date-->
                    </tr>
                  </tbody>
                  <!--end::Table body-->
                </table>
              </div>
            </div>
            <div v-if="totalPages > 1" class="w-100 text-center mt-5">
              <pagination
                :pages="totalPages"
                v-model="currentPage"
                @update:modelValue="setCurrentPage"
              ></pagination>
            </div>
            <!--end::Table-->
          </div>
        </div>
        <!--end::Card-->
      </div>
      <!--end::Content-->
      <!--begin::Modals-->
      <create-update
        v-if="modalDisplay"
        :contents="contents.data.modal"
        :modalInfo="contactInfo"
        @close-modal="setModalConfigs"
      ></create-update>
      <!--end::Modals-->
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
import dataService from "../../../services/data";
import serverConfigs from "../../../configs/server.json";

// Tools
import Pagination from "../../tools/Pagination.vue";

// Modals
// import ExportUsersModal from "../../modals/ExportUsers.vue";
// import FilterUsersModal from "../../modals/FilterUsers.vue";
import CreateUpdate from "../../modals/CreateUpdate.vue";

export default {
  components: {
    Pagination,
    // ExportUsersModal,
    // FilterUsersModal,
    CreateUpdate,
  },
  setup() {},
  data() {
    return {
      totalPages: 0,
      currentPage: 1,
      contactInfo: {},
      modalDisplay: false,
      dataIsEmpty: true,
      contacts: [],
      contents: {
        name: "",
        data: {},
        status: false,
      },
      loaderStatus: [false, false],
    };
  },
  computed: {
    contentTheme() {
      return this.$store.state.themeFile.replace(".css", "");
    },
    backendHost() {
      return serverConfigs["serverDomain"]["host"];
    },
    routeLocale() {
      return this.$route.params.locale;
    },
    bodyNode() {
      return document.getElementById("kt_body");
    },
  },
  methods: {
    setModalConfigs(opt, index) {
      this.$store.commit("setLoader", true);
      
      switch (opt) {
        case "update":
          this.contactInfo = { ...this.contacts[index], status: false };
          
          this.modalDisplay = !this.modalDisplay;
          this.$store.commit("setLoader", false);
          
          break;
        case "create":
          this.setupContactInfo();
          this.modalDisplay = !this.modalDisplay;
          this.$store.commit("setLoader", false);
          
          break;
        case "validate":
          if (this.inputValidation(index)) {
            index.id ? this.updateContact(index) : this.createNewContact(index);
          } else {
            this.$store.commit("setLoader", false);
            
          }
          break;
        default:
          this.resetContactInfo();
          this.$store.commit("setLoader", false);
          
          break;
      }
    },
    setCurrentPage(page) {
      
      this.$store.commit("setLoader", true);
      this.readContacts(page)
    },
    loaderDone() {
      if (this.loaderStatus[0] && this.loaderStatus[1]) {
        this.$store.commit("setLoader", false);
      }
    },
    getContents() {
      let data = {
        endPoint: `contents/r/contacts`,
        locale: `${this.routeLocale}`,
        data: {},
      };

      dataService
        .get(data)
        .then((res) => {
          this.contents = {
            name: res.data.data[0].contentName,
            data: res.data.data[0].contentJson,
            status: true,
          };
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[0] = true;
          this.loaderDone();
        });
    },
    setupContactInfo() {
      for (let counter in this.contents.data.modal.fields) {
        switch (this.contents.data.modal.fields[counter].type) {
          case "paragraph":
            this.contactInfo[this.contents.data.modal.fields[counter].name] =
              "teadsfdkl;fgkhfghkropfkdl";
            break;
          case "text":
            this.contactInfo[this.contents.data.modal.fields[counter].name] =
              "";
            break;
          case "textarea":
            this.contactInfo[this.contents.data.modal.fields[counter].name] =
              "";
            break;
          default:
            break;
        }
      }
      this.contactInfo["status"] = true;
    },
    resetContactInfo() {
      this.contactInfo = {};
      this.modalDisplay = false;
      if (!this.modalDisplay) {
        this.bodyNode.classList.remove("modal-open");
        this.bodyNode.style.overflow = "visible";
      }
      for (let index in this.contents.data.modal.fields) {
        if (this.contents.data.modal.fields[index].required) {
          this.contents.data.modal.fields[index].errorCode = 0;
        }
      }
    },
    inputValidation(obj) {
      let errorCount = 0;
      for (
        let index = 0;
        index < this.contents.data.modal.fields.length;
        index++
      ) {
        if (this.contents.data.modal.fields[index].required) {
          for (let expression in this.contents.data.modal.fields[index]
            .validation) {
            switch (
              this.contents.data.modal.fields[index].validation[expression]
                .expression
            ) {
              case "isEmpty":
                this.contents.data.modal.fields[index].errorCode =
                  !obj[this.contents.data.modal.fields[index].name] ||
                  obj[this.contents.data.modal.fields[index].name].length == 0
                    ? this.contents.data.modal.fields[index].validation[
                        expression
                      ].id
                    : 0;
                if (this.contents.data.modal.fields[index].errorCode > 0) {
                  errorCount += 1;
                }
                break;
              case "isSpace":
                this.contents.data.modal.fields[index].errorCode =
                  this.contents.data.modal.fields[index].validation.length >
                    1 && this.contents.data.modal.fields[index].errorCode > 0
                    ? this.contents.data.modal.fields[index].errorCode
                    : String(
                        obj[this.contents.data.modal.fields[index].name]
                      ).trim().length == 0
                    ? this.contents.data.modal.fields[index].validation[
                        expression
                      ].id
                    : 0;
                if (this.contents.data.modal.fields[index].errorCode > 0) {
                  errorCount += 1;
                }
                break;
              default:
                this.contents.data.modal.fields[index].errorCode = 0;
                break;
            }
          }
        }
      }
      return errorCount == 0 ? true : false;
    },
    createNewContact(obj) {
      let tempPermissions = {};
      for (let p in obj.contactPermissions) {
        tempPermissions[`${p}`] = obj.contactPermissions[p];
      }
      let data = {
        endPoint: `contacts`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {
          name: obj["contactName"],
          description: obj["contactDescription"],
          permissions: tempPermissions,
        },
      };
      dataService
        .post(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.resetContactInfo();
          this.readContacts();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
          
        });
    },
    readContacts(page) {
      let data = {
        endPoint: `contacts`,
        pageNo: page,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          res.data.data.length > 0
            ? (this.dataIsEmpty = false)
            : (this.dataIsEmpty = true);
          for (let d in res.data.data) {
            res.data.data[d].contactDate = new Date(
              res.data.data[d].contactDate
            ).toLocaleDateString(this.routeLocale, {
              year: "numeric",
              month: "long",
              day: "numeric",
            });
          }
          this.contacts = res.data.data;
          this.contacts = this.contacts.reverse();
          if (page == 1) {
            
            this.totalPages = res.data.totalRes
          }
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[1] = true;
          this.loaderDone();
        });
    },
    updateContact(obj) {
      let data = {
        endPoint: `contacts/u/${obj.id}`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {
          email: obj["contactEmail"],
          response: obj["contactResponse"],
          subject: obj["contactSubject"],
          description: obj["contactDescription"],
        },
      };
      dataService
        .post(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.resetContactInfo();
          this.readContacts();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
          
        });
    },
    deleteContact(index) {
      this.$store.commit("setLoader", true);
      
      let data = {
        endPoint: `contacts/d/${this.contacts[index].id}`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.readContacts();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
          
        });
    },
  },
  mounted() {
    this.$store.commit("setLoader", true);
    this.getContents();
    this.readContacts(1);
  },
};
</script>
