import { createApp } from "vue";
import App from "./App.vue";

import Notifications from "@kyvg/vue3-notification";
// import Multiselect from "@vueform/multiselect";
import routes from "./routes/index";
import store from "./store/index";
import './registerServiceWorker'

const app = createApp(App);

// app.component("Multiselect", Multiselect);

app.use(Notifications);
app.use(routes);
app.use(store);
app.mount("#app");

/*
import { createApp } from "vue";

// Components
import App from "./App.vue";
import Pagination from "./components/Pagination.vue";
import SpinnerLoader from "./components/modals/SpinnerLoader.vue";
import Editor from "@tinymce/tinymce-vue";

// Modals
import DeleteDialog from "./components/modals/DeleteDialog.vue";

import routes from "./routes/index";

import gAuthPlugin from "vue3-google-oauth2";

import VCalendar from "v-calendar";

// Configs
import i18nConfigs from "./configs/i18n/en";

// Services
import Services from "./services/services";
import Validators from "./services/validators";

const gAuthOptions = {
    clientId: "221999405022-2a3vlu4orsrmv5kkikmvfo3kschv2u8r.apps.googleusercontent.com",
    scope: "email",
    prompt: "consent",
    fetch_basic_profile: true,
};
const app = createApp(App);

app.config.globalProperties.$services = Services;
app.config.globalProperties.$validators = Validators;

app.component("editor", Editor);
app.component("pagination", Pagination);
app.component("spinner-loader", SpinnerLoader);
app.component("delete-dialog", DeleteDialog);

app.use(routes);
app.use(gAuthPlugin, gAuthOptions);

app.use(VCalendar, {});
app.use(i18nConfigs);


app.mount("#app");
*/
