<template>
  <div v-if="contents.status">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl my-5">
      <div v-if="dataIsEmpty" class="card-body p-20">
        <div class="d-flex flex-center">
          <img
            class="col-md-3"
            :src="`/assets/images/svg/no_data.svg`"
            alt=""
          />
        </div>
      </div>
      <!--begin::Row-->
      <div v-else>
        <!--begin::Col-->
        <div
          v-if="pac"
          class="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-5 g-xl-9"
        >
          <div
            v-for="(product, index) in products"
            :key="index"
            class="col d-flex align-items-stretch"
          >
            <!--begin::Card-->
            <div class="card w-100">
              <!--begin::Card body-->
              <div class="card-body pb-0 d-flex flex-column">
                <div class="mb-6">
                  <a
                    href="#"
                    class="text-inverse-light bg-light text-hover-inverse-secondary bg-hover-secondary rounded fw-bold p-2 fs-8"
                    >{{ product.categoryName }}</a
                  >
                </div>
                <!--begin::Image-->
                <div class="d-flex justify-content-center mb-5">
                  <div
                    :id="`carouselExampleIndicators${index}`"
                    class="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <div class="carousel-indicators">
                      <button
                        v-for="(photo, bid) in product.productPhotos"
                        :key="bid"
                        type="button"
                        :data-bs-target="`#carouselExampleIndicators${index}`"
                        :data-bs-slide-to="`${bid}`"
                        :class="bid == 0 ? `active` : ''"
                        :aria-current="bid == 0 ? true : null"
                        :aria-label="`Slide ${Number(bid) + 1}`"
                      ></button>
                    </div>
                    <div class="carousel-inner">
                      <div
                        v-for="(photo, pid) in product.productPhotos"
                        :key="photo"
                        class="carousel-item"
                        :class="pid == 0 ? 'active' : ''"
                      >
                        <div
                          class="symbol symbol-200px"
                          style="background-color: #cccc"
                        >
                          <img
                            :src="`${backendHost}/uploads/${$store.state.uploadsPath}/${photo}`"
                            class="d-block card-image img w-100"
                            :alt="`${photo}`"
                          />
                        </div>
                      </div>
                    </div>
                    <button
                      class="carousel-control-prev"
                      type="button"
                      :data-bs-target="`#carouselExampleIndicators${index}`"
                      data-bs-slide="prev"
                    >
                      <span
                        class="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span class="visually-hidden">Previous</span>
                    </button>
                    <button
                      class="carousel-control-next"
                      type="button"
                      :data-bs-target="`#carouselExampleIndicators${index}`"
                      data-bs-slide="next"
                    >
                      <span
                        class="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span class="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
                <!--end::Image-->
                <!--begin::Title-->
                <a
                  :href="`${backendHost}/shop/r/${product.id}?locale=${routeLocale}`"
                  class="fs-4 text-gray-800 text-hover-primary fw-bold mb-1"
                  target="_blank"
                  >{{ product.productName }}</a
                >
                <!--begin::Summary-->
                <div class="text-gray-500 mb-3 text_justify fs-7">
                  {{ product.productSummary }}
                </div>
                <!--end::Summary-->
                <!--end::Title-->
                <!--begin::Position-->
                <div class="d-flex flex-stack mb-3">
                  <div class="text-gray-500">
                    <i class="bi bi-star-fill text-warning me-1"> </i>
                    <span class="fs-8">{{ product.productRate }}</span>
                  </div>
                  <div class="text-gray-500">
                    <i class="bi bi-people me-1"></i>
                    {{ product.productStudents }}
                  </div>
                </div>
                <div class="d-flex flex-stack mb-3">
                  <div class="text-gray-500">
                    <i class="bi bi-clock me-1"> </i>
                    {{ product.productDuration }}
                  </div>
                  <div class="text-gray-500">
                    <i class="bi bi-book me-1"> </i>
                    {{ product.productSessionsNo }}
                  </div>
                </div>
                <!--end::Position-->
              </div>
              <div class="card-footer py-3 d-flex flex-column">
                <div class="d-flex flex-stack mb-5">
                  <a
                    href=""
                    class="symbol symbol-circle symbol-30px text-dark text-hover-primary fs-8"
                  >
                    <img
                      :src="`${backendHost}/uploads/${$store.state.uploadsPath}/${product.profilePhoto}`"
                      class="me-1"
                      :alt="`${product.userUserName} photo`"
                    />
                    <span class="ms-1">{{ product.profileFullName }}</span>
                  </a>
                  <div class="d-flex flex-column">
                    <!-- <span class="text-danger"><del> 596,000 تومان</del></span> -->
                    <span v-if="product.productPrice == 0" class="text-success"
                      >Feel Free</span
                    >
                    <span v-else class="text-success"
                      >{{ product.productPrice }}
                      {{ product.productCurrency }}</span
                    >
                  </div>
                </div>
                <div class="align-self-center">
                  <!-- <a
                    :href="`/role-view?id=${product.id}`"
                    class="btn btn-sm btn-light btn-active-primary my-1 me-2"
                    >{{ contents.data.page.card.buttons[0].label }}</a
                  > -->
                  <!-- <button
                    type="button"
                    class="btn btn-sm btn-light btn-active-light-primary my-1 me-2"
                    @click="setModalConfigs('update', index)"
                  >
                    {{ contents.data.page.card.buttons[1].label }}
                  </button> -->
                  <!-- <button
                    type="button"
                    class="btn btn-sm btn-light btn-active-light-danger my-1"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_update_role"
                    @click="setModalConfigs('delete', index)"
                  >
                    {{ contents.data.page.card.buttons[2].label }}
                  </button> -->
                </div>
              </div>
              <!--end::Card body-->
            </div>
            <!--end::Card-->
          </div>
        </div>
        <div
          v-else
          class="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-5 g-xl-9"
        >
          <div
            v-for="(product, index) in products"
            :key="index"
            class="col d-flex align-items-stretch"
          >
            <!--begin::Card-->
            <div class="card w-100">
              <!--begin::Card body-->
              <div class="card-body pb-0 d-flex flex-column">
                <div class="mb-6 d-flex flex-stack">
                  <a
                    href="#"
                    class="text-primary bg-light-primary text-hover-inverse-secondary bg-hover-secondary rounded fw-bold p-2 fs-8 me-2"
                    >{{ product.categoryName }}</a
                  >
                  <div class="d-flex gap-2">
                    <a v-for="tag in product.productTags"
                      :key="tag.id"
                      :href="`#${tag.id}`"
                      class="text-inverse-light bg-light text-hover-inverse-secondary bg-hover-secondary rounded fw-bold p-2 fs-8"
                      >{{ tag.tagName }}</a
                    >
                  </div>
                </div>
                <!--begin::Image-->
                <div class="d-flex justify-content-center mb-5">
                  <div
                    :id="`carouselExampleIndicators${index}`"
                    class="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <div class="carousel-indicators">
                      <button
                        v-for="(photo, bid) in product.productPhotos"
                        :key="bid"
                        type="button"
                        :data-bs-target="`#carouselExampleIndicators${index}`"
                        :data-bs-slide-to="`${bid}`"
                        :class="bid == 0 ? `active` : ''"
                        :aria-current="bid == 0 ? true : null"
                        :aria-label="`Slide ${Number(bid) + 1}`"
                      ></button>
                    </div>
                    <div class="carousel-inner">
                      <div
                        v-for="(photo, pid) in product.productPhotos"
                        :key="photo"
                        class="carousel-item"
                        :class="pid == 0 ? 'active' : ''"
                      >
                        <div
                          class="symbol symbol-200px"
                          style="background-color: #cccc"
                        >
                          <img
                            :src="`${backendHost}/uploads/${$store.state.uploadsPath}/${photo}`"
                            class="d-block card-image img w-100"
                            :alt="`${photo}`"
                          />
                        </div>
                      </div>
                    </div>
                    <button
                      class="carousel-control-prev"
                      type="button"
                      :data-bs-target="`#carouselExampleIndicators${index}`"
                      data-bs-slide="prev"
                    >
                      <span
                        class="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span class="visually-hidden">Previous</span>
                    </button>
                    <button
                      class="carousel-control-next"
                      type="button"
                      :data-bs-target="`#carouselExampleIndicators${index}`"
                      data-bs-slide="next"
                    >
                      <span
                        class="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span class="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
                <!--end::Image-->
                <!--begin::Title-->
                <a
                  href="#"
                  class="fs-4 text-gray-800 text-hover-primary fw-bold mb-1"
                  target="_blank"
                  >{{ product.productName }}</a
                >
                <!--begin::Summary-->
                <div class="text-gray-500 mb-3 text_justify fs-7" v-html="product.productReview">
                </div>
                <!--end::Summary-->
                <!--end::Title-->
                <!--begin::Position-->
                <div class="d-flex flex-stack mb-3">
                  <div class="text-gray-500">
                    <i class="bi bi-calendar me-1"> </i>
                    {{ product.productDate }}
                  </div>
                  <div class="text-gray-500">
                    <i class="bi bi-star-fill text-warning me-1"> </i>
                    <span class="fs-8">{{ product.productRate }}</span>
                  </div>
                </div>
                <!--end::Position-->
              </div>
              <div class="card-footer py-3 d-flex flex-column">
                <div class="d-flex flex-stack mb-5">
                  <a
                    href=""
                    class="symbol symbol-circle symbol-30px text-dark text-hover-primary fs-8"
                  >
                    <img
                      :src="`${backendHost}/uploads/${$store.state.uploadsPath}/${product.profilePhoto}`"
                      class="me-1"
                      :alt="`${product.userUserName} photo`"
                    />
                    <span class="ms-1">{{ product.profileFullName }}</span>
                  </a>
                  <div class="d-flex flex-column text-end">
                    <!-- <span class="text-danger"><del> 596,000 تومان</del></span> -->
                    <span v-if="product.productPrice == 0" class="text-success"
                      >توافقی</span
                    >
                    <div v-else class="d-flex flex-column">
                      <div v-if="product.productOff > 0">
                        <span class="text-danger"
                          ><del
                            >{{ product.productPrice }}
                            {{ product.productCurrency }}</del
                          ></span
                        >
                        <span class="text-success"
                          >{{ product.productPrice }}
                          {{ product.productCurrency }}</span
                        >
                      </div>
                      <div v-else>
                        <span class="text-success"
                          >{{ product.productPrice }}
                          {{ product.productCurrency }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="align-self-center">
                  <a
                    :href="`tel:${product.profilePhoneNumber}`"
                    class="btn btn-sm btn-light btn-active-primary my-1 me-2"
                    >تماس با فروشنده</a
                  >
                  <button
                  disabled
                    type="button"
                    class="btn btn-sm btn-light btn-active-light-primary my-1 me-2"
                    @click="setModalConfigs('ticket', product)"
                  >
                    گفتگو با فروشنده
                  </button>
                  <!-- <button
                    type="button"
                    class="btn btn-sm btn-light btn-active-light-danger my-1"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_update_role"
                    @click="setModalConfigs('delete', index)"
                  >
                    {{ contents.data.page.card.buttons[2].label }}
                  </button> -->
                </div>
              </div>
              <!--end::Card body-->
            </div>
            <!--end::Card-->
          </div>
        </div>
        <div v-if="totalPages > 1" class="w-100 text-center mt-5">
          <pagination
          :pages="totalPages"
          v-model="currentPage"
          @update:modelValue="setCurrentPage"
          ></pagination>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->
      <!--begin::Modals-->
      <create-update
        v-if="modalDisplay"
        :contents="contents.data.modal"
        :modalInfo="productInfo"
        :mode="mode"
        :modalType="'uploadselect'"
        :firstErrorName="firstErrorName"
        @close-modal="setModalConfigs"
      ></create-update>
      <dialog-box
        v-if="dialogDisplay"
        :contents="contents.data.modal"
        :modalInfo="deleteInfo"
        @close-modal="setModalConfigs"
      >
      </dialog-box>
      <!--end::Modals-->
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
import dataService from "../../../services/data";
import serverConfigs from "../../../configs/server.json";

// Tools
import Pagination from "../../tools/Pagination.vue";

// Modals
import CreateUpdate from "../../modals/CreateUpdate.vue";
import DialogBox from "../../modals/DialogBox.vue";

export default {
  components: {
    Pagination,
    CreateUpdate,
    DialogBox,
  },
  setup() {},
  computed: {
    contentTheme() {
      return this.$store.state.themeFile.replace(".css", "");
    },
    backendHost() {
      return serverConfigs["serverDomain"]["host"];
    },
    routeLocale() {
      return this.$route.params.locale;
    },
    bodyNode() {
      return document.getElementById("kt_body");
    },
  },
  data() {
    return {
      totalPages: 0,
      currentPage: 1,
      pac: false,
      productInfo: {},
      deleteInfo: Object,
      modalDisplay: false,
      dialogDisplay: false,
      dataIsEmpty: true,
      products: [],
      firstErrorName: "",
      contents: {
        name: "",
        data: {},
        status: false,
      },
      mode: "single",
      loaderStatus: [false, false],
    };
  },
  methods: {
    setModalConfigs(opt, index) {
      this.$store.commit("setLoader", true);
      
      switch (opt) {
        case "ticket":
          this.deleteInfo = {
            id: index.id,
            name: "",
            status: false,
            delete: true,
            reason: true,
            message: "",
            description: "",
          };
          this.dialogDisplay = !this.dialogDisplay;
          this.$store.commit("setLoader", false);
          break
        case "update":
          this.productInfo = { ...this.products[index], status: false };
          this.productInfo["photos"] = this.products[index].productPhotos;
          this.modalDisplay = !this.modalDisplay;


          this.$store.commit("setLoader", false);
          
          break;
        case "create":
          this.setupProductInfo();
          this.modalDisplay = !this.modalDisplay;
          this.$store.commit("setLoader", false);
          
          break;
        case "delete":
          this.deleteInfo = {
            id: this.products[index].id,
            name: this.products[index].productName,
            status: false,
            delete: true,
          };
          this.dialogDisplay = !this.dialogDisplay;
          this.$store.commit("setLoader", false);
          
          break;
        case "validate":
          if (index.delete) {
            this.sendMessage(index);
          } else {
            // index["productPhotos"] = index["photos"];
            // if (this.inputValidation(index)) {
              index.id
                ? this.updateProducts(index)
                : this.createProducts(index);
            // } else {
            //   this.$store.commit("setLoader", false);
            // }
          }
          break;
        default:
          this.resetProductInfo();
          this.$store.commit("setLoader", false);
          
          break;
      }
    },
    setCurrentPage(page) {
      
      this.$store.commit("setLoader", true);
      this.readProducts(page)
    },
    loaderDone() {
      if (
        this.loaderStatus[0] &&
        this.loaderStatus[1] &&
        this.loaderStatus[2]
      ) {
        this.$store.commit("setLoader", false);
      }
    },
    getContents() {
      let data = {
        endPoint: `contents/r/products`,
        locale: `${this.routeLocale}`,
        data: {},
      };

      dataService
        .get(data)
        .then((res) => {
          
          this.contents = {
            name: res.data.data[0].contentName,
            data: res.data.data[0].contentJson,
            status: true,
          };

          this.contents.data.modal.fields = this.$store.state.shopType.includes("->") ?
            this.contents.data.modal.fields[this.$store.state.shopType.split("->")[0]][this.$store.state.shopType.split("->")[1]] :
            this.contents.data.modal.fields[this.$store.state.shopType]

          this.readCategories();
          this.readTags();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[0] = true;
          this.loaderDone();
        });
    },
    setupProductInfo() {
      let rootCard = {};
      let childCard = {};
      for (let counter in this.contents.data.modal.fields) {
        switch (this.contents.data.modal.fields[counter].type) {
          case "text":
            this.productInfo[this.contents.data.modal.fields[counter].name] =
              "";
            break;
          case "textarea":
            this.productInfo[this.contents.data.modal.fields[counter].name] =
              "";
            break;
          case "card":
            Object.assign(
              rootCard,
              this.contents.data.modal.fields[counter].value
            );
            this.productInfo[this.contents.data.modal.fields[counter].name] = [
              rootCard,
            ];
            for (let i in this.contents.data.modal.fields[counter].fields) {
              switch (this.contents.data.modal.fields[counter].fields[i].type) {
                case "text":
                  this.productInfo[
                    this.contents.data.modal.fields[counter].fields[i].name
                  ] = "";
                  break;
                case "textarea":
                  this.productInfo[
                    this.contents.data.modal.fields[counter].fields[i].name
                  ] = "";
                  break;
                case "card":
                  Object.assign(
                    childCard,
                    this.contents.data.modal.fields[counter].fields[i].value
                  );
                  this.productInfo[
                    this.contents.data.modal.fields[counter].fields[i].name
                  ] = [childCard];
                  break;
                default:
                  break;
              }
            }
            break;
          default:
            break;
        }
      }
      this.productInfo["status"] = true;
      
    },
    resetProductInfo() {
      this.productInfo = {};
      this.modalDisplay = false;
      this.dialogDisplay = false;
      if (!this.modalDisplay) {
        this.bodyNode.classList.remove("modal-open");
        this.bodyNode.style.overflow = "visible";
      }
      for (let index in this.contents.data.modal.fields) {
        if (this.contents.data.modal.fields[index].required) {
          this.contents.data.modal.fields[index].errorCode = 0;
        }
      }
    },
    inputValidation(obj) {
      let errorCount = 0;
      let tempError = "";

      for (
        let index = 0;
        index < this.contents.data.modal.fields.length;
        index++
      ) {
        // if (this.contents.data.modal.fields[index].type == "card") {
        //   for (
        //     let i = 0;
        //     i < this.contents.data.modal.fields[index].fields.length;
        //     i++
        //   ) {
        //     for (let p in obj[this.contents.data.modal.fields[index].name]) {
        //       if (
        //         this.contents.data.modal.fields[index].fields[i].type == "card"
        //       ) {
        //         for (
        //           let j = 0;
        //           j <
        //           this.contents.data.modal.fields[index].fields[i].fields
        //             .length;
        //           j++
        //         ) {
        //           if (
        //             this.contents.data.modal.fields[index].fields[i].fields[j]
        //               .required
        //           ) {
        //             console.log(obj[this.contents.data.modal.fields[index].name][p][
        //                     this.contents.data.modal.fields[index].fields[i].name
        //                   ])
        //                   console.log(this.contents.data.modal.fields[index].fields[i].name)
        //             // for (let s in obj[
        //             //   this.contents.data.modal.fields[index].fields[i].name
        //             // ]) {
        //             //   // for (let expression in this.contents.data.modal.fields[
        //             //   //   index
        //             //   // ].fields[i].fields[j].validation) {
        //             //   //   switch (
        //             //   //     this.contents.data.modal.fields[index].fields[i]
        //             //   //       .fields[j].validation[expression].expression
        //             //   //   ) {
        //             //   //     case "isEmpty":
        //             //   //       this.contents.data.modal.fields[index].fields[
        //             //   //         i
        //             //   //       ].fields[j].errorCode =
        //             //   //         !obj[this.contents.data.modal.fields[index].name][
        //             //   //           p
        //             //   //         ][
        //             //   //           this.contents.data.modal.fields[index].fields[i]
        //             //   //             .fields[j].name
        //             //   //         ][s] ||
        //             //   //         obj[this.contents.data.modal.fields[index].name][
        //             //   //           s
        //             //   //         ][
        //             //   //           this.contents.data.modal.fields[index].fields[i]
        //             //   //             .fields[j].name
        //             //   //         ].length == 0
        //             //   //           ? this.contents.data.modal.fields[index].fields[
        //             //   //               i
        //             //   //             ].validation[expression].id
        //             //   //           : 0;
        //             //   //       if (
        //             //   //         this.contents.data.modal.fields[index].fields[i]
        //             //   //           .errorCode > 0
        //             //   //       ) {
        //             //   //         errorCount += 1;
        //             //   //         tempError =
        //             //   //           tempError.length == 0
        //             //   //             ? this.contents.data.modal.fields[index]
        //             //   //                 .fields[i].name
        //             //   //             : tempError;
        //             //   //       }
        //             //   //       break;
        //             //   //     case "isSpace":
        //             //   //       this.contents.data.modal.fields[index].fields[
        //             //   //         i
        //             //   //       ].errorCode =
        //             //   //         this.contents.data.modal.fields[index].fields[i]
        //             //   //           .validation.length > 1 &&
        //             //   //         this.contents.data.modal.fields[index].fields[i]
        //             //   //           .errorCode > 0
        //             //   //           ? this.contents.data.modal.fields[index].fields[
        //             //   //               i
        //             //   //             ].errorCode
        //             //   //           : String(
        //             //   //               obj[
        //             //   //                 this.contents.data.modal.fields[index]
        //             //   //                   .name
        //             //   //               ][p][
        //             //   //                 this.contents.data.modal.fields[index]
        //             //   //                   .fields[i].name
        //             //   //               ]
        //             //   //             ).trim().length == 0
        //             //   //           ? this.contents.data.modal.fields[index].fields[
        //             //   //               i
        //             //   //             ].validation[expression].id
        //             //   //           : 0;
        //             //   //       if (
        //             //   //         this.contents.data.modal.fields[index].fields[i]
        //             //   //           .errorCode > 0
        //             //   //       ) {
        //             //   //         errorCount += 1;
        //             //   //         tempError =
        //             //   //           tempError.length == 0
        //             //   //             ? this.contents.data.modal.fields[index]
        //             //   //                 .fields[i].name
        //             //   //             : tempError;
        //             //   //       }
        //             //   //       break;
        //             //   //     default:
        //             //   //       this.contents.data.modal.fields[
        //             //   //         index
        //             //   //       ].errorCode = 0;
        //             //   //       break;
        //             //   //   }
        //             //   // }
        //             // }
        //           }
        //         }
        //       } else {
        //         if (this.contents.data.modal.fields[index].fields[i].required) {
        //           for (let expression in this.contents.data.modal.fields[index]
        //             .fields[i].validation) {
        //             switch (
        //               this.contents.data.modal.fields[index].fields[i]
        //                 .validation[expression].expression
        //             ) {
        //               case "isEmpty":
        //                 this.contents.data.modal.fields[index].fields[
        //                   i
        //                 ].errorCode =
        //                   !obj[this.contents.data.modal.fields[index].name][p][
        //                     this.contents.data.modal.fields[index].fields[i]
        //                       .name
        //                   ] ||
        //                   obj[this.contents.data.modal.fields[index].name][p][
        //                     this.contents.data.modal.fields[index].fields[i]
        //                       .name
        //                   ].length == 0
        //                     ? this.contents.data.modal.fields[index].fields[i]
        //                         .validation[expression].id
        //                     : 0;
        //                 if (
        //                   this.contents.data.modal.fields[index].fields[i]
        //                     .errorCode > 0
        //                 ) {
        //                   errorCount += 1;
        //                   tempError =
        //                     tempError.length == 0
        //                       ? this.contents.data.modal.fields[index].fields[i]
        //                           .name
        //                       : tempError;
        //                 }
        //                 break;
        //               case "isSpace":
        //                 this.contents.data.modal.fields[index].fields[
        //                   i
        //                 ].errorCode =
        //                   this.contents.data.modal.fields[index].fields[i]
        //                     .validation.length > 1 &&
        //                   this.contents.data.modal.fields[index].fields[i]
        //                     .errorCode > 0
        //                     ? this.contents.data.modal.fields[index].fields[i]
        //                         .errorCode
        //                     : String(
        //                         obj[
        //                           this.contents.data.modal.fields[index].name
        //                         ][p][
        //                           this.contents.data.modal.fields[index].fields[
        //                             i
        //                           ].name
        //                         ]
        //                       ).trim().length == 0
        //                     ? this.contents.data.modal.fields[index].fields[i]
        //                         .validation[expression].id
        //                     : 0;
        //                 if (
        //                   this.contents.data.modal.fields[index].fields[i]
        //                     .errorCode > 0
        //                 ) {
        //                   errorCount += 1;
        //                   tempError =
        //                     tempError.length == 0
        //                       ? this.contents.data.modal.fields[index].fields[i]
        //                           .name
        //                       : tempError;
        //                 }
        //                 break;
        //               default:
        //                 this.contents.data.modal.fields[index].errorCode = 0;
        //                 break;
        //             }
        //           }
        //         }
        //       }
        //     }
        //   }
        // } else {
        if (this.contents.data.modal.fields[index].required) {
          for (let expression in this.contents.data.modal.fields[index]
            .validation) {
            switch (
              this.contents.data.modal.fields[index].validation[expression]
                .expression
            ) {
              case "isEmpty":
                this.contents.data.modal.fields[index].errorCode =
                  !obj[this.contents.data.modal.fields[index].name] ||
                  obj[this.contents.data.modal.fields[index].name].length == 0
                    ? this.contents.data.modal.fields[index].validation[
                        expression
                      ].id
                    : 0;
                if (this.contents.data.modal.fields[index].errorCode > 0) {
                  errorCount += 1;
                  tempError =
                    tempError.length == 0
                      ? this.contents.data.modal.fields[index].name
                      : tempError;
                }
                break;
              case "isSpace":
                this.contents.data.modal.fields[index].errorCode =
                  this.contents.data.modal.fields[index].validation.length >
                    1 && this.contents.data.modal.fields[index].errorCode > 0
                    ? this.contents.data.modal.fields[index].errorCode
                    : String(
                        obj[this.contents.data.modal.fields[index].name]
                      ).trim().length == 0
                    ? this.contents.data.modal.fields[index].validation[
                        expression
                      ].id
                    : 0;
                if (this.contents.data.modal.fields[index].errorCode > 0) {
                  errorCount += 1;
                  tempError =
                    tempError.length == 0
                      ? this.contents.data.modal.fields[index].name
                      : tempError;
                }
                break;
              default:
                this.contents.data.modal.fields[index].errorCode = 0;
                break;
            }
          }
        }
        // }
      }
      this.firstErrorName =
        tempError.length > 0 ? tempError : this.firstErrorName;
      return errorCount == 0 ? true : false;
    },
    inputValidation_new(obj) {
      let errorCount = 0;
      let tempItem = {};
      
      let tempStep = this.contents.data.modal.fields;
      for (let i in tempStep) {
        if (
          tempStep[i].type == "tab" ||
          tempStep[i].type == "radioTab" ||
          tempStep[i].type == "imgRadio" ||
          tempStep[i].type == "mulImgRadio"
        ) {
          tempItem = tempStep[i].tabs[this.registerInfo[tempStep[i].key]].rows;
        } else {
          tempItem = tempStep;
        }
      }
      for (let i in tempItem) {
        if (tempItem[i].required) {
          for (let v in tempItem[i].validation) {
            switch (tempItem[i].validation[v].expression) {
              case "isEmpty":
                tempItem[i].errorCode =
                  !obj[tempItem[i].key] || obj[tempItem[i].key].length == 0
                    ? tempItem[i].validation[v].id
                    : 0;
                if (tempItem[i].errorCode > 0) {
                  errorCount += 1;
                }
                tempItem[i].type == "selectMiniFile"
                  ? null
                  : this.goto(tempItem[i].name);
                break;
              case "isSpace":
                tempItem[i].errorCode =
                  tempItem[i].validation.length > 1 && tempItem[i].errorCode > 0
                    ? tempItem[i].errorCode
                    : String(obj[tempItem[i].name]).trim().length == 0
                    ? tempItem[i].validation[v].id
                    : 0;
                if (tempItem[i].errorCode > 0) {
                  errorCount += 1;
                }
                this.goto(tempItem[i].name);
                break;
              default:
                tempItem[i].errorCode = 0;
                break;
            }
          }
        }
      }
      return errorCount == 0 ? true : false;
    },
    readProducts(page) {
      let data = {
        endPoint: `products`,
        pageNo: page,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {},
        query: {
          pageName: "dashboard",
        },
      };
      data.query = JSON.stringify(data.query)
        .replace("{", "")
        .replace(",", "&")
        .replace("}", "")
        .replace(/":"/g, "=")
        .replace(/"/g, "");

      dataService
        .get(data)
        .then((res) => {
          
          res.data.data.length > 0
            ? (this.dataIsEmpty = false)
            : (this.dataIsEmpty = true);
          this.products = res.data.data;
          this.products = this.products.reverse();
          for (let i in this.products) {
            this.products[i].productDate = new Date(
              this.products[i].productDate
            ).toLocaleDateString(this.routeLocale, {
              year: "numeric",
              month: "long",
              day: "numeric",
            });
          }
          if (page == 1) {
            
            this.totalPages = res.data.totalRes
          }
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[1] = true;
          this.loaderDone();
        });
    },
    readCategories() {
      let data = {
        endPoint: `categories`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          let tempCategories = {}
          this.contents.data.modal.fields.forEach(field => {
            if (field.name == "productCategory") {
              tempCategories = field;
            }
          });
          for (let i in res.data.data) {
            tempCategories.options.push({
              title: res.data.data[i].categoryName,
              value: res.data.data[i].id,
            });
          }
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[2] = true;
          this.loaderDone();
        });
    },
    readTags() {
      let data = {
        endPoint: `tags`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          let tempTags = {}
          this.contents.data.modal.fields.forEach(field => {
            if (field.name == "productTags") {
              tempTags = field;
            }
          });
          for (let tag in res.data.data) {
            tempTags.options.push({
              title: res.data.data[tag].tagName,
              value: res.data.data[tag].id,
            });
          }
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[2] = true;
          this.loaderDone();
        });
    },
    sendMessage(obj) {
      let data = {
        endPoint: `tickets`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {
          department: 3,
          product: obj["id"],
          subject: "پیام جدید از خریدار",
          message: obj["message"],
          attachments: {},
        },
      };
      dataService
        .post(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.profile["active"] = "block"
          this.updateUser(this.profile);
          this.readProfiles();
          this.resetProfileInfo();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
          
        });
    },
    goto() {
      // console.log(this.$refs[refName])
      // this.$refs[refName][0].scrollIntoView({ behavior: "smooth" });
    },
  },
  mounted() {
    this.$store.commit("setLoader", true);
    this.getContents();
    this.readProducts(1);
  },
};
</script>
