<template>
  <div class="d-flex justify-content-center align-items-center h-100 my-5 my-lg-0 h-100">
    <form @keydown.enter="userLogin" class="login__form col-11 col-md-8 col-lg-7">
      <div v-if="step == 0">
        <h3 class="fw-boldest fs-2x mb-3 pb-4">ثبت نام کاربران</h3>
        <div class="form__input mb-4">
          <label class="mb-1" for="">شماره تماس <span class="text-danger">*</span></label>
          <div class="input__container d-flex justify-content-between" :class="phoneNumberErrorClass">
            <i class="bi bi-phone px-5"></i>
            <input v-model="userInfo.phoneNumber" class="flex-grow-1 fs-5 me-3" type="tel"
              placeholder="لطفا شماره تماس خود را وارد نمایید" />
          </div>
          <div :class="phoneNumberErrorClass.length > 0
            ? `${phoneNumberErrorClass} d-block`
            : 'd-none'
            ">

          </div>
        </div>
        <div class="pt-5 mb-4 d-flex justify-content-center flex-column">
          <a ref="testFocus" class="link-primary link px-15 m-1 mb-10 w-100" href="/users/fa/login?pid=57174120b8ad4b6ab36b8b362e3fb11d">
            ورود به حساب کاربری
          </a>
          <button ref="testFocus" class="btn btn-primary px-15 m-1 w-100" type="button" @click="userSignUp">
            ارسال کد
          </button>
        </div>
      </div>
      <div v-if="step == 1">
        <h3 class="fw-boldest fs-2x mb-3 pb-4">تایید حساب کاربری</h3>
        <div class="form__input mb-4">
          <label class="mb-1" for="">کد اعتبار سنجی <span class="text-danger">*</span></label>
          <div class="input__container d-flex justify-content-between" :class="vcodeErrorClass">
            <i class="bi bi-123 px-5"></i>
            <input v-model="userInfo.vcode" class="text-center flex-grow-1 fs-5 me-3" type="number"
              placeholder="لطفا کد ارسال شده را وارد نمایید" />
          </div>
          <div :class="vcodeErrorClass.length > 0
            ? `${vcodeErrorClass} d-block`
            : 'd-none'
            ">

          </div>
        </div>
        <div class="pt-5 mb-4 d-flex justify-content-center flex-column flex-md-row">
          <button ref="testFocus" class="col-12 col-md-6 btn btn-primary px-15 m-1" type="button"
            @click="userVerification">
            تایید کد
          </button>
          <button class="col-12 col-md-6 btn btn-secondary px-15 m-1" type="button" @click="previousStep">
            بازگشت
          </button>
        </div>
      </div>
      <div v-if="step == 2">
        <h1 class="fw-boldest fs-3x mb-3 pb-4">احراز هویت</h1>
        <div class="mb-10">
          <h3 class="mb-3" for="">شخص حقیقی / حقوقی <span class="text-danger">*</span></h3>
          <div class="d-flex justify-content-between" :class="userKindErrorClass">
            <label class="" for="userKind0">حقیقی</label>
            <input id="userKind0" name="userKind" :checked="userInfo['userKind'] == 'person'"
              @click="setRadioGroup('userKind', 'person')" class="text-center flex-grow-1 fs-5 me-3" type="radio" />
            <label class="" for="userKind1">حقوقی</label>
            <input id="userKind1" name="userKind" :checked="userInfo['userKind'] == 'company'"
              @click="setRadioGroup('userKind', 'company')" class="text-center flex-grow-1 fs-5 me-3" type="radio" />
          </div>
          <div :class="vcodeErrorClass.length > 0
            ? `${vcodeErrorClass} d-block`
            : 'd-none'
            ">
          </div>
        </div>
        <div class="form__input mb-10">
          <h3 class="mb-3" for="">نام و نام خانوادگی / نام شرکت <span class="text-danger">*</span></h3>
          <div class="input__container d-flex justify-content-between" :class="fullNameErrorClass">
            <i class="bi bi-person px-5"></i>
            <input v-model="userInfo.fullName" class="text-center flex-grow-1 fs-5 me-3" type="text" placeholder="" />
          </div>
          <div :class="vcodeErrorClass.length > 0
            ? `${vcodeErrorClass} d-block`
            : 'd-none'
            ">
          </div>
        </div>
        <div class="form__input mb-10">
          <h3 class="mb-3" for="">کدملی / شماره ثبت <span class="text-danger">*</span></h3>
          <div class="input__container d-flex justify-content-between" :class="idNumberErrorClass">
            <i class="bi bi-card-text px-5"></i>
            <input v-model="userInfo.idNumber" class="text-center flex-grow-1 fs-5 me-3" type="text" placeholder="" />
          </div>
        </div>
        <div class="form__input mb-10">
          <h3 class="mb-3" for="">آدرس <span class="text-danger">*</span></h3>
          <div class="d-flex flex-column gap-3">
            <div class="d-flex flex-column flex-xl-row justify-content-between">
              <div class="col-12 col-xl-5 input__container mb-3 mb-xl-0 d-flex justify-content-between border-0">
                <i class="bi bi-pin px-5"></i>
                <!-- <input v-model="userInfo.state" class="text-center flex-grow-1 fs-5 me-3" type="text" placeholder="استان" /> -->
                <select class="w-100 input__container" v-model="userInfo.state" :class="stateErrorClass">
                  <option value="">استان</option>
                  <option v-for="state in states" :key="state.name" :value="state.name">{{ state.name }}</option>
                </select>
              </div>
              <div class="col-12 col-xl-5 input__container d-flex justify-content-between border-0" :class="cityErrorClass">
                <i class="bi bi-pin px-5"></i>
                <select v-if="userInfo.state.length > 0" class="w-100 input__container" v-model="userInfo.city" :class="cityErrorClass">
                  <option value="">شهرستان</option>
                  <option v-for="city in states[stateIndex].cities" :key="city" :value="city.name">{{ city.name }}</option>
                </select>
                <input v-else :disabled="true" v-model="userInfo.city" class="text-center flex-grow-1 fs-5 me-3" type="text" placeholder="شهرستان" />
              </div>
            </div>
            <div class="col-12 input__container d-flex justify-content-between" :class="addressErrorClass">
              <i class="bi bi-pin px-5"></i>
              <input v-model="userInfo.address" class="text-center flex-grow-1 fs-5 me-3" type="text"
                placeholder="خیابان، کوچه، پلاک" />
            </div>
          </div>
        </div>
        <div class="mb-10">
          <h3 class="mb-3" for="">نوع فعالیت <span class="text-danger">*</span></h3>
          <div class="d-flex justify-content-between" :class="typeErrorClass">
            <label class="" for="userTypeSaler">فروشنده</label>
            <input id="userTypeSaler" name="userType" :checked="userInfo['type'] == 'saler'"
              @click="setRadioGroup('type', 'saler')" class="text-center flex-grow-1 fs-5 me-3" type="radio" />
            <label class="" for="userTypeBuyer">خریدار</label>
            <input id="userTypeBuyer" name="userType" :checked="userInfo['type'] == 'buyer'"
              @click="setRadioGroup('type', 'buyer')" class="text-center flex-grow-1 fs-5 me-3" type="radio" />
          </div>
        </div>
        <div class="pt-5 mb-4 d-flex justify-content-center flex-column flex-md-row">
          <button ref="testFocus" class="col-12 col-md-6 btn btn-primary px-15 m-1" type="button" @click="nextStep">
            ثبت اطلاعات
          </button>
          <button class="col-12 col-md-6 btn btn-secondary px-15 m-1" type="button" @click="previousStep">
            بازگشت
          </button>
        </div>
      </div>
      <div v-if="step == 3">
        <h3 class="fw-boldest fs-2x mb-3 pb-4">تعهدنامه <span class="text-danger">*</span></h3>
        <p>لطفا متن زیر را بر روی یک کاغذ سفید بدون خط نوشته و مشابه عکس مقابل خود قرار داده و عکس گرفته و آن را بارگذاری
          بفرمایید:</p>
        <div class="d-flex flex-center flex-column border border-dashed border-1 border-primary rounded p-5 m-5">
          <p class="text-center fw-bold fs-2">این عکس جهت احراز هویت اینجانب...............در کاردو میباشدو متعهد می شوم
            حساب کاربری خود را به افراد غیره اجاره ندهم.</p>
          <div class="d-flex flex-wrap gap-3">
            <a class="d-block overlay cursor-pointer" @click="softBox">
              <div
                class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-100px w-100px bg-secondary"
                :style="`background-image: url(/assets/images/kardoAccept.jpeg);`"></div>
              <!--begin::Action-->
              <div class="overlay-layer card-rounded bg-dark bg-opacity-25">
                <i class="bi bi-eye-fill fs-2x text-white"></i>
              </div>
              <!--end::Action-->
            </a>
          </div>
          <!-- <img class="w-200px" src="/assets/images/kardoAccept.jpeg" alt="acceptPhoto"> -->
        </div>
        <!--begin::Dropzone-->
        <div class="dropzone dropzone-queue mb-2 bg-light-primary border border-dashed border-1 border-primary"
          :class="acceptPhotoErrorClass" id="kt_dropzonejs_example_0">
          <!--begin::Controls-->
          <div class="dropzone-panel mb-lg-0 mb-2 col-12">
            <div class="dropzone-select btn w-100">
              <!--begin::Icon-->
              <i class="bi bi-file-earmark-arrow-up text-primary fs-4x me-2 mb-3"></i>
              <div class="text-muted">برای بارگذاری فایل ضربه بزنید</div>
              <!--begin::Hint-->
              <span class="form-text text-muted fs-8">حداکثر تعداد فایل مجاز: 1 عدد</span>
              <!--end::Hint-->
              <!--end::Icon-->
            </div>
            <a class="dropzone-remove-all btn btn-sm btn-light-primary">حذف همه</a>
          </div>
          <!--end::Controls-->

          <!--begin::Items-->
          <div class="dropzone-items wm-200px d-flex flex-center flex-wrap scroll-y mh-200px" style="overflow-x: hidden">
          </div>
          <!--end::Items-->
        </div>
        <!--end::Dropzone-->
        <div class="pt-5 mb-4 d-flex justify-content-center flex-column flex-md-row">
          <button ref="testFocus" class="col-12 col-md-6 btn btn-primary px-15 m-1" type="button"
            @click="userRegistration">
            ارسال اطلاعات
          </button>
          <button class="col-12 col-md-6 btn btn-secondary px-15 m-1" type="button" @click="previousStep">
            بازگشت
          </button>
        </div>
      </div>
    </form>
    <div>
      <FsLightbox :toggler="toggler" :sources="[`/assets/images/kardoAccept.jpeg`]" />
    </div>
  </div>
</template>

<script>
import Dropzone from "dropzone";
import FsLightbox from "fslightbox-vue/v3";
import isMobilePhone from "validator/lib/isMobilePhone";
import isIdentityCard from "validator/lib/isIdentityCard";
import dataService from "../../../services/data";

import statesIRAN from "../../../configs/states.json";

export default {
  components: {
    FsLightbox
  },
  computed: {
    routePath() {
      return this.$route.path;
    },
    routeLocale() {
      return this.$route.params.locale;
    },
    states() {
      return statesIRAN;
    },
    stateIndex() {
      if (this.userInfo.state.length > 0) {
        for (let i = 0; i < this.states.length; i++) {
          if (this.states[i].name == this.userInfo.state) {
            return i;
          }
        }
      }
      return -1;
    }
  },
  data() {
    return {
      step: 0,
      toggler: false,
      dialogDisplay: true,

      userInfo: {
        id: "",
        vcode: "",
        phoneNumber: "",
        fullName: "",
        idNumber: "",
        state: "",
        city: "",
        address: "",
        acceptPhoto: "",
        userKind: "",
        type: "",
        token: null,
      },

      phoneNumberErrorClass: "",
      vcodeErrorClass: "",
      userKindErrorClass: "",
      fullNameErrorClass: "",
      idNumberErrorClass: "",
      stateErrorClass: "",
      cityErrorClass: "",
      addressErrorClass: "",
      typeErrorClass: "",
      acceptPhotoErrorClass: "",
    }
  },
  methods: {
    previousStep() {
      this.step -= 1;
    },
    nextStep() {
      if (this.step == 2) {
          if (this.userInfo.userKind.length > 0 &&
            this.userInfo.fullName.length > 0 &&
            this.userInfo.idNumber.length > 0 &&
            (this.userInfo.userKind == "company" || this.userInfo.userKind == "person" && isIdentityCard(this.userInfo.idNumber, ["IR"])) &&
            this.userInfo.state.length > 0 &&
            // this.userInfo.city.length > 0 &&
            this.userInfo.address.length > 0 &&
            this.userInfo.type.length > 0) {
            this.step += 1;
        } else {
          if (this.userInfo.userKind.length == 0) {
            this.userKindErrorClass = "wrong-input"
          } else {
            this.userKindErrorClass = ""
          }

          if (this.userInfo.fullName.length == 0) {
            this.fullNameErrorClass = "wrong-input"
          } else {
            this.fullNameErrorClass = ""
          }

          if (this.userInfo.idNumber.length == 0 || (this.userInfo.userKind.length > 0 && this.userInfo.userKind == "person" && !isIdentityCard(this.userInfo.idNumber, ["IR"]))) {
            this.idNumberErrorClass = "wrong-input"
          } else {
            this.idNumberErrorClass = ""
          }

          if (this.userInfo.state.length == 0) {
            this.stateErrorClass = "wrong-input"
          } else {
            this.stateErrorClass = ""
          }

          if (this.userInfo.city.length == 0) {
            this.cityErrorClass = "wrong-input"
          } else {
            this.cityErrorClass = ""
          }

          if (this.userInfo.address.length == 0) {
            this.addressErrorClass = "wrong-input"
          } else {
            this.addressErrorClass = ""
          }

          if (this.userInfo.type.length == 0) {
            this.typeErrorClass = "wrong-input"
          } else {
            this.typeErrorClass = ""
          }
        }
      }
      if (this.step == 3) {
        setTimeout(() => {
          this.initDropzone(this)
        }, 1000)
      }
    },
    setRadioGroup(name, value) {
      this.userInfo[name] = value;
    },
    userRegistration() {
      if (this.userInfo.acceptPhoto.length > 0) {
        let data = {
          endPoint: `profiles/u/${this.userInfo.id}`,
          locale: `${this.routeLocale}`,
          token: this.userInfo.token,
          uuid: this.$store.state.projectUUID,
          data: {
            fullName: this.userInfo.fullName,
            photo: "",
            active: "inActive",
            extra: {
              acceptPhoto: this.userInfo.acceptPhoto,
              nationalID: this.userInfo.idNumber,
              address: `${this.userInfo.state} - ${this.userInfo.city} - ${this.userInfo.state} - ${this.userInfo.address}`,
              personType: this.userInfo.userKind,
              role: this.userInfo.type == "saler" ? 42 : 43
            },
            status: 1,
          },
        };
        dataService
          .post(data)
          .then((res) => {
            this.$notify({
              type: res.data.type,
              title: res.data.type,
              text: res.data.message,
            });
            this.acceptPhotoErrorClass = ""
            setTimeout(() => {
              window.location = `https://panel.websiran.com/fa/login?pid=57174120b8ad4b6ab36b8b362e3fb11d`
            }, 3000)
          })
          .catch((error) => {
            this.$notify({
              type: error.response.data.type,
              title: error.response.data.type,
              text: error.response.data.message,
            });
            this.acceptPhotoErrorClass = "wrong-input"
          })
          .finally(() => {
            this.$store.commit("setLoader", false);
          });
      } else {
        this.acceptPhotoErrorClass = "wrong-input text-danger"
      }
    },
    userVerification() {
      if (this.userInfo.vcode > 0) {
        let data = {
          endPoint: `users/cc`,
          locale: `${this.routeLocale}`,
          // token: this.$store.state.token,
          uuid: this.$store.state.projectUUID,
          data: {
            verificationCode: this.userInfo.vcode,
            userName: this.userInfo.phoneNumber,
          }
        };
        dataService
          .post(data)
          .then((res) => {
            this.$notify({
              type: res.data.type,
              title: res.data.type,
              text: res.data.message,
            });

            this.vcodeErrorClass = ""

            this.step += 1
          })
          .catch((error) => {
            this.$notify({
              type: error.response.data.type,
              title: error.response.data.type,
              text: error.response.data.message,
            });
            this.vcodeErrorClass = "wrong-input"
          })
          .finally(() => {
            this.$store.commit("setLoader", false);

          });
      } else {
        this.vcodeErrorClass = "wrong-input"
      }
    },
    userSignUp() {
      if (this.userInfo.phoneNumber.length > 0 && isMobilePhone(this.userInfo.phoneNumber, ["fa-IR"])) {
        let data = {
          endPoint: `users`,
          locale: `${this.routeLocale}`,
          // token: this.$store.state.token,
          uuid: this.$store.state.projectUUID,
          data: {
            role: 34,
            phoneNumber: this.userInfo.phoneNumber,
          }
        };
        dataService
          .post(data)
          .then((res) => {
            this.$notify({
              type: res.data.type,
              title: res.data.type,
              text: res.data.message,
            });
            this.userInfo.id = res.data.data.id
            this.userInfo.token = res.data.data.token

            this.phoneNumberErrorClass = ""

            this.step += 1
          })
          .catch((error) => {
            this.$notify({
              type: error.response.data.type,
              title: error.response.data.type,
              text: error.response.data.message,
            });
            this.phoneNumberErrorClass = "wrong-input"
          })
          .finally(() => {
            this.$store.commit("setLoader", false);
          });
      } else {
        this.phoneNumberErrorClass = "wrong-input"
      }
    },
    sendResendCode() {
      let data = {
        endPoint: `users/vc`,
        locale: `${this.routeLocale}`,
        // token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {
          userName: this.userInfo.phoneNumber,
        }
      };
      dataService
        .post(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.userInfo.id = res.data.data[0]
          this.step += 1
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
        });
    },
    initDropzone(el) {
      // set the dropzone container id
      const id = `#kt_dropzonejs_example_0`;
      const dropzone = document.querySelector(id);

      var previewTemplate = `<div
                          class="dropzone-item bg-dark bg-opacity-10 col-12 m-3"
                          style="display: none"
                        >
                          <!--begin::File-->
                          <div
                            class="dropzone-file d-flex flex-column flex-center w-100"
                          >
                            <div
                              class="image-input mt-3"
                              data-kt-image-input="true"
                            >
                              <div
                                class="image-input-wrapper w-100px d-flex flex-center"
                              >
                                <img
                                  data-dz-thumbnail=""
                                  class="dz-image w-100 mh-100"
                                  style="object-fit: cover"
                                  alt=""
                                />
                              </div>
                              <!--begin::Edit button-->
                              <label
                                class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow dropzone-delete"
                                data-dz-remove
                                data-kt-image-input-action="change"
                                data-bs-toggle="tooltip"
                                data-bs-dismiss="click"
                              >
                                <i class="bi bi-trash-fill fs-7"></i>
                              </label>
                              <!--end::Edit button-->
                            </div>
                            <!--begin::Progress-->
                            <div class="dropzone-progress">
                              <div class="progress">
                                <div
                                  class="progress-bar bg-primary"
                                  role="progressbar"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                  aria-valuenow="0"
                                  data-dz-uploadprogress
                                ></div>
                              </div>
                            </div>
                            <!--end::Progress-->
                            <div
                              class="dropzone-error"
                              data-dz-errormessage
                            ></div>
                          </div>
                          <!--end::File-->
                        </div>`;

      var myDropzone = new Dropzone(id, {
        // Make the whole body a dropzone
        // url: `${
        //   serverConfigs["serverDomain"]["host"]
        // }/api/v1/uploads/upload?locale=fa&pid=${sessionStorage.getItem(
        //   "projectUUID"
        // )}`,
        // url: "http://localhost:3077/api/v1/uploads",
        url: `https://websiran.com/api/v1/uploads/upload?locale=fa&pid=${this.$store.state.projectUUID}`,
        parallelUploads: 20,
        maxFiles: 1,
        maxFilesize: 100, // Max filesize in MB
        previewTemplate: previewTemplate,
        previewsContainer: id + " .dropzone-items", // Define the container to display the previews
        clickable: id + " .dropzone-select", // Define the element that should be used as click trigger to select files.
      });

      myDropzone.on("addedfile", function () {
        // Hookup the start button
        const dropzoneItems = dropzone.querySelectorAll(".dropzone-item");
        dropzoneItems.forEach((dropzoneItem) => {
          dropzoneItem.style.display = "";
        });
        dropzone.querySelector(".dropzone-select").style.display = "none";
      });

      // Update the total progress bar
      myDropzone.on("totaluploadprogress", function (progress) {
        const progressBars = dropzone.querySelectorAll(".progress-bar");
        progressBars.forEach((progressBar) => {
          progressBar.style.width = progress + "%";
        });
      });

      myDropzone.on("sending", function () {
        // Show the total progress bar when upload starts
        const progressBars = dropzone.querySelectorAll(".progress-bar");
        progressBars.forEach((progressBar) => {
          progressBar.style.opacity = "1";
        });
      });

      myDropzone.on("success", function (file) {
        el.userInfo.acceptPhoto = JSON.parse(file.xhr.response)[0].filename
        console.log(JSON.parse(file.xhr.response)[0].filename)
        // let temp =
        //   document.getElementById(`${thisItems[i].key}`).value.length > 0
        //     ? JSON.parse(document.getElementById(`${thisItems[i].key}`).value)
        //     : [];
        // temp.push(JSON.parse(file.xhr.response)[0].filename);
        // thisElement.registerInfo[`${thisItems[i].key}`] = temp;
        // document.getElementById(`${thisItems[i].key}`).value =
        //   JSON.stringify(temp);
        // if (
        //   (!thisElement.mode || thisElement.mode == "single") &&
        //   document.getElementById("kt_modal_new_ticket_form")
        // ) {
        //   document
        //     .getElementById("kt_modal_new_ticket_form")
        //     .classList.add("d-none");
        // }
      });

      // Hide the total progress bar when nothing"s uploading anymore
      myDropzone.on("complete", function () {
        const progressBars = dropzone.querySelectorAll(".dz-complete");

        setTimeout(function () {
          progressBars.forEach((progressBar) => {
            progressBar.querySelector(".progress-bar").style.opacity = "0";
            progressBar.querySelector(".progress").style.opacity = "0";
          });
        }, 300);
      });
      // On all files removed
      myDropzone.on("removedfile", function () {
        console.log("Here")
        // thisElement.registerInfo[`${thisItems[i].key}`] = "";
        // if (myDropzone.files.length < 1) {
        //   dropzone.querySelector(".dropzone-select").style.display =
        //     "inline-block";
        // }
      });

      // Setup the button for remove all files
      dropzone
        .querySelector(".dropzone-remove-all")
        .addEventListener("click", function () {
          myDropzone.removeAllFiles();
        });
    },
    softBox() {
      this.toggler = !this.toggler;
    },
  },
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-webkit-input-placeholder {
  text-align: right;
}

.wrong-input {
  border-color: red !important;
  color: red !important;
}
</style>