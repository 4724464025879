<template>
  <div
    class="col-12 col-lg-4 __bg__body d-flex align-items-center justify-content-center"
  >
    <img
      class="m-5 mb-lg-5 px-2"
      :src="`/assets/images/svg/${photoName}_illustration.svg`"
      alt=""
    />
  </div>
</template>

<script>
export default {
  setup() {},
  props: {
    photoName: {
      type: String,
      required: true,
    },
  },
};
</script>
