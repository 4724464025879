<template>
  <div
    v-if="contents.status"
    class="d-flex justify-content-center align-items-center forgot__content"
  >
    <div
      class="card shadow col-11 col-md-10 col-lg-6 d-flex justify-content-center align-items-center my-4 my-lg-0"
    >
      <h3 class="fw-bold mt-5">{{ contents.data.title }}</h3>
      <p class="text-muted mb-4">
        {{ contents.data.subTitle }}
      </p>

      <div
        @keydown.enter="sendVerificationCode"
        class="col-10 col-md-9 col-lg-7 forgot__form mt-4"
      >
        <div class="mb-5">
          <label class="form-label">{{ contents.data.fields[0].title }}</label>
          <input
            v-model="userName"
            type="email"
            class="form-control form-control-lg border-0 __bg__light"
            :class="userNameErrorClass"
            :placeholder="contents.data.fields[0].placeholder"
          />
          <div class="invalid-feedback">
            {{ contents.data.fields[0].error }}
          </div>
        </div>
        <div
          class="pt-1 mt-5 mb-5 d-flex justify-content-center flex-column flex-lg-row"
        >
          <a
            v-for="button in contents.data.buttons"
            :key="button"
            @click="handleButtonAction(button.function)"
            class="btn btn-lg px-5 m-1"
            :class="button.class"
          >
            {{ button.title }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";
import dataService from "../../../services/data";

export default {
  setup() {},
  data() {
    return {
      userName: "",
      userNameErrorClass: "",

      contents: {
        data: {},
        status: false,
      },
    };
  },
  computed: {
    routePath() {
      return this.$route.path;
    },
    routeLocale() {
      return this.$route.params.locale;
    },
  },
  methods: {
    handleButtonAction(func) {
      switch (func.name) {
        case "redirectToPage":
          window.location = `/${this.routeLocale}/${func.args}`;
          break;
        case "sendVerificationCode":
          this.sendVerificationCode()
          break
        default:
          break;
      }
    },
    getContents() {
      let data = {
        endPoint: `contents/r/${this.routePath.substring(4)}`,
        locale: `${this.routeLocale}`,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          this.contents = {
            data: res.data.data[0].contentJson,
            status: true,
          };
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        });
    },
    sendVerificationCode() {
      if (this.inputValidation()) {
        let data = {
          endPoint: "users/vc",
          locale: `${this.routeLocale}`,
          data: {
            userName: this.userName,
          },
        };
        dataService
          .post(data)
          .then((res) => {
            if (res.data.success) {
              window.location = `/${this.routeLocale}/verification-code?id=${this.userName}`;
            }
            this.$notify({
              type: res.data.type,
              title: res.data.type,
              text: res.data.message,
            });
          })
          .catch((error) => {
            this.$notify({
              type: error.response.data.type,
              title: error.response.data.type,
              text: error.response.data.message,
            });
          });
      }
    },
    inputValidation() {
      if (this.userName.length == 0) {
        this.userNameErrorClass = "wrong-inputs is-invalid";
        return false;
      } else if (!isEmail(this.userName) && !isMobilePhone(this.userName, ["fa-IR"])) {
        this.userNameErrorClass = "wrong-inputs is-invalid";
        return false;
      } 
      this.userNameErrorClass = "";
      return true;
    },
  },
  mounted() {
    this.getContents();
  },
};
</script>

<style scoped>
.wrong-inputs {
  border: 2px solid !important;
  border-color: red !important;
  background-color: #fff;
}
</style>
