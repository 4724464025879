import http from "../configs/axios";

class DataService {
  /* Language APIs */
  // Get Info
  get(data) {
    
    return http.get(
      `v1/${data.endPoint}?locale=${data.locale}&pageNo=${
        data.pageNo ? data.pageNo : 1
      }&${data.query}`,
      {
        headers: {
          Authorization: `Bearer ${data.token}`,
          projectuuid: data.uuid,
          "Access-Control-Allow-Origin": `*`,
            // document.getElementById("projectuuid").content != "null" &&
            // document.getElementById("projectuuid").content != "undefined" &&
            // document.getElementById("projectuuid").content.length > 0
            //   ? document.getElementById("projectuuid").content
            //   : this.getPID(),
        },
      }
    );
  }

  // Post Info
  post(data, baseURL) {
    return http.post(`v1/${data.endPoint}?locale=${data.locale}`, data.data, {
      baseURL: baseURL,
      headers: {
        Authorization: `Bearer ${data.token}`,
        ProjectUUID: document.getElementById("projectuuid").content
          ? document.getElementById("projectuuid").content
          : this.getPID(data),
      },
    });
  }

  getPID(data) {
    if (data.uuid) {
      return data.uuid;
    }
    let tempQuery = window.location.search.replace("?", "");
    tempQuery = tempQuery.split("=");
    for (let i = 0; i < tempQuery.length; i++) {
      if (tempQuery[i] == "pid") {
        document
          .getElementById("projectuuid")
          .setAttribute("content", tempQuery[i + 1]);
        
        return tempQuery[i + 1];
      }
      i++;
    }
    return "";
  }
}

export default new DataService();
