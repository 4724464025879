import http from "../configs/axios";

var isExistUser = async function (userInfo, locale) {
  return await http.post(`v1/validators/users?locale=${locale}`, {userName: userInfo}, 
  {
    // baseURL: "http://localhost:3077/api",
    baseURL: "https://def.webplate.info/api",
  }).then(() => {
    return true
  }).catch(() => {
    return false;
  });
};

export default isExistUser;
