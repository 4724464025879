<template>
  <!--begin::Container-->
  <div dir="ltr" id="kt_content_container" class="container-xxl my-5">
    <!--begin::Breadcrumb-->
    <ol class="breadcrumb text-muted fs-6 fw-bold mb-3">
      <li class="breadcrumb-item pe-2">
        <a href="/" class="fs-8 pe-2 text-gray-500 text-hover-primary">Home</a>
      </li>
      <li class="breadcrumb-item pe-2">
        <a href="/shop" class="fs-8 pe-2 text-gray-500 text-hover-primary"
          >Packages</a
        >
      </li>
      <li class="breadcrumb-item pe-2">
        <a
          :href="`/shop#${$store.state.curProduct.productCategory}`"
          class="fs-8 pe-2 text-gray-500 text-hover-primary"
          >{{ $store.state.curProduct.categoryName }}</a
        >
      </li>
      <!-- {{!-- <li class="breadcrumb-item pe-2">
            <a href="/shop/r/{{$store.state.curProduct.id}}" class="fs-8 pe-2 text-gray-500 text-hover-primary">{{$store.state.curProduct.productName}}</a>
          </li>
          <li class="breadcrumb-item px-2 fs-8">
            <a href="#{{currentChapter.chapterName}}" class="fs-8 pe-2 text-gray-500 text-hover-primary">{{currentChapter.chapterName}}</a>
          </li> --}} -->
      <li class="breadcrumb-item pe-2 text-primary fs-8">
        <!-- {{!-- {{currentSession.sessionName}} --}} -->
        <a :href="`/shop/r/${$store.state.curProduct.id}`">{{
          $store.state.curProduct.productName
        }}</a>
      </li>
    </ol>
    <!--end::Breadcrumb-->
    <!--begin::Layout-->
    <div class="d-none d-flex flex-column gap-6">
      <div class="flex-lg-row-fluid">
        <!--begin::Session video-->
        <div class="card card-flush shadow-sm">
          <div class="card-header">
            <div class="card-title fs-2">
              <h1>
                <i class="bi bi-box fs-5 me-4 text-gray-800"></i
                >{{ $store.state.curProduct.productName }}
              </h1>
            </div>
            <div class="card-title">
              <i class="bi bi-star-fill text-warning fs-6 me-1">
                {{ $store.state.curProduct.productRate }}
              </i>
              <a href="" class="text-gray-500 fs-8 text-hover-primary fw-normal"
                >({{ $store.state.curProduct.productRate }} Vote)</a
              >
            </div>
          </div>
          <div class="card-body pt-0">
            <!--begin::Video-->
            <div class="mb-3">
              <!-- <video
                controls
                controlsList="nodownload"
                class="w-100 h-400px rounded"
                style="object-fit: fill"
                :src="`${backendHost}/video/${$store.state.curProduct.productChapters[currentChapter].chapterSessions[currentSession].sessionPhotos.uploadName}`"
                :type="
                  $store.state.curProduct.productChapters[currentChapter]
                    .chapterSessions[currentSession].sessionPhotos.uploadInfo
                    .mimetype
                "
              ></video> -->
              <video
                controls
                controlsList="nodownload"
                type="video/mp4"
                class="w-100 h-400px rounded"
                style="object-fit: fill"
              >
                <source
                  :src="`https:websiran.com/video/${$store.state.uploadsPath}/${$store.state.curProduct.productPhotos}?id=${videoStreamRefCode}`"
                  type="video/mp4"
                />
              </video>
              <!-- <source :src="`${backendHost}/video/${$store.state.uploadsPath}/${$store.state.curProduct.productPhotos}`" type="video/mp4"> -->
              <!-- <video
                  id="my-player"
                  class="video-js vjs-defaultskin"
                  controls
                  preload="auto"
                  poster="https://vjs.zencdn.net/v/oceans.png"
                  data-setup='{}'>
                <source src="https://vjs.zencdn.net/v/oceans.mp4" type="video/mp4"></source>
                <source src="https://vjs.zencdn.net/v/oceans.webm" type="video/webm"></source>
                <source src="https://vjs.zencdn.net/v/oceans.ogv" type="video/ogg"></source>
                <p class="vjs-no-js">
                  To view this video please enable JavaScript, and consider upgrading to a
                  web browser that
                  <a href="https://videojs.com/html5-video-support/" target="_blank">
                    supports HTML5 video
                  </a>
                </p>
              </video> -->
              <!-- <video
                id="videoJsPlayer"
                ref="videoJsPlayer"
                class="video-js vjs-defaultskin"
              ></video> -->
            </div>
            <!--end::Video-->
            <!--begin::Title-->
            <div class="d-flex flex-stack fs-5 mb-3">
              <!-- <div class="text-gray-800">
                <i class="bi bi-play-fill text-gray-700 fs-3 me-2"></i
                >{{
                  $store.state.curProduct.productChapters[currentChapter]
                    .chapterName
                }}
                /
                {{
                  $store.state.curProduct.productChapters[currentChapter]
                    .chapterSessions[currentSession].sessionName
                }}
              </div> -->
              <div class="text-gray-800">
                <i class="bi bi-play-fill text-gray-700 fs-3 me-2"></i>
                {{ $store.state.curProduct.productName }}
              </div>
              <div class="d-flex text-gray-800 fs-8">
                <span class="">{{ $store.state.curProduct.productDate }}</span>
                <span class="mx-2">-</span>
                <span class=""
                  >{{
                    $store.state.curProduct.productStudentsNo
                  }}
                  Students</span
                >
              </div>
            </div>
            <!--end::Title-->
            <!--begin::Teacher-->
            <div class="d-flex flex-stack">
              <a href="" class="link-dark d-flex align-items-center">
                <div class="symbol symbol-circle symbol-40px">
                  <img
                    :src="`${backendHost}/uploads/${$store.state.uploadsPath}/${$store.state.curProduct.profilePhoto}`"
                    alt=""
                    class="me-2"
                  />
                </div>
                <div class="d-flex flex-column text-gray-500 fs-8">
                  <span> {{ $store.state.curProduct.profileFullName }}</span>
                  <span></span>
                </div>
              </a>
              <div class="d-flex text-gray-500">
                <a href="" class="text-hover-primary"
                  ><i class="bi bi-heart fs-3 mx-2"></i
                ></a>
                <a href="" class="text-hover-primary"
                  ><i class="bi bi-bookmark-plus fs-3 mx-2"></i
                ></a>
                <a href="" class="text-hover-primary"
                  ><i class="bi bi-cloud-download fs-3 mx-2"></i
                ></a>
                <a href="" class="text-hover-primary"
                  ><i class="bi bi-share fs-3 mx-2"></i
                ></a>
              </div>
            </div>
            <!--end::Teacher-->
          </div>
        </div>
        <!--end::Session video-->
      </div>
      <div class="flex-column flex-lg-row-auto w-100 mb-10">
        <!--begin::Course list-->
        <div class="mb-5">
          <div
            v-for="(chapter, chap) in $store.state.curProduct.productChapters"
            :key="chap"
            class="my-5"
          >
            <!--begin::Chapter-->
            <div
              class="bg-primary col-12 bg__first text-white py-4 px-8 rounded-3 cursor-pointer"
              :href="`#kt_toggle_block_${chap}`"
              data-bs-toggle="collapse"
            >
              <div class="d-flex flex-stack">
                <!--begin::Toggle link-->
                <a
                  data-bs-toggle="collapse"
                  :href="`#kt_toggle_block_${chap}`"
                  class="text-white fw-boldest rotate collapsible"
                  :class="chap == 0 ? '' : 'collapsed'"
                >
                  <h2 class="text-white">{{ chapter.sessionTitle }}</h2>
                  <span
                    class="svg-icon svg-icon-3 svg-icon-white rotate-n180 ms-1"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill=""
                    >
                      <path
                        d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                        fill="black"
                      ></path>
                    </svg>
                  </span>
                </a>
                <!--end::Toggle link-->
                <!-- <div class="fs-8">
                  <span class=""
                    >{{ chapter.chapterSessions.length }} Sessions</span
                  >
                </div> -->
              </div>
            </div>
            <div
              :id="`kt_toggle_block_${chap}`"
              class="collapse"
              :class="chap == 0 ? 'show' : ''"
            >
              <div class="card card-body shadow-sm rounded-3 my-4">
                <!-- <a :href="`/${routeLocale}/productview/${this.$store.state.curProduct.id}/${chap}`" class="text-dark text-hover-primary"> -->
                <!-- <div class="d-flex flex-stack">
                  <div class="fs-8">
                    <i class="bi bi-unlock-fill fs-4 me-2"></i>
                    <span class="">{{ chapter.sessionSummary }}</span>
                  </div>
                  <div class="fs-8 d-flex align-items-center">
                    <span class="me-2">{{
                      chapter.sessionAttachments.length
                    }}</span>
                    <i class="bi bi-stopwatch"></i>
                  </div>
                </div> -->
                <!-- </a> -->
                <div class="d-flex flex-stack p-10">
                  <div
                    v-if="chapter.sessionType == 'teaching'"
                    style="text-align: justify"
                  >
                    <div id="sessionSummary">
                      <h3>خلاصه مطلب</h3>
                      <p class="px-5 fs-4">{{ chapter.sessionSummary }}</p>
                    </div>
                    <div v-if="chapter.sessionWebcam.length > 0" class="mt-10">
                      <h3>ویدیو آموزشی تصویری استاد</h3>
                      <video
                        controls
                        controlsList="nodownload"
                        class="w-100 h-400px rounded"
                        style="object-fit: fill"
                        :src="`${backendHost}/video/${$store.state.uploadsPath}/${chapter.sessionWebcam[0]}?id=${videoStreamRefCode}${chap}`"
                      ></video>
                    </div>
                    <div class="mt-10">
                      <h3>توضیحات تکمیلی آموزش</h3>
                      <div v-html="chapter.sessionDescription"></div>
                    </div>
                  </div>
                  <div v-else-if="chapter.sessionType == 'quiz'">
                    <div>
                      <h3>مبحث</h3>
                      <p class="px-5 fs-4">{{ chapter.sessionTopic }}</p>
                    </div>
                    <div>
                      <div v-if="chapter.sessionTopic == 'reading'">
                        <h3>Passage</h3>
                        <div class="d-flex flex-center mt-10">
                          <a
                            class="d-block overlay cursor-pointer"
                            @click="softBox(chapter.sessionAttachments[0])"
                          >
                            <div
                              class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-300px w-100 bg-secondary"
                              :style="`background-image: url(${backendHost}/uploads/${$store.state.uploadsPath}/${chapter.sessionAttachments[0]});`"
                            ></div>
                            <!--begin::Action-->
                            <div
                              class="overlay-layer card-rounded bg-dark bg-opacity-25"
                            >
                              <i class="bi bi-eye-fill fs-2x text-white"></i>
                            </div>
                            <!--end::Action-->
                          </a>
                        </div>
                      </div>
                      <div v-else-if="chapter.sessionTopic == 'listening'">
                        <h3>فایل شنیداری</h3>
                        <audio class="w-100" controls>
                            <!-- <source src="horse.ogg" type="audio/ogg"> -->
                            <source
                              :src="`${backendHost}/uploads/${$store.state.uploadsPath}/${chapter.sessionAttachments[0]}`"
                              type="audio/mpeg"
                            />
                            Your browser does not support the audio tag.
                          </audio>
                      </div>
                      <div v-else-if="chapter.sessionTopic == 'speaking'">
                        <h3>موضوع گفتگو</h3>
                      </div>
                      <div v-else-if="chapter.sessionTopic == 'writing'">
                        <h3>موضوع نگارش</h3>
                      </div>
                    </div>
                    <div id="sessionSummary">
                      <h3>سوال</h3>
                      <p class="px-5 fs-4">{{ chapter.sessionSummary }}</p>
                    </div>
                    <div class="mt-10">
                      <div v-if="chapter.sessionAnswerType == 'truefalse'">
                        <div class="form-check">
                          <label class="form-check-label" for="radiogroup_true">
                            درست
                          </label>
                          <input
                            class="form-check-input"
                            checked="true"
                            type="radio"
                            @click="setRadioGroup('', true)"
                            id="radiogroup_true"
                            name="truefalse_answer"
                          />
                        </div>
                        <div class="form-check mt-5">
                          <label
                            class="form-check-label"
                            for="radiogroup_flase"
                          >
                            نادرست
                          </label>
                          <input
                            class="form-check-input"
                            checked="false"
                            type="radio"
                            @click="setRadioGroup('', false)"
                            id="radiogroup_false"
                            name="truefalse_answer"
                          />
                        </div>
                      </div>
                      <div v-else-if="chapter.sessionAnswerType == '4choices'">
                        <div
                          v-for="(choice, index) in chapter.sessionChoices"
                          :key="choice"
                          class="form-check"
                        >
                          <label
                            class="form-check-label"
                            :for="`radiogroup_${index}`"
                          >
                            {{ choice }}
                          </label>
                          <input
                            class="form-check-input"
                            checked="true"
                            type="radio"
                            @click="setRadioGroup('', true)"
                            :id="`radiogroup_${index}`"
                            name="4choices_answer"
                          />
                        </div>
                      </div>
                      <div v-else-if="chapter.sessionAnswerType == 'short'">
                        <textarea
                          class="form-control form-control-solid p-4"
                          rows="2"
                          v-model="chapter.sessionAnswer"
                        ></textarea>
                      </div>
                      <div v-else-if="chapter.sessionAnswerType == 'long'">
                        <editor
                          api-key="rqvf4kbcv45gegveslou9yrhln98r31nx5fgpe0ctwd4jgkv"
                          v-model="chapter.sessionAnswer"
                          :init="{ height: 200 }"
                        />
                      </div>
                    </div>
                    <div
                      v-if="chapter.sessionAttachments.length > 0"
                      class="mt-10"
                    >
                      <h3>ویدیو آموزشی تصویری استاد</h3>
                      <video
                        controls
                        controlsList="nodownload"
                        class="w-100 h-400px rounded"
                        style="object-fit: fill"
                        :src="`${backendHost}/video/${$store.state.uploadsPath}/${chapter.sessionAttachments[0]}?id=${videoStreamRefCode}${chap}`"
                      ></video>
                    </div>
                    <div class="mt-10">
                      <h3>توضیحات تکمیلی آموزش</h3>
                      <div
                        class="px-5 fs-4"
                        v-html="chapter.sessionDescription"
                      ></div>
                    </div>
                  </div>
                  <div
                    v-else-if="chapter.sessionType == 'homework'"
                    style="text-align: justify"
                  >
                    <div id="sessionSummary">
                      <h3>سوال تمرین در منزل</h3>
                      <p class="px-5 fs-4">{{ chapter.sessionSummary }}</p>
                    </div>
                    <div
                      v-if="chapter.sessionAttachments.length > 0"
                      class="mt-10"
                    >
                      <h3>فایل پیوست تمرین</h3>
                      <video
                        controls
                        controlsList="nodownload"
                        class="w-100 h-400px rounded"
                        style="object-fit: fill"
                        :src="`${backendHost}/video/${$store.state.uploadsPath}/${chapter.sessionAttachments[0]}?id=${videoStreamRefCode}${chap}`"
                      ></video>
                    </div>
                    <div class="mt-10">
                      <h3>توضیحات تکمیلی آموزش</h3>
                      <div v-html="chapter.sessionDescription"></div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div
                v-for="(session, sess) in chapter.chapterSessions"
                :key="sess"
                class="card card-body shadow-sm rounded-3 my-4"
              >
                <a :href="`/${routeLocale}/products/${chap}/${sess}`" class="text-dark text-hover-primary">
                  <div class="d-flex flex-stack">
                    <div class="fs-8">
                      <i class="bi bi-unlock-fill fs-4 me-2"></i>
                      <div class="symbol symbol-25px me-3">
                        <div class="symbol-label fs-4">{{ chap }}</div>
                      </div>
                      <span class="">{{ session.sessionName }}</span>
                    </div>
                    <div class="fs-8 d-flex align-items-center">
                      <span class="me-2">{{
                        session.sessionPhotos.uploadInfo.duration
                      }}</span>
                      <i class="bi bi-stopwatch"></i>
                    </div>
                  </div>
                </a>
              </div> -->
            </div>
            <!--end::Chapter-->
          </div>
        </div>
        <!--end::Course list-->
      </div>
    </div>
    <div
      class="d-flex flex-column flex-lg-row justify-content-between align-items-start mt-10"
    >
      <div
        class="shadow bg-white rounded p-5 col-12 col-lg-3 d-flex flex-column justify-content-center align-items-center h-100"
      >
        <h2 class="fs-1">Table of content</h2>
        <div class="w-100 mt-2">
          <button class="text-start btn btn-outline btn-active-secondary w-100" :class="displayStep == 0 ? 'active' : ''" @click="setDisplayStep(0)">
            Intro
          </button>
        </div>
        <div
          v-for="(_, index) in $store.state.curProduct.productChapters"
          :key="index"
          class="w-100 mt-2"
        >
          <button class="text-start btn btn-outline btn-active-secondary w-100" :class="displayStep == index + 1 ? 'active' : ''" @click="setDisplayStep(index + 1)">
            Session {{ index + 1 }}
          </button>
        </div>
      </div>
      <div
        class="col-12 col-lg-8 d-flex flex-column justify-content-center align-items-center"
      >
        <div v-if="displayStep == 0" class="card card-flush shadow-sm w-100">
          <div class="card-header">
            <div class="card-title">
              <h1>
                <i class="bi bi-box fs-5 me-4 text-gray-800"></i>
                {{ $store.state.curProduct.productName }}
              </h1>
            </div>
            <div class="card-title d-flex flex-column">
              <div class="col-12 text-center">
                <span class="me-1"> 4.5 </span>
                <i class="bi bi-star-fill text-warning fs-6 me-1"></i>
              </div>
              <div class="col-12 text-center">
                <a
                  href="#"
                  class="text-gray-500 fs-8 text-hover-primary fw-normal"
                >
                  (1.500 Vote)
                </a>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="mb-10">
              <div class="mb-10">
                <a
                  class="d-block overlay cursor-pointer"
                  @click="
                    softBox(
                      $store.state.curProduct.productPhotos.length > 1
                        ? $store.state.curProduct.productPhotos[1]
                        : $store.state.curProduct.productPhotos[0]
                    )
                  "
                >
                  <div
                    class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-400px w-100 bg-secondary"
                    :style="`background-image: url(${backendHost}/uploads/${$store.state.uploadsPath}/${$store.state.curProduct.productPhotos[0]});`"
                  ></div>
                  <div class="overlay-layer card-rounded bg-dark bg-opacity-25">
                    <i class="bi bi-eye-fill fs-2x text-white"></i>
                  </div>
                </a>
              </div>
              <div class="d-flex flex-row justify-content-between mb-10">
                <div class="col-12 text-gray-800 text-end fs-4">
                  <i class="bi bi-play-fill text-gray-600 me-2 fs-4"></i>
                  {{ $store.state.curProduct.productSummary }}
                </div>
                <div
                  class="d-none col-4 d-flex flex-column justify-content-between text-start text-gray-600 fs-5"
                >
                  <div class="col-12">
                    <span class="me-2">تاریخ انتشار:</span>
                    <span class="fw-boldest">{{
                      $store.state.curProduct.productDate
                    }}</span>
                  </div>
                  <div class="col-12">
                    <span class="me-2 fw-boldest">1000</span>
                    <span class="">دانش آموز</span>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-stack">
                <a href="" class="link-dark d-flex align-items-center">
                  <div class="symbol symbol-circle symbol-40px">
                    <img
                      :src="`${backendHost}/uploads/${$store.state.uploadsPath}/${$store.state.curProduct.profilePhoto}`"
                      alt=""
                      class="me-2"
                    />
                  </div>
                  <div class="d-flex flex-column text-gray-600 fs-4">
                    <span> {{ $store.state.curProduct.profileFullName }}</span>
                    <span></span>
                  </div>
                </a>
                <div class="d-flex text-gray-500">
                  <a href="" class="text-hover-primary"
                    ><i class="bi bi-heart fs-3 mx-2"></i
                  ></a>
                  <a href="" class="text-hover-primary"
                    ><i class="bi bi-bookmark-plus fs-3 mx-2"></i
                  ></a>
                  <a href="" class="text-hover-primary"
                    ><i class="bi bi-cloud-download fs-3 mx-2"></i
                  ></a>
                  <a href="" class="text-hover-primary"
                    ><i class="bi bi-share fs-3 mx-2"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-for="(chapter, ci) in $store.state.curProduct.productChapters"
            :key="ci"
            class="card card-flush shadow-sm w-100"
          >
            <div class="d-none card-header">
              <div class="card-title">
                <h1>
                  <i class="bi bi-box fs-5 me-4 text-gray-800"></i>
                  {{ $store.state.curProduct.productName }}
                </h1>
              </div>
              <div class="card-title d-flex flex-column">
                <div class="col-12 text-center">
                  <span class="me-1"> 4.5 </span>
                  <i class="bi bi-star-fill text-warning fs-6 me-1"></i>
                </div>
                <div class="col-12 text-center">
                  <a
                    href="#"
                    class="text-gray-500 fs-8 text-hover-primary fw-normal"
                  >
                    (1.500 Vote)
                  </a>
                </div>
              </div>
            </div>
            <div v-if="ci + 1 == displayStep" class="card-body">
              <div v-for="(part, pi) in chapter.sessionFramework" :key="pi" class="mb-10">
                <div v-html="part['summary']"></div>
                <div class="mt-10 w-100">
                  <div
                    v-if="
                      part['objType'] == 'video'
                    "
                  >
                    <video
                      controls
                      controlsList="nodownload"
                      class="w-100 h-400px rounded"
                      style="object-fit: fill"
                      :src="`${backendHost}/video/${$store.state.uploadsPath}/${part['obj']['file'][0]}`"
                    ></video>
                  </div>
                  <div
                    v-else-if="
                      part['objType'] == 'audio'
                    "
                  >
                    <audio class="w-100" controls>
                      <source
                        :src="`${backendHost}/video/${$store.state.uploadsPath}/${part['obj']['file'][0]}`"
                        type="audio/mpeg"
                      />
                      Your browser does not support the audio tag.
                    </audio>
                  </div>
                  <div v-else-if="part['objType'] == 'image'" class="row justify-content-center align-items-center">
                    <div v-for="photo in part['obj']['file']" :key="photo" class="mb-10 col-12 p-3" :class="part['obj']['file'].length > 1 ? 'col-lg-6' : ''">
                      <a
                        class="d-block overlay cursor-pointer"
                        @click="
                          softBox(
                            part['obj']['file']
                          )
                        "
                      >
                        <div
                          class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded w-100 h-200px bg-secondary"
                          :style="`background-image: url(${backendHost}/video/${$store.state.uploadsPath}/${photo});`"
                        ></div>
                        <div
                          class="overlay-layer card-rounded bg-dark bg-opacity-25"
                        >
                          <i
                            class="bi bi-eye-fill fs-2x text-white"
                          ></i>
                        </div>
                      </a>
                      <div v-if="part['ansStatus']">
                        <textarea
                          class="form-control form-control-solid p-4 mt-2"
                          name="" id="" rows="3" placeholder="Write down your answer">
                        </textarea>
                      </div>
                    </div>
                  </div>
                  <div
                    v-else-if="
                      part['objType'] == 'table'
                    "
                  >
                    <table class="w-100 border border-1 border-gray-500 p-5">
                      <tr class="border border-1 border-gray-500">
                        <th class="text-center p-3" v-for="h in part.obj.headers" :key="h" :class="h.length > 0 ? '' : 'd-none'">{{ h }}</th>
                      </tr>
                      <tr v-for="r in part.obj.records" :key="r" :class="r.length > 0 ? '' : 'd-none'" class="border border-1 border-gray-400">
                        <td v-for="d in r" :key="d" :class="d.length > 0 ? '' : 'd-none'" class="text-center p-2">{{ d }}</td>
                      </tr>
                    </table>
                  </div>
                  <div v-else v-html="part['obj']['text']"></div>
                  {{ part['ansType'] }}
                  <div v-if="part['ansType'] && part['ansType'].length > 0">
                    <div v-if="part['ansType'] == 'upload'"
                      @click="
                        setModalConfigs('choose', {
                          root: 'courseSessions',
                          index: hafezliSessionNo,
                          name: 'quiz',
                          depth: 9,
                        })
                      "
                      class="fv-row"
                    >
                      <div
                        class="dropzone bg-light-primary rounded border-primary border border-dashed"
                        id="kt_dropzonejs_example_1"
                      >
                        <div
                          v-if="
                            ansForm.file &&
                            ansForm.file.length > 0
                          "
                          class="d-flex flex-center flex-wrap scroll-y mh-200px"
                        >
                          <div
                            v-for="photo in hafezliCoursesObj.courseSessions[hafezliSessionNo]['sessionFramework'][hafezliWizardStep]['quiz']['obj']['file']"
                            :key="photo"
                            class="d-flex flex-column flex-center bg-dark bg-opacity-5 rounded border border-dashed border-primary p-5 m-2 col-6 col-md-3 col-lg-2"
                          >
                          <div class="symbol symbol-100px">
                            <!-- <img
                              :src="`${backendHost}/uploads/${$store.state.uploadsPath}/${photo}`"
                            /> -->
                            <img src="/assets/images/png/uploadfilepreview.png" alt="upload.png">
                          </div>
                          <label for="">{{photo.split(".")[0]}}</label>
                          <label for="">{{photo.split(".")[1].toUpperCase()}}</label>
                          </div>
                        </div>
                        <div
                          v-else
                          class="dz-message needsclick text-start justify-content-center justify-content-lg-start"
                        >
                          <i
                            class="bi bi-file-earmark-arrow-up text-primary fs-3x"
                          ></i>
                          <div class="ms-4">
                            <h3 class="fs-5 fw-bolder text-gray-900 mb-1">
                              جهت بارگذاری فایل ضربه بزنید
                            </h3>
                            <span class="fs-7 fw-bold text-gray-400"
                              >حداکثر 2 فایل قابل بارگذاری است</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="part['quiz']" class="border border-1 border-dashed border-gray-400 p-10 mt-10">
                  <h2>Quiz Part</h2>
                  <div class="w-100">
                    <div
                      v-if="
                        part['quiz']['objType'] == 'video'
                      "
                    >
                      <video
                        controls
                        controlsList="nodownload"
                        class="w-100 h-400px rounded"
                        style="object-fit: fill"
                        :src="`${backendHost}/video/${$store.state.uploadsPath}/${part['quiz']['obj']['file'][0]}`"
                      ></video>
                    </div>
                    <div
                      v-else-if="
                        part['quiz']['objType'] == 'audio'
                      "
                    >
                      <audio class="w-100" controls>
                        <source
                          :src="`${backendHost}/video/${$store.state.uploadsPath}/${part['quiz']['obj']['file'][0]}`"
                          type="audio/mpeg"
                        />
                        Your browser does not support the audio tag.
                      </audio>
                    </div>
                    <div v-else-if="part['quiz']['objType'] == 'image'">
                      <div class="mb-10">
                        <a
                          class="d-block overlay cursor-pointer"
                          @click="
                            softBox(
                              part['quiz']['obj']['file']
                            )
                          "
                        >
                          <div
                            class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-400px w-100 bg-secondary"
                            :style="`background-image: url(${backendHost}/video/${$store.state.uploadsPath}/${part['quiz']['obj']['file'][0]});`"
                          ></div>
                          <div
                            class="overlay-layer card-rounded bg-dark bg-opacity-25"
                          >
                            <i
                              class="bi bi-eye-fill fs-2x text-white"
                            ></i>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div
                      v-else-if="
                        part['quiz']['objType'] == 'table'
                      "
                    >
                      <table class="w-100 border border-1 border-gray-500 p-5">
                        <tr>
                          <th class="text-center p-3" v-for="h in part['quiz'].obj.headers" :key="h" :class="h.length > 0 ? '' : 'd-none'">{{ h }}</th>
                        </tr>
                        <tr v-for="r in part['quiz'].obj.records" :key="r" :class="r.length > 0 ? '' : 'd-none'">
                          <td v-for="d in r" :key="d" :class="d.length > 0 ? '' : 'd-none'" class="text-center p-2">{{ d }}</td>
                        </tr>
                      </table>
                    </div>
                    <div v-else v-html="part['quiz']['obj']['text']"></div>
                  </div>
                  <div v-if="part.quiz.forms.length > 0">
                    <div v-for="(f, fi) in part.quiz.forms" :key="fi" class="d-flex flex-column mt-10">
                      <!-- <h4>QA {{ fi + 1 }}. <span v-html="f.question"></span></h4> -->
                       <h4 v-html="f.question"></h4>
                      <div>
                        <div v-if="f.type == '2choices' || f.type == '3choices' || f.type == '4choices'">
                          <div class="row justify-content-center align-items-center">
                            <div v-for="o in f.options" class="px-7 py-5 col-12 col-lg-6 d-flex flex-row justify-content-between align-items-center" :class="o.length > 0 ? '' : 'd-none'" :key="o">
                              <label class="col-11 fs-5 fw-bolder form-label m-0">
                                {{ o }}
                              </label>
                              <input class="form-control form-control-solid p-4"
                                type="radio"
                              />
                            </div>
                          </div>
                        </div>
                        <div v-else-if="f.type == 'shortAns'">
                          <div class="w-100">
                            <input class="form-control form-control-solid p-4"
                              type="text"
                              placeholder="Answer here"
                            />
                          </div>
                        </div>
                        <div v-else-if="f.type == 'longAns'">
                          <div class="w-100">
                            <textarea
                              class="form-control form-control-solid p-4 mt-2"
                              name="" id="" rows="10" placeholder="Write down your answer"></textarea>
                          </div>
                        </div>
                        <div v-else-if="f.type == 'clozeTest'">
                          <div class="w-100 border border-1 border-dark rounded row justify-content-center align-items-center p-5 gap-2">
                            <span v-for="o in f.options" :key="o" class="fs-6 col-2" :class="o.length > 0 ? '' : 'd-none'">{{ o }}</span>
                          </div>
                        </div>
                        <div v-else-if="f.type == 'matching'">
                          In developing ...
                          <div class="d-none w-100">
                            <div class="d-flex flex-column justify-content-center align-items-center">
                              <div v-for="o in f.matchingOptions" class="mt-3 d-flex flex-row justify-content-between align-items-center" :key="o">
                                <button type="button" class="btn btn-secondary">{{ o.text }}</button>
                                <button type="button" class="btn btn-secondary">{{ o.ans }}</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-center mt-5">
                      <button type="button" class="btn btn-primary" @click="submitAnswer">Submit your Answer</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-none d-flex flex-column w-100">
          <div
            v-for="(chapter, index) in $store.state.curProduct
              .productChapters"
            :key="index"
          >
            <div class="my-10">
              <div
                v-if="
                  index == 0 ||
                  $store.state.curProduct.productChapters[index]
                    .sessionType !=
                    $store.state.curProduct.productChapters[index - 1]
                      .sessionType
                "
                class="bg-primary col-12 bg__first text-white py-4 px-8 rounded-3 cursor-pointer"
              >
                <div class="d-flex flex-stack">
                  <a
                    data-bs-toggle="collapse"
                    :href="`#kt_toggle_block_${index}`"
                    class="text-white fw-boldest rotate collapsible"
                  >
                    <h2 class="text-white">
                      {{ index + 1 }} - {{ chapter.sessionTitle }}
                    </h2>
                    <span
                      class="text-end svg-icon svg-icon-3 svg-icon-white rotate-n180 ms-1"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill=""
                      >
                        <path
                          d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                          fill="black"
                        ></path>
                      </svg>
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div
              v-if="chapter.sessionType == 'teaching'"
              :id="`kt_toggle_block_${index}`"
              class="collapse show"
            >
              <div
                class="card card-body shadow-sm rounded-3 my-4 bg-light-primary"
              >
                <div class="d-flex flex-stack p-10">
                  <div style="text-align: justify" class="w-100">
                    <div id="sessionSummary">
                      <h3>خلاصه مبحث</h3>
                      <p class="fs-4">
                        {{ chapter.sessionSummary }}
                      </p>
                    </div>
                    <div
                      v-if="
                        chapter.sessionAttachments &&
                        chapter.sessionAttachments.length > 0
                      "
                      class="mt-10 w-100"
                    >
                      <div
                        v-if="
                          chapter.sessionAttachments[0].includes('.mp4')
                        "
                      >
                        <h3>ویدیو آموزش مبحث</h3>
                        <video
                          controls
                          controlsList="nodownload"
                          class="w-100 h-400px rounded"
                          style="object-fit: fill"
                          :src="`${backendHost}/video/${$store.state.uploadsPath}/${chapter.sessionAttachments[0]}`"
                        ></video>
                      </div>
                      <div
                        v-else-if="
                          chapter.sessionAttachments[0].includes('.mp3') ||
                          chapter.sessionAttachments[0].includes('.m4a')
                        "
                      >
                        <h3>صوت آموزش مبحث</h3>
                        <audio class="w-100" controls>
                          <!-- <source src="horse.ogg" type="audio/ogg"> -->
                          <source
                            :src="`${backendHost}/uploads/${$store.state.uploadsPath}/${chapter.sessionAttachments[0]}`"
                            type="audio/mpeg"
                          />
                          Your browser does not support the audio tag.
                        </audio>
                      </div>
                      <div v-else>
                        <!-- <h3>تصویر آموزش مبحث</h3> -->
                        <div class="mb-10">
                          <a
                            class="d-block overlay cursor-pointer"
                            @click="
                              softBox(
                                chapter.sessionAttachments
                              )
                            "
                          >
                            <div
                              class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-400px w-100 bg-secondary"
                              :style="`background-image: url(${backendHost}/uploads/${$store.state.uploadsPath}/${chapter.sessionAttachments[0]});`"
                            ></div>
                            <div
                              class="overlay-layer card-rounded bg-dark bg-opacity-25"
                            >
                              <i
                                class="bi bi-eye-fill fs-2x text-white"
                              ></i>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        chapter.sessionDescription &&
                        chapter.sessionDescription.length > 0
                      "
                      class="mt-10"
                    >
                      <h3>متن آموزشی مبحث</h3>
                      <div v-html="chapter.sessionDescription"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else-if="chapter.sessionType == 'quiz'"
              :id="`kt_toggle_block_${index}`"
              class="collapse show"
            >
              <div class="card-body shadow-sm rounded-3 bg-light-primary">
                <div class="d-flex flex-stack p-10">
                  <div style="text-align: justify" class="w-100">
                    <div
                      v-if="
                        chapter.sessionAttachments &&
                        chapter.sessionAttachments.length > 0
                      "
                      class="mt-10"
                    >
                      <div
                        v-if="
                          chapter.sessionAttachments[0].includes('.mp4') ||
                          chapter.sessionAttachments[0].includes('.avi')
                        "
                      >
                        <h3>ویدیو آموزش مبحث</h3>
                        <video
                          controls
                          controlsList="nodownload"
                          class="w-100 h-400px rounded"
                          style="object-fit: fill"
                          :src="`${backendHost}/video/${$store.state.uploadsPath}/${chapter.sessionAttachments[0]}`"
                        ></video>
                      </div>
                      <div
                        v-else-if="
                          chapter.sessionAttachments[0].includes('.mp3') ||
                          chapter.sessionAttachments[0].includes('.m4a')
                        "
                      >
                        <h3>صوت آموزش مبحث</h3>
                        <audio class="w-100" controls>
                          <!-- <source src="horse.ogg" type="audio/ogg"> -->
                          <source
                            :src="`${backendHost}/uploads/${$store.state.uploadsPath}/${chapter.sessionAttachments[0]}`"
                            type="audio/mpeg"
                          />
                          Your browser does not support the audio tag.
                        </audio>
                      </div>
                      <div v-else>
                        <!-- <h3>تصویر آموزش مبحث</h3> -->
                        <div class="mb-10">
                          <a
                            class="d-block overlay cursor-pointer"
                            @click="
                              softBox(
                                chapter.sessionAttachments &&
                                chapter.sessionAttachments.length > 1
                                  ? chapter.sessionAttachments[1]
                                  : chapter.sessionAttachments[0]
                              )
                            "
                          >
                            <div
                              class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-400px w-100 bg-secondary"
                              :style="`background-image: url(${backendHost}/uploads/${$store.state.uploadsPath}/${chapter.sessionAttachments[0]});`"
                            ></div>
                            <div
                              class="overlay-layer card-rounded bg-dark bg-opacity-25"
                            >
                              <i
                                class="bi bi-eye-fill fs-2x text-white"
                              ></i>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div id="sessionSummary">
                      <h3>سوال</h3>
                      <p class="fs-4">
                        {{ chapter.sessionSummary }}
                      </p>
                      <div
                        class="separator separator-dashed bg-primary"
                      ></div>
                      <div v-if="chapter.sessionAnswerType == 'multiblank'">
                        <div class="pt-5 mt-5">
                          <p class="fw-boldest">پاسخ جای خالی 1</p>
                          <div class="row mt-2">
                            <div class="col-6 p-4">
                              <input
                                type="radio"
                                name="action"
                                id="track"
                                value="track"
                                class="me-2"
                              /><label for="track">مصطفی طباطبائی</label
                              ><br />
                            </div>
                            <div class="col-6 p-4">
                              <input
                                type="radio"
                                name="action"
                                id="track"
                                value="track"
                                class="me-2"
                              /><label for="track">شیما خالقی</label><br />
                            </div>
                            <div class="col-6 p-4">
                              <input
                                type="radio"
                                name="action"
                                id="track"
                                value="track"
                                class="me-2"
                              /><label for="track">تکتم پرقوه</label><br />
                            </div>
                            <div class="col-6 p-4">
                              <input
                                type="radio"
                                name="action"
                                id="track"
                                value="track"
                                class="me-2"
                              /><label for="track">هیچکدام</label><br />
                            </div>
                          </div>
                        </div>
                        <div class="pt-5 mt-5">
                          <div class="w-100 d-flex flex-column">
                            <p class="col-12 fw-boldest">پاسخ جای خالی 2</p>
                            <input
                              class="col-12"
                              type="text"
                              name="action"
                              id="message"
                              value=""
                              placeholder="پاسخ خود را درج کنید"
                            />
                          </div>
                        </div>
                        <div class="pt-5 mt-5">
                          <p class="fw-boldest">پاسخ جای خالی 3</p>
                          <div class="row mt-2">
                            <div class="col-6 p-4">
                              <input
                                type="radio"
                                name="action"
                                id="track"
                                value="track"
                                class="me-2"
                              /><label for="track">پروانه قاسمی</label
                              ><br />
                            </div>
                            <div class="col-6 p-4">
                              <input
                                type="radio"
                                name="action"
                                id="track"
                                value="track"
                                class="me-2"
                              /><label for="track">شهراد علائی</label><br />
                            </div>
                          </div>
                        </div>
                        <div class="pt-5 mt-5">
                          <p class="fw-boldest">پاسخ جای خالی 4</p>
                          <div class="row mt-2">
                            <div class="col-6 p-4">
                              <input
                                type="radio"
                                name="action"
                                id="track"
                                value="track"
                                class="me-2"
                              /><label for="track">KC-CES</label><br />
                            </div>
                            <div class="col-6 p-4">
                              <input
                                type="radio"
                                name="action"
                                id="track"
                                value="track"
                                class="me-2"
                              /><label for="track">MIC</label><br />
                            </div>
                            <div class="col-6 p-4">
                              <input
                                type="radio"
                                name="action"
                                id="track"
                                value="track"
                                class="me-2"
                              /><label for="track">CO-COMP</label><br />
                            </div>
                            <div class="col-6 p-4">
                              <input
                                type="radio"
                                name="action"
                                id="track"
                                value="track"
                                class="me-2"
                              /><label for="track">هیچکدام</label><br />
                            </div>
                          </div>
                        </div>
                        <div class="pt-5 mt-5">
                          <p class="fw-boldest">پاسخ جای خالی 5</p>
                          <div class="row mt-2">
                            <div class="col-6 p-4">
                              <input
                                type="radio"
                                name="action"
                                id="track"
                                value="track"
                                class="me-2"
                              /><label for="track">دکتر حق پناه</label
                              ><br />
                            </div>
                            <div class="col-6 p-4">
                              <input
                                type="radio"
                                name="action"
                                id="track"
                                value="track"
                                class="me-2"
                              /><label for="track">لنا صبوحی</label><br />
                            </div>
                          </div>
                        </div>
                        <div class="pt-5 mt-5">
                          <div class="w-100 d-flex flex-column">
                            <p class="col-12 fw-boldest">پاسخ جای خالی 6</p>
                            <input
                              class="col-12"
                              type="text"
                              name="action"
                              id="message"
                              value=""
                              placeholder="پاسخ خود را درج کنید"
                            />
                          </div>
                        </div>
                      </div>
                      <div v-else class="mt-10">
                        <div
                          v-if="chapter.sessionAnswerType == 'truefalse'"
                        >
                          <div class="row">
                            <div class="col-6 p-2">
                              <button
                                class="w-100 btn btn-primary"
                                :class="
                                  chapter['sessionAns'] &&
                                  chapter['sessionAns'].length > 0 &&
                                  'true' == chapter['sessionAns']
                                    ? 'btn-warning'
                                    : ''
                                "
                                @click="
                                  setRadioGroup(chapter, 'true')
                                "
                              >
                                True
                              </button>
                            </div>
                            <div class="col-6 p-2">
                              <button
                                class="w-100 btn btn-primary"
                                :class="
                                  chapter['sessionAns'] &&
                                  chapter['sessionAns'].length > 0 &&
                                  'false' == chapter['sessionAns']
                                    ? 'btn-warning'
                                    : ''
                                "
                                @click="
                                  setRadioGroup(chapter, 'false')
                                "
                              >
                                False
                              </button>
                            </div>
                          </div>
                        </div>
                        <div
                          v-else-if="
                            chapter.sessionAnswerType == '4choices'
                          "
                          class="row"
                        >
                          <div
                            v-for="choice in getChoices(chapter)"
                            :key="choice"
                            class="col-6 p-2"
                          >
                            <button
                              v-if="choice.length > 0"
                              class="w-100 btn btn-primary"
                              :class="
                                chapter['sessionAns'] &&
                                chapter['sessionAns'].length > 0 &&
                                choice == chapter['sessionAns']
                                  ? 'btn-warning'
                                  : ''

                              "
                              @click="setRadioGroup(chapter, choice)"
                            >
                              {{ choice }}
                            </button>
                          </div>
                        </div>
                        <div
                          v-else-if="
                            chapter.sessionAnswerType == 'matching'
                          "
                          class="d-flex flex-row justify-content-between"
                        >
                          <div class="col-4 d-flex flex-column align-items-center justify-content-center">
                            <div
                              v-for="choice in getQuestionChoices(chapter)"
                              :key="choice"
                              class="col-12 p-2"
                            >
                              <button
                                class="w-100 btn btn-primary"
                                :class="
                                  chapter['sessionAns'] &&
                                  chapter['sessionAns'].length > 0 &&
                                  choice == chapter['sessionAns']
                                    ? 'btn-warning'
                                    : ''

                                "
                                @click="setRadioGroup(chapter, choice)"
                              >
                                {{ choice }}
                              </button>
                            </div>
                          </div>
                          <div class="col-4 d-flex flex-column align-items-center justify-content-center">
                            <div
                              v-for="choice in getAnswerChoices(chapter)"
                              :key="choice"
                              class="col-12 p-2"
                            >
                              <button
                                class="w-100 btn btn-primary"
                                :class="
                                  chapter['sessionAns'] &&
                                  chapter['sessionAns'].length > 0 &&
                                  choice == chapter['sessionAns']
                                    ? 'btn-warning'
                                    : ''

                                "
                                @click="setRadioGroup(chapter, choice)"
                              >
                                {{ choice }}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div
                          v-else-if="chapter.sessionAnswerType == 'short'"
                        >
                          <textarea
                            class="form-control form-control-white p-4 border-1 border-dark"
                            rows="2"
                            v-model="chapter['sessionAns']"
                          ></textarea>
                        </div>
                        <div
                          v-else-if="chapter.sessionAnswerType == 'long'"
                        >
                          <editor
                            api-key="rqvf4kbcv45gegveslou9yrhln98r31nx5fgpe0ctwd4jgkv"
                            v-model="chapter['sessionAns']"
                            :init="{ height: 200 }"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        chapter.sessionDescription &&
                        chapter.sessionDescription.length > 0
                      "
                      class="mt-10"
                    >
                      <h3>متن آموزشی مبحث</h3>
                      <div v-html="chapter.sessionDescription"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer bg-light-primary d-flex flex-center">
                <button
                  class="btn btn-success btn-active-light-success"
                  @click="getAnswer(chapter)"
                >
                  ثبت پاسخ
                </button>
              </div>
            </div>
            <div
              v-else-if="chapter.sessionType == 'homework'"
              :id="`kt_toggle_block_${index}`"
              class="collapse show"
            >
              <div class="card-body shadow-sm rounded-3 bg-light-primary">
                <div class="d-flex flex-stack p-10">
                  <div style="text-align: justify" class="w-100">
                    <div
                      v-if="
                        chapter.sessionAttachments &&
                        chapter.sessionAttachments.length > 0
                      "
                      class="mt-10"
                    >
                      <div
                        v-if="
                          chapter.sessionAttachments[0].includes('.mp4') ||
                          chapter.sessionAttachments[0].includes('.avi')
                        "
                      >
                        <h3>ویدیو آموزش مبحث</h3>
                        <video
                          controls
                          controlsList="nodownload"
                          class="w-100 h-400px rounded"
                          style="object-fit: fill"
                          :src="`${backendHost}/video/${$store.state.uploadsPath}/${chapter.sessionAttachments[0]}`"
                        ></video>
                      </div>
                      <div
                        v-else-if="
                          chapter.sessionAttachments[0].includes('.mp3') ||
                          chapter.sessionAttachments[0].includes('.m4a')
                        "
                      >
                        <h3>صوت آموزش مبحث</h3>
                        <audio class="w-100" controls>
                          <!-- <source src="horse.ogg" type="audio/ogg"> -->
                          <source
                            :src="`${backendHost}/uploads/${$store.state.uploadsPath}/${chapter.sessionAttachments[0]}`"
                            type="audio/mpeg"
                          />
                          Your browser does not support the audio tag.
                        </audio>
                      </div>
                      <div v-else>
                        <!-- <h3>تصویر آموزش مبحث</h3> -->
                        <div class="mb-10">
                          <a
                            class="d-block overlay cursor-pointer"
                            @click="
                              softBox(
                                chapter.sessionAttachments &&
                                chapter.sessionAttachments.length > 1
                                  ? chapter.sessionAttachments[1]
                                  : chapter.sessionAttachments[0]
                              )
                            "
                          >
                            <div
                              class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-400px w-100 bg-secondary"
                              :style="`background-image: url(${backendHost}/uploads/${$store.state.uploadsPath}/${chapter.sessionAttachments[0]});`"
                            ></div>
                            <div
                              class="overlay-layer card-rounded bg-dark bg-opacity-25"
                            >
                              <i
                                class="bi bi-eye-fill fs-2x text-white"
                              ></i>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div id="sessionSummary">
                      <h3>سوال</h3>
                      <p class="fs-4">
                        {{ chapter.sessionSummary }}
                      </p>
                    </div>
                    <div
                      v-if="
                        chapter.sessionDescription &&
                        chapter.sessionDescription.length > 0
                      "
                      class="mt-10"
                    >
                      <h3>متن آموزشی مبحث</h3>
                      <div v-html="chapter.sessionDescription"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer bg-light-primary d-flex flex-center">
                <button
                  class="btn btn-success btn-active-light-success"
                  @click="getAnswer(chapter)"
                >
                  ثبت پاسخ
                </button>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
    <div>
      <FsLightbox :toggler="toggler" :sources="softBoxSource" />
    </div>
    <answer-dialog
      v-if="dialogDisplay"
      :modalInfo="answerInfo"
      @close-modal="setModalConfigs"
    >
    </answer-dialog>
    <!--end::Layout-->
  </div>
</template>

<script>
import serverConfigs from "../../../configs/server.json";
import FsLightbox from "fslightbox-vue/v3";
import AnswerDialog from "../../modals/AnswerDialog.vue";
import Editor from "@tinymce/tinymce-vue";
// import videojs from "video.js";

export default {
  components: {
    AnswerDialog,
    FsLightbox,
    editor: Editor,
  },
  computed: {
    videoStreamRefCode() {
      return `${this.$store.state.profile.profileUser}${new Date().getTime()}`;
    },
    currentChapter() {
      return this.$route.params.chapter;
    },
    currentSession() {
      return this.$route.params.session;
    },
    backendHost() {
      return serverConfigs["serverDomain"]["host"];
    },
    routeLocale() {
      return this.$route.params.locale;
    },
  },
  data() {
    return {
      ansForm: {
        file: []
      },
      displayStep: 0,
      answerInfo: {},
      dialogDisplay: false,
      player: null,
      toggler: false,
      softBoxSource: [],
    };
  },
  methods: {
    submitAnswer() {
      alert("Your answer has been saved successfully!")
    },
    setModalConfigs() {
      this.dialogDisplay = false
    },
    softBox(src) {
      if (typeof src == 'object') {
        this.softBoxSource = []
        for (let i in src) {
          this.softBoxSource.push(`${this.backendHost}/uploads/${this.$store.state.uploadsPath}/${src[i]}`);
        }
      } else {
        this.softBoxSource.push(`${this.backendHost}/uploads/${this.$store.state.uploadsPath}/${src}`);
      }
      
      this.toggler = !this.toggler;
    },
    setRadioGroup(obj, value) {
      obj["sessionAns"] = value;
    },
    getChoices(obj) {
      obj.sessionChoices[3] = obj.sessionAnswer;
      let tempArray = []
      Object.assign(tempArray, obj.sessionChoices)
      console.log(tempArray)

      let iii = 0
      let max = 3
      let index = []
      while (iii < 4) {
        let indexTemp = Math.round(Math.random() * max);
        if (iii == 0) {
          index.push(indexTemp)
          iii++;
        } else {
          if (index.indexOf(indexTemp) == -1) {
            index.push(indexTemp)
            iii++;
          }
        }
      }

      console.log(index)
      let temp1 = tempArray[index[0]]
      let temp2 = tempArray[index[1]]
      let temp3 = tempArray[index[2]]
      let temp4 = tempArray[index[3]]

      let t = []
      if (temp1.length > 0) {
        t.push(temp1)
      }
      if (temp2.length > 0) {
        t.push(temp2)
      }
      if (temp3.length > 0) {
        t.push(temp3)
      }
      if (temp4.length > 0) {
        t.push(temp4)
      }
      return t
    },
    getQuestionChoices(obj) {
      let questions = []
      for (let i in obj.sessionChoices) {
        if (obj.sessionChoices[i] && obj.sessionChoices[i]['q'] && obj.sessionChoices[i]['q'].length > 0) {
          questions.push(obj.sessionChoices[i]['q'])
        }
      }

      return questions
    },
    getAnswerChoices(obj) {
      let questions = []
      for (let i in obj.sessionChoices) {
        if (obj.sessionChoices[i] && obj.sessionChoices[i]['a'] && obj.sessionChoices[i]['a'].length > 0) {
          questions.push(obj.sessionChoices[i]['a'])
        }
      }

      return questions
    },
    getAnswer(obj) {
      if (obj.sessionAns && obj.sessionAns.length > 0) {
        this.answerInfo = obj
        this.answerInfo["status"] = obj.sessionAns == obj.sessionAnswer
        this.dialogDisplay = true
      } else {
        alert("لطفا پاسخ خود را وارد نمایید.")
      }
    },
    nextContentOfTable() {
      this.displayStep += 1
    },
    previousContentOfTable() {
      this.displayStep -= 1
    },
    setDisplayStep(index) {
      this.displayStep = index
    }
  },
  // mounted() {
  //   let options = {
  //     autoplay: true,
  //     controls: true,
  //     preload: true,
  //     fluid: true,
  //     sources: [
  //       {
  //         src: "https://websiran.com/video/hafezli.websiran.com/1712040953845_bd11539644eb57cd0f410c5d65f837aa48311754-240p.mp4",
  //         type: "video/mp4",
  //       },
  //     ],
  //   };
  //   this.player = videojs("videoJsPlayer", options, () => {
  //     this.player.log("video player ready", this);
  //   });
  // },
  // beforeDestroy() {
  //   if (this.player) {
  //     this.player.dispose();
  //   }
  // },
};
</script>

<style>
p > img {
  width: 100%;
}
</style>