<template>
  <!--begin::Aside-->
  <div
    v-if="contents.status"
    id="kt_aside"
    class="aside aside-dark"
    data-kt-drawer="true"
    data-kt-drawer-name="aside"
    data-kt-drawer-activate="{default: true, lg: false}"
    data-kt-drawer-overlay="true"
    data-kt-drawer-width="{default:'200px', '300px': '250px'}"
    data-kt-drawer-direction="start"
    data-kt-drawer-toggle="#kt_aside_mobile_toggle"
  >
    <!--begin::Brand-->
    <div class="aside-logo flex-column-auto" id="kt_aside_logo">
      <!--begin::Logo-->
      <a :href="`${backendHost}?locale=${routeLocale}`">
        <img
          alt="Logo"
          :src="`${backendHost}/uploads/${$store.state.uploadsPath}/${$store.state.logos.mainLogoLight}`"
          class="h-45px logo"
        />
      </a>
      <!--end::Logo-->
      <!--begin::Aside toggler-->
      <div
        id="kt_aside_toggle"
        class="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle"
        :class="sidebarToggleActive"
        data-kt-toggle="true"
        data-kt-toggle-state="active"
        data-kt-toggle-target="body"
        data-kt-toggle-name="aside-minimize"
        @click="toggleSidebar"
      >
        <!--begin::Svg Icon | path: icons/duotune/arrows/arr079.svg-->
        <span class="svg-icon svg-icon-4 rotate-180">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19.38"
            height="16.798"
            viewBox="0 0 19.38 16.798"
          >
            <g id="Arrow" transform="translate(-1631.35 -36.192)">
              <path
                id="Icon_ionic-ios-arrow-forward"
                data-name="Icon ionic-ios-arrow-forward"
                d="M17.436,13.942l-5.864-5.86a1.1,1.1,0,0,1,0-1.564,1.117,1.117,0,0,1,1.569,0l6.644,6.64a1.105,1.105,0,0,1,.032,1.527l-6.672,6.686a1.108,1.108,0,0,1-1.569-1.564Z"
                transform="translate(1620.104 30.646)"
                fill="#fff"
                opacity="0.5"
              />
              <path
                id="Icon_ionic-ios-arrow-forward-2"
                data-name="Icon ionic-ios-arrow-forward"
                d="M17.956,14.592,11.6,8.241a1.2,1.2,0,0,1,0-1.7,1.211,1.211,0,0,1,1.7,0l7.2,7.2a1.2,1.2,0,0,1,.035,1.655L13.3,22.644a1.2,1.2,0,1,1-1.7-1.7Z"
                transform="translate(1629.879 29.996)"
                fill="#fff"
              />
            </g>
          </svg>
        </span>
        <!--end::Svg Icon-->
      </div>
      <!--end::Aside toggler-->
    </div>
    <!--end::Brand-->
    <!--begin::Aside menu-->
    <div class="aside-menu flex-column-fluid">
      <!--begin::Aside Menu-->
      <div
        class="hover-scroll-overlay-y my-5 my-lg-5"
        id="kt_aside_menu_wrapper"
        data-kt-scroll="true"
        data-kt-scroll-activate="{default: false, lg: true}"
        data-kt-scroll-height="auto"
        data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
        data-kt-scroll-wrappers="#kt_aside_menu"
        data-kt-scroll-offset="0"
        style="height: 80vh"
      >
        <!--begin::Menu-->
        <div class="menu menu-column" id="#kt_aside_menu" data-kt-menu="true">
          <div
            v-for="(route, index) in $store.state.sidebar"
            :key="index"
            class="menu-item"
          >
            <router-link
              v-if="route.routeType == 'service' ? route.routeActive : true"
              class="menu-link"
              :to="`/${routeLocale}${route.routePath}`"
              active-class="active"
              exact-active-class="exact-active"
            >
              <span class="menu-icon">
                <!--begin::Svg Icon | path: icons/duotune/general/gen025.svg-->
                <span class="svg-icon svg-icon-2">
                  <i class="bi fs-2" :class="route.routeIcon"></i>
                </span>
                <!--end::Svg Icon-->
              </span>
              <span class="menu-title">{{ route.routeLabel[routeLocale] }}</span>
            </router-link>
          </div>
        </div>
        <!--end::Menu-->
      </div>
      <!--end::Aside Menu-->
    </div>
    <!--end::Aside menu-->
  </div>
  <!--end::Aside-->
</template>

<script>
import serverConfigs from "../../../configs/server.json";
import dataService from "../../../services/data";

export default {
  computed: {
    backendHost() {
      return serverConfigs["serverDomain"]["host"];
    },
    routeLocale() {
      return this.$route.params.locale;
    },
  },
  data() {
    return {
      loaderStatus: [false],
      sidebarMinimize: false,
      sidebarToggleActive: "",
      contents: {
        data: {},
        status: false,
      },
    };
  },
  methods: {
    toggleSidebar() {
      this.sidebarMinimize = !this.sidebarMinimize;

      if (this.sidebarMinimize) {
        document
          .getElementById("kt_body")
          .setAttribute("data-kt-aside-minimize", "on");
        this.sidebarToggleActive = "active";
        this.animating();
      } else {
        document
          .getElementById("kt_body")
          .setAttribute("data-kt-aside-minimize", "off");
        this.sidebarToggleActive = "";
        this.animating();
      }
    },
    loaderDone() {
      if (this.loaderStatus[0]) {
        this.$store.commit("setLoader", false);
      }
    },
    animating() {
      let Sidebar = document.getElementById("kt_aside");
      Sidebar.classList.add("animating");
      setTimeout(() => Sidebar.classList.remove("animating"), 300);
    },
    readSidebar() {
      let data = {
        endPoint: `sidebar`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        data: {},
        uuid: this.$store.state.projectUUID,
      };

      dataService
        .get(data)
        .then((res) => {
          let tempRoutes = {};
          for (let route in res.data.data) {
            tempRoutes[`${res.data.data[route].routeName}`] =
              res.data.data[route];
          }
          
          this.$store.commit("setSidebar", tempRoutes);
          this.contents.status = true;
          
        })
        .catch((error) => {
          
          this.$store.commit("setLoader", false);
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[0] = true;
          this.loaderDone();
        });
    },
  },
  mounted() {
    this.readSidebar();
  },
};
</script>
