<template>
  <div v-if="contents.status">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl my-5">
      <!--begin::Hero card-->
      <div class="card mb-12 shadow-sm">
        <!--begin::Hero body-->
        <div class="card-body flex-column p-5 mb-5">
          <!--begin::Hero content-->
          <div
            class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center h-lg-300px p-5 p-lg-15 overlay-wrapper gap-10"
          >
            <!--begin::Wrapper-->
            <div
              class="d-flex flex-column align-items-start justift-content-center flex-equal me-5 order-1 order-md-0"
            >
              <!--begin::Title-->
              <h1 class="fw-bolder fs-4 fs-lg-1 text-gray-800 mb-5 mb-lg-5">
                {{ contents.data.page.locale.title }}
              </h1>
              <!--end::Title-->
              <!--begin::Input group-->
              <div class="position-relative w-100 mb-5 mb-lg-10">
                <span class="text-gray-600 fs-6">{{
                  contents.data.page.locale.subTitle
                }}</span>
              </div>
              <!--end::Input group-->
              <!--begin::Action-->
              <a
                href="#"
                class="btn btn-primary fw-bolder fs-5"
                @click="setModalConfigs('create')"
                ><i
                  class="me-2 fs-4"
                  :class="`${contents.data.page.setting.buttonIcon}`"
                ></i
                >{{ contents.data.page.locale.buttonLabel }}</a
              >
              <!--end::Action-->
            </div>
            <!--end::Wrapper-->
            <!--begin::Wrapper-->
            <div
              class="flex-equal d-flex align-self-center justify-content-end align-items-center order-0 order-md-1"
            >
              <!--begin::Illustration-->
              <img
                :src="`${backendHost}/images/themes/${contentTheme}/${
                  $store.state.sidebar[contents.name].routeImage
                }`"
                alt=""
                class="mw-100 min-h-100px mh-md-125px mh-lg-225px"
              />
              <!--end::Illustration-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Hero content-->
        </div>
        <!--end::Hero body-->
      </div>
      <!--end::Hero card-->
      <div v-if="dataIsEmpty" class="p-20">
        <div class="d-flex flex-center">
          <img
            class="col-md-3"
            :src="`/assets/images/svg/no_data.svg`"
            alt=""
          />
        </div>
      </div>
      <!--begin::Row-->
      <div v-else class="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-5 g-xl-9">
        <!--begin::Col-->
        <div v-for="(role, index) in roles" :key="index" class="col-md-6">
          <!--begin::Card-->
          <div class="card card-flush h-md-100">
            <!--begin::Card header-->
            <div class="card-header">
              <!--begin::Card title-->
              <div class="card-title">
                <h2>{{ role.roleName }}</h2>
              </div>
              <!--end::Card title-->
            </div>
            <!--end::Card header-->
            <!--begin::Card body-->
            <div class="card-body pt-1">
              <!--begin::Users-->
              <!-- <div class="fw-bolder text-gray-600 mb-5">
                {{ contents.data.page.card.subTitle }} 5 نفر
              </div> -->
              <!--end::Users-->
              <!--begin::Permissions-->
              <div class="d-flex flex-column text-gray-600">
                <div class="d-flex align-items-center py-2">
                  {{ role.roleDescription }}
                </div>
                <!-- <div
                  v-for="(permission, route) in JSON.parse(
                    role.rolePermissions
                  )"
                  :key="permission"
                  class="d-flex align-items-center py-2"
                >
                  <span class="bullet bg-primary me-3"></span>
                  {{ permission }} {{ route }}
                </div> -->
              </div>
              <!--end::Permissions-->
            </div>
            <!--end::Card body-->
            <!--begin::Card footer-->
            <div class="card-footer flex-wrap pt-0">
              <a
                :href="`/role-view?id=${role.id}`"
                class="btn btn-sm btn-light btn-active-primary my-1 me-2"
                >{{ contents.data.page.card.buttons[0].label }}</a
              >
              <button
                type="button"
                class="btn btn-sm btn-light btn-active-light-primary my-1 me-2"
                @click="setModalConfigs('update', index)"
              >
                {{ contents.data.page.card.buttons[1].label }}
              </button>
              <button
                class="btn btn-sm btn-light btn-active-light-danger my-1"
                @click="setModalConfigs('delete', index)"
              >
                {{ contents.data.page.card.buttons[2].label }}
              </button>
            </div>
            <!--end::Card footer-->
          </div>
          <!--end::Card-->
        </div>
        <div v-if="totalPages > 1" class="w-100 text-center mt-5">
          <pagination
            :pages="totalPages"
            v-model="currentPage"
            @update:modelValue="setCurrentPage"
          ></pagination>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->
      <!--begin::Modals-->
      <create-update
        v-if="modalDisplay"
        :contents="contents.data.modal"
        :modalInfo="roleInfo"
        :modalType="'uploadselect'"
        :firstErrorName="firstErrorName"
        @close-modal="setModalConfigs"
      ></create-update>
      <dialog-box
        v-if="dialogDisplay"
        :contents="contents.data.modal"
        :modalInfo="deleteInfo"
        @close-modal="setModalConfigs"
      >
      </dialog-box>
      <!--end::Modals-->
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
import dataService from "../../../services/data";
import serverConfigs from "../../../configs/server.json";

// Tools
import Pagination from "../../tools/Pagination.vue";

// Modals
import CreateUpdate from "../../modals/CreateUpdate.vue";
import DialogBox from "../../modals/DialogBox.vue";

export default {
  components: {
    Pagination,
    CreateUpdate,
    DialogBox,
  },
  setup() {},
  data() {
    return {
      totalPages: 0,
      currentPage: 1,
      roleInfo: {},
      deleteInfo: {},
      modalDisplay: false,
      dialogDisplay: false,
      dataIsEmpty: true,
      roles: [],
      firstErrorName: "",
      contents: {
        name: "",
        data: {},
        status: false,
      },
      loaderStatus: [false, false],
    };
  },
  computed: {
    contentTheme() {
      return this.$store.state.themeFile.replace(".css", "");
    },
    backendHost() {
      return serverConfigs["serverDomain"]["host"];
    },
    routeLocale() {
      return this.$route.params.locale;
    },
    bodyNode() {
      return document.getElementById("kt_body");
    },
  },
  methods: {
    setModalConfigs(opt, index) {
      this.$store.commit("setLoader", true);

      switch (opt) {
        case "update":
          this.roleInfo = { ...this.roles[index], status: false };
          this.modalDisplay = !this.modalDisplay;
          this.$store.commit("setLoader", false);
          break;
        case "create":
          this.setupRoleInfo();
          this.modalDisplay = !this.modalDisplay;
          this.$store.commit("setLoader", false);
          break;
        case "delete":
          this.deleteInfo = {
            id: this.roles[index].id,
            name: this.roles[index].roleName,
            status: false,
            delete: true,
          };
          this.dialogDisplay = !this.dialogDisplay;
          this.$store.commit("setLoader", false);

          break;
        case "validate":
          if (index.delete) {
            this.deleteRole(index);
          } else {
            if (this.inputValidation(index)) {
              index.id ? this.updateRole(index) : this.createNewRole(index);
            } else {
              this.$store.commit("setLoader", false);
            }
          }
          break;
        default:
          this.resetRoleInfo();
          this.$store.commit("setLoader", false);

          break;
      }
    },
    setCurrentPage(page) {
      this.$store.commit("setLoader", true);
      this.readRoles(page);
    },
    loaderDone() {
      if (this.loaderStatus[0] && this.loaderStatus[1]) {
        this.$store.commit("setLoader", false);
      }
    },
    getContents() {
      let data = {
        endPoint: `contents/r/roles`,
        locale: `${this.routeLocale}`,
        data: {},
      };

      dataService
        .get(data)
        .then((res) => {
          this.loaderStatus[0] = true;

          this.contents = {
            name: res.data.data[0].contentName,
            data: res.data.data[0].contentJson,
            status: true,
          };
        })
        .catch((error) => {
          this.$store.commit("setLoader", false);
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[0] = true;
          this.loaderDone();
        });
    },
    setupRoleInfo() {
      let tempArray = {};
      for (let counter in this.contents.data.modal.fields) {
        switch (this.contents.data.modal.fields[counter].type) {
          case "text":
            this.roleInfo[this.contents.data.modal.fields[counter].name] = "";
            break;
          case "array":
            for (let item in this.contents.data.modal.fields[counter].value) {
              tempArray[
                this.contents.data.modal.fields[counter].value[item].name
              ] = [];
              for (let accessStatus in this.contents.data.modal.fields[counter]
                .value[item].items) {
                tempArray[
                  this.contents.data.modal.fields[counter].value[item].name
                ].push(
                  this.contents.data.modal.fields[counter].value[item].items[
                    accessStatus
                  ].value
                );
              }
            }
            break;
          default:
            break;
        }
      }
      this.roleInfo["status"] = true;
      this.roleInfo["rolePermissions"] = tempArray;
    },
    resetRoleInfo() {
      this.roleInfo = {};
      this.modalDisplay = false;
      this.dialogDisplay = false;
      if (!this.modalDisplay) {
        this.bodyNode.classList.remove("modal-open");
        this.bodyNode.style.overflow = "visible";
      }
      for (let index in this.contents.data.modal.fields) {
        if (this.contents.data.modal.fields[index].required) {
          this.contents.data.modal.fields[index].errorCode = 0;
        }
      }
    },
    inputValidation(obj) {
      let errorCount = 0;
      for (
        let index = 0;
        index < this.contents.data.modal.fields.length;
        index++
      ) {
        if (this.contents.data.modal.fields[index].required) {
          for (let expression in this.contents.data.modal.fields[index]
            .validation) {
            switch (
              this.contents.data.modal.fields[index].validation[expression]
                .expression
            ) {
              case "isEmpty":
                this.contents.data.modal.fields[index].errorCode =
                  !obj[this.contents.data.modal.fields[index].name] ||
                  obj[this.contents.data.modal.fields[index].name].length == 0
                    ? this.contents.data.modal.fields[index].validation[
                        expression
                      ].id
                    : 0;
                if (this.contents.data.modal.fields[index].errorCode > 0) {
                  errorCount += 1;
                  this.firstErrorName =
                    this.firstErrorName.length == 0
                      ? this.contents.data.modal.fields[index].name
                      : this.firstErrorName;
                }
                break;
              case "isSpace":
                this.contents.data.modal.fields[index].errorCode =
                  this.contents.data.modal.fields[index].validation.length >
                    1 && this.contents.data.modal.fields[index].errorCode > 0
                    ? this.contents.data.modal.fields[index].errorCode
                    : String(
                        obj[this.contents.data.modal.fields[index].name]
                      ).trim().length == 0
                    ? this.contents.data.modal.fields[index].validation[
                        expression
                      ].id
                    : 0;
                if (this.contents.data.modal.fields[index].errorCode > 0) {
                  errorCount += 1;
                  this.firstErrorName =
                    this.firstErrorName.length == 0
                      ? this.contents.data.modal.fields[index].name
                      : this.firstErrorName;
                }
                break;
              default:
                this.contents.data.modal.fields[index].errorCode = 0;
                break;
            }
          }
        }
      }
      return errorCount == 0 ? true : false;
    },
    createNewRole(obj) {
      let tempPermissions = {};
      for (let p in obj.rolePermissions) {
        tempPermissions[`${p}`] = obj.rolePermissions[p];
      }
      let data = {
        endPoint: `roles`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {
          name: obj["roleName"],
          description: obj["roleDescription"],
          permissions: tempPermissions,
        },
      };
      dataService
        .post(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.resetRoleInfo();
          this.readRoles();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
        });
    },
    readRoles(page) {
      let data = {
        endPoint: `roles`,
        pageNo: page,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          res.data.data.length > 0
            ? (this.dataIsEmpty = false)
            : (this.dataIsEmpty = true);
          this.roles = res.data.data;
          this.roles = this.roles.reverse();
          if (page == 1) {
            this.totalPages = res.data.totalRes;
          }
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[1] = true;
          this.loaderDone();
        });
    },
    updateRole(obj) {
      let tempPermissions = {};
      for (let p in obj.rolePermissions) {
        tempPermissions[`${p}`] = obj.rolePermissions[p];
      }
      let data = {
        endPoint: `roles/u/${obj.id}`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {
          name: obj["roleName"],
          description: obj["roleDescription"],
          permissions: tempPermissions,
        },
      };
      dataService
        .post(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.resetRoleInfo();
          this.readRoles();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
        });
    },
    deleteRole(obj) {
      this.$store.commit("setLoader", true);

      let data = {
        endPoint: `roles/d/${obj.id}`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.resetRoleInfo();
          this.readRoles();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
        });
    },
  },
  mounted() {
    this.$store.commit("setLoader", true);
    this.getContents();
    this.readRoles(1);
  },
  updated() {
    this.loaderDone();
  },
};
</script>
