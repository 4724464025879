<template>
  <!--begin::Container-->
  <div id="kt_content_container" class="container-xxl my-5 wizard_register">
    <div
      v-if="$store.state.projectUUID == '1debc80694784a179ce1ba51ca3f1765'"
      class="card card-bordered border-gray-400 p-0 px-md-20 py-md-10 scroll-y"
      style="border-radius: 0.5rem; height: 100%"
    >
      <div class="card-body px-md-15 mt-5">
        <!-- begin::Content -->
        <div class="h-100">
          <h1 class="p-5 text-center text-primary mb-0">سایت ساز صاران</h1>
          <p class="p-2 fs-3 text-center text-gray-700 mb-0">
            جهت ساخت سایت خود ابتدا با وارد کردن ایمیل یا شماره تلفن همراه خود،
            ساخت کاربری خود را ایجاد کرده و با استفاده از کد اعتبار سنجی ارسال
            شده حساب کاربری خود را فعال نمایید. سپس از طریق داشبورد، جهت تکمیل
            پروسه ساخت سایت خود اقدام بفرمایید. نگران پیچیدگی فرآیندها نباشد؛
            زیرا برای هر مرحله آن آموزشی قرار داده‌ایم و در هر جایی که مشکل
            داشته باشید همکاران ما در واحد پشتیبانی پاسخگو سوالات شما هستند.
          </p>
          <form
            class="form text-center text-md-start d-flex flex-column justify-content-center align-items-center mt-20"
          >
            <div class="col-10 my-10 my-md-5">
              <!--begin::Label-->
              <label class="form-label"> دامنه حریداری شده </label>
              <!--end::Label-->
              <!--begin::Input-->
              <div class="d-flex">
                <div class="position-relative flex-grow-1 me-3">
                  <input
                    dir="ltr"
                    :disabled="verifyTime ? true : false"
                    type="text"
                    class="form-control form-control-solid"
                    placeholder="example.websiran.com or example.com"
                    v-model="saranit.domain"
                  />
                </div>
              </div>
            </div>
            <div class="col-10 my-10 my-md-5">
              <!--begin::Label-->
              <label class="form-label"> ایمیل یا شماره تلفن همراه </label>
              <!--end::Label-->
              <!--begin::Input-->
              <div class="d-flex">
                <div class="position-relative flex-grow-1 me-3">
                  <input
                    :disabled="verifyTime ? true : false"
                    type="text"
                    class="form-control form-control-solid"
                    placeholder="ایمیل یا شماره تلفن همراه خود را وارد نمایید"
                    v-model="saranit.userName"
                  />
                </div>
              </div>
            </div>
            <div v-if="verifyTime" class="col-10 my-10 my-md-5">
              <!--begin::Label-->
              <label class="form-label"> کد اعتبار سنجی </label>
              <!--end::Label-->
              <!--begin::Input-->
              <div class="d-flex">
                <div class="position-relative flex-grow-1 me-3">
                  <input
                    type="text"
                    class="form-control form-control-solid"
                    placeholder="کد اعتبار سنجی ارسال شده را وارد نمایید"
                    v-model="saranit.verificationCode"
                  />
                </div>
              </div>
            </div>
            <div
              class="col-10 d-flex align-items-center justify-content-center"
            >
              <div class="col-auto me-3">
                <label
                  v-if="verifyTime"
                  class="form-label text-muted __bottom__label mb-0"
                  >زمان باقی مانده تا ارسال مجدد کد</label
                >
                <label
                  v-else
                  class="form-label text-muted __bottom__label mb-0"
                >
                  <a
                    href="#"
                    class="btn btn-sm btn-primary text-decoration-none"
                    @click="resendCode()"
                    >ارسال مجدد کد</a
                  ></label
                >
                <!-- <span class="ms-2 __text__primary fs-5"
                  >{{ contents.data.fields[3].title }} {{ countDown }}</span
                > -->
              </div>
              <div v-if="verifyTime" class="progress col-6 fs-4 h-auto py-2">
                <div
                  class="progress-bar progress-bar-striped progress-bar-animated bg-primary rounded"
                  :style="`width: ${
                    (countDown * 100) / $store.state.resendTime
                  }%`"
                  role="progressbar"
                  aria-label="Example with label"
                  :aria-valuenow="countDown"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {{ countDown }} ثانیه
                </div>
              </div>
            </div>
          </form>
        </div>
        <!-- end::Content -->
      </div>
      <div class="card-footer py-2" v-if="displayResgisterBtn">
        <!-- begin::Actions -->
        <div class="d-flex flex-center w-100">
          <a
            class="btn btn-primary"
            data-kt-stepper-action="submit"
            @click="registerAtWebsIRAN()"
          >
            <span class="indicator-label fs-3">ثبت نام</span>
          </a>
        </div>
        <!-- end::Actions -->
      </div>
    </div>
    <div
      v-else-if="$store.state.projectUUID == '926fe3cfe4e04a19a84444363a5b431b'"
      class="card card-bordered border-gray-400 p-0 px-md-20 py-md-10 scroll-y"
      style="border-radius: 0.5rem; height: 100%"
    >
      <div class="card-body px-md-15 mt-5">
        <!-- begin::Content -->
        <div class="h-100">
          <h1 class="p-5 text-center text-primary mb-0">ربات ساز «اعلان رسان» تلگرامی ثمین</h1>
          <p class="p-2 fs-3 text-center text-gray-700 mb-0">
            ربات فرسنده اعلان خودت را داشته باش. به راحتی و فقط با داشتن توکن ربات که از BotFather تلگرام دریافت کردی و زدن دکمه شروع، رباتت خود را ایجاد کن تا از این به بعد با ربات خودت برای مشتریانت اعلان بفرستی.
          </p>
          <form
            class="form text-center text-md-start d-flex flex-column justify-content-center align-items-center mt-20"
          >
            <div class="col-10 my-10 my-md-5">
              <!--begin::Label-->
              <label class="form-label">توکن دریافتی از BotFather</label>
              <!--end::Label-->
              <!--begin::Input-->
              <div class="d-flex">
                <div class="position-relative flex-grow-1 me-3">
                  <input
                    dir="ltr"
                    :disabled="verifyTime ? true : false"
                    type="text"
                    class="form-control form-control-solid"
                    placeholder="example.websiran.com or example.com"
                    v-model="notiferbot.botToken"
                  />
                </div>
              </div>
            </div>
            <div class="col-10 my-10 my-md-5">
              <!--begin::Label-->
              <label class="form-label"> ایمیل یا شماره تلفن همراه </label>
              <!--end::Label-->
              <!--begin::Input-->
              <div class="d-flex">
                <div class="position-relative flex-grow-1 me-3">
                  <input
                    dir="ltr"
                    :disabled="verifyTime ? true : false"
                    type="text"
                    class="form-control form-control-solid"
                    placeholder="ایمیل یا شماره تلفن همراه خود را وارد نمایید"
                    v-model="notiferbot.userName"
                  />
                </div>
              </div>
            </div>
            <div v-if="verifyTime" class="col-10 my-10 my-md-5">
              <!--begin::Label-->
              <label class="form-label"> کد اعتبار سنجی </label>
              <!--end::Label-->
              <!--begin::Input-->
              <div class="d-flex">
                <div class="position-relative flex-grow-1 me-3">
                  <input
                    dir="ltr"
                    type="text"
                    class="form-control form-control-solid"
                    placeholder="کد اعتبار سنجی ارسال شده را وارد نمایید"
                    v-model="notiferbot.verificationCode"
                  />
                </div>
              </div>
            </div>
            <div
              class="col-10 d-flex align-items-center justify-content-center"
            >
              <div class="col-auto me-3">
                <label
                  v-if="verifyTime"
                  class="form-label text-muted __bottom__label mb-0"
                  >زمان باقی مانده تا ارسال مجدد کد</label
                >
                <label
                  v-else
                  class="form-label text-muted __bottom__label mb-0"
                >
                  <a
                    href="#"
                    class="btn btn-sm btn-primary text-decoration-none"
                    @click="resendCodeNotiferBot()"
                    >ارسال کد</a
                  ></label
                >
                <!-- <span class="ms-2 __text__primary fs-5"
                  >{{ contents.data.fields[3].title }} {{ countDown }}</span
                > -->
              </div>
              <div v-if="verifyTime" class="progress col-6 fs-4 h-auto py-2">
                <div
                  class="progress-bar progress-bar-striped progress-bar-animated bg-primary rounded"
                  :style="`width: ${
                    (countDown * 100) / $store.state.resendTime
                  }%`"
                  role="progressbar"
                  aria-label="Example with label"
                  :aria-valuenow="countDown"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {{ countDown }} ثانیه
                </div>
              </div>
            </div>
          </form>
        </div>
        <!-- end::Content -->
      </div>
      <div class="card-footer py-2" v-if="displayResgisterBtn">
        <!-- begin::Actions -->
        <div class="d-flex flex-center w-100">
          <a
            class="btn btn-primary"
            data-kt-stepper-action="submit"
            @click="userVerificationNotiferBot()"
          >
            <span class="indicator-label fs-3">ثبت نام</span>
          </a>
        </div>
        <!-- end::Actions -->
      </div>
    </div>
    <div
      v-else-if="$store.state.uploadsPath == 'hafezli.websiran.com'"
      class="card card-bordered border-gray-400 p-0 px-md-20 py-md-10 scroll-y"
      style="border-radius: 0.5rem; height: 100%"
    >
      <div class="card-body px-md-15 mt-5">
        <!-- begin::Content -->
        <div class="h-100">
          <h1 class="p-5 text-center text-primary mb-0">Welcome to EliteStars language e-learnign platform</h1>
          <p class="p-2 fs-3 text-center text-gray-700 mb-0">
            Do you want to access to out package? Please fill the registration form
          </p>
          <form
            class="form text-center text-md-start d-flex flex-column justify-content-center align-items-center mt-20"
          >
            <div class="col-10 my-10 my-md-5">
              <!--begin::Label-->
              <label class="form-label"> ایمیل یا شماره تلفن همراه </label>
              <!--end::Label-->
              <!--begin::Input-->
              <div class="d-flex">
                <div class="position-relative flex-grow-1 me-3">
                  <input
                    :disabled="verifyTime ? true : false"
                    type="text"
                    class="form-control form-control-solid"
                    :class="hafezli.userNameErrorClass"
                    placeholder="ایمیل یا شماره تلفن همراه خود را وارد نمایید"
                    v-model="hafezli.userName"
                  />
                </div>
              </div>
            </div>
            <div v-if="verifyTime" class="col-10 my-10 my-md-5">
              <!--begin::Label-->
              <label class="form-label"> کد اعتبار سنجی </label>
              <!--end::Label-->
              <!--begin::Input-->
              <div class="d-flex">
                <div class="position-relative flex-grow-1 me-3">
                  <input
                    type="text"
                    class="form-control form-control-solid"
                    placeholder="کد اعتبار سنجی ارسال شده را وارد نمایید"
                    v-model="hafezli.verificationCode"
                  />
                </div>
              </div>
            </div>
            <div
              class="col-10 d-flex align-items-center justify-content-center"
            >
              <div class="col-auto me-3">
                <label
                  v-if="verifyTime"
                  class="form-label text-muted __bottom__label mb-0"
                  >زمان باقی مانده تا ارسال مجدد کد</label
                >
                <label
                  v-else
                  class="form-label text-muted __bottom__label mb-0"
                >
                  <a
                    href="#"
                    class="btn btn-sm btn-primary text-decoration-none"
                    @click="resendCodeHafezLi()"
                    >ارسال کد</a
                  ></label
                >
                <!-- <span class="ms-2 __text__primary fs-5"
                  >{{ contents.data.fields[3].title }} {{ countDown }}</span
                > -->
              </div>
              <div v-if="verifyTime" class="progress col-6 fs-4 h-auto py-2">
                <div
                  class="progress-bar progress-bar-striped progress-bar-animated bg-primary rounded"
                  :style="`width: ${
                    (countDown * 100) / $store.state.resendTime
                  }%`"
                  role="progressbar"
                  aria-label="Example with label"
                  :aria-valuenow="countDown"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {{ countDown }} ثانیه
                </div>
              </div>
            </div>
          </form>
        </div>
        <!-- end::Content -->
      </div>
      <div class="card-footer py-2" v-if="displayResgisterBtn">
        <!-- begin::Actions -->
        <div class="d-flex flex-center w-100">
          <a
            class="btn btn-primary"
            data-kt-stepper-action="submit"
            @click="userVerificationHafezLi()"
          >
            <span class="indicator-label fs-3">ثبت نام</span>
          </a>
        </div>
        <!-- end::Actions -->
      </div>
    </div>
    <div
      v-else-if="$store.state.uploadsPath == 'kardoapples.com'"
      class="card card-bordered border-gray-400 p-0 px-md-20 py-md-10 scroll-y"
      style="border-radius: 0.5rem; height: 85vh"
    >
      <div class="card-body px-md-15 mt-5">
        <!-- begin::Content -->
        <div class="h-100 d-flex flex-center flex-column">
          <form
            @keydown.enter="userLogin"
            class="login__form col-12 col-md-8 col-lg-7"
          >
            <div v-if="kardoStep == 0">
              <h1 class="p-5 text-center text-primary mb-0">
                گروه بازرگانی کاردو
              </h1>
              <p class="p-2 fs-3 text-center text-gray-700 mb-0">
                به مجموعه بازرگانی کاردو خوش آمدید. استفاده از وب‌اپلیکیشن کاردو
                به مدت 14 روز رایگان بوده و پس از آن در صورت پرداخت حق اشتراک
                دوره‌ای قابل استفاده خواهد بود. لطفا جهت استفاده از وب‌اپلیکیشن
                کاردو، با وارد کردن اطلاعات درخواستی ثبت نام نمایید. پس از بررسی
                مدارک و تایید اطلاعات توسط کارشناسان کاردو پیامک استفاده از
                وب‌اپلیکیشن کاردو برایتان ارسال خواهد شد. این پروسه ممکن است 24
                تا 72 ساعت زمانبر باشد. از صبر و شکیبایی شما سپاسگزاریم.
              </p>
              <div class="form__input mb-4 mt-4">
                <label class="mb-1" for=""
                  >شماره تماس <span class="text-danger">*</span></label
                >
                <div
                  class="input__container d-flex justify-content-between"
                  :class="kardo.phoneNumberErrorClass"
                >
                  <i class="bi bi-phone px-5"></i>
                  <input
                    v-model="kardo.phoneNumber"
                    class="flex-grow-1 fs-5 me-3"
                    type="tel"
                    placeholder="لطفا شماره تماس خود را وارد نمایید"
                  />
                </div>
                <div
                  :class="
                    kardo.phoneNumberErrorClass.length > 0
                      ? `${kardo.phoneNumberErrorClass} d-block`
                      : 'd-none'
                  "
                ></div>
              </div>
              <div class="pt-5 mb-4 d-flex justify-content-center flex-column">
                <a
                  ref="testFocus"
                  class="link-primary link px-15 m-1 mb-10 w-100"
                  href="/fa/login?pid=57174120b8ad4b6ab36b8b362e3fb11d"
                >
                  ورود به حساب کاربری
                </a>
                <button
                  ref="testFocus"
                  class="btn btn-primary px-15 m-1 w-100"
                  type="button"
                  @click="userSignUp"
                >
                  ارسال کد
                </button>
              </div>
            </div>
            <div v-if="kardoStep == 1">
              <h3 class="fw-boldest fs-2x mb-3 pb-4">تایید حساب کاربری</h3>
              <div class="form__input mb-4">
                <label class="mb-1" for=""
                  >کد اعتبار سنجی <span class="text-danger">*</span></label
                >
                <div
                  class="input__container d-flex justify-content-between"
                  :class="kardo.vcodeErrorClass"
                >
                  <i class="bi bi-123 px-5"></i>
                  <input
                    v-model="kardo.vcode"
                    class="text-center flex-grow-1 fs-5 me-3"
                    type="number"
                    placeholder="لطفا کد ارسال شده را وارد نمایید"
                  />
                </div>
                <div
                  :class="
                    kardo.vcodeErrorClass.length > 0
                      ? `${kardo.vcodeErrorClass} d-block`
                      : 'd-none'
                  "
                ></div>
              </div>
              <div
                class="form__input pt-5 mb-4 d-flex justify-content-center flex-column flex-md-row"
              >
                <button
                  ref="testFocus"
                  class="col-12 col-md-6 btn btn-primary px-15 m-1"
                  type="button"
                  @click="userVerification"
                >
                  تایید کد
                </button>
                <button
                  class="col-12 col-md-6 btn btn-secondary px-15 m-1"
                  type="button"
                  @click="previousStep"
                >
                  بازگشت
                </button>
              </div>
            </div>
            <div v-if="kardoStep == 2">
              <h1 class="fw-boldest fs-3x mb-3 pb-4">احراز هویت</h1>
              <div class="mb-10">
                <h3 class="mb-3" for="">
                  شخص حقیقی / حقوقی <span class="text-danger">*</span>
                </h3>
                <div
                  class="d-flex justify-content-between"
                  :class="kardo.userKindErrorClass"
                >
                  <label class="" for="userKind0">حقیقی</label>
                  <input
                    id="userKind0"
                    name="userKind"
                    :checked="kardo['userKind'] == 'person'"
                    @click="setRadioGroup('userKind', 'person')"
                    class="text-center flex-grow-1 fs-5 me-3"
                    type="radio"
                  />
                  <label class="" for="userKind1">حقوقی</label>
                  <input
                    id="userKind1"
                    name="userKind"
                    :checked="kardo['userKind'] == 'company'"
                    @click="setRadioGroup('userKind', 'company')"
                    class="text-center flex-grow-1 fs-5 me-3"
                    type="radio"
                  />
                </div>
                <div
                  :class="
                    kardo.vcodeErrorClass.length > 0
                      ? `${kardo.vcodeErrorClass} d-block`
                      : 'd-none'
                  "
                ></div>
              </div>
              <div class="form__input mb-10">
                <h3 class="mb-3" for="">
                  نام و نام خانوادگی / نام شرکت
                  <span class="text-danger">*</span>
                </h3>
                <div
                  class="input__container d-flex justify-content-between"
                  :class="kardo.fullNameErrorClass"
                >
                  <i class="bi bi-person px-5"></i>
                  <input
                    v-model="kardo.fullName"
                    class="text-center flex-grow-1 fs-5 me-3"
                    type="text"
                    placeholder=""
                  />
                </div>
                <div
                  :class="
                    kardo.vcodeErrorClass.length > 0
                      ? `${kardo.vcodeErrorClass} d-block`
                      : 'd-none'
                  "
                ></div>
              </div>
              <div class="form__input mb-10">
                <h3 class="mb-3" for="">
                  کدملی / شماره ثبت <span class="text-danger">*</span>
                </h3>
                <div
                  class="input__container d-flex justify-content-between"
                  :class="kardo.idNumberErrorClass"
                >
                  <i class="bi bi-card-text px-5"></i>
                  <input
                    v-model="kardo.idNumber"
                    class="text-center flex-grow-1 fs-5 me-3"
                    type="text"
                    placeholder=""
                  />
                </div>
              </div>
              <div class="form__input mb-10">
                <h3 class="mb-3" for="">
                  آدرس <span class="text-danger">*</span>
                </h3>
                <div class="d-flex flex-column gap-3">
                  <div
                    class="d-flex flex-column flex-xl-row justify-content-between"
                  >
                    <div
                      class="col-12 col-xl-5 input__container mb-3 mb-xl-0 d-flex justify-content-between border-0"
                    >
                      <i class="bi bi-pin px-5"></i>
                      <!-- <input v-model="kardo.state" class="text-center flex-grow-1 fs-5 me-3" type="text" placeholder="استان" /> -->
                      <select
                        class="w-100 input__container"
                        v-model="kardo.state"
                        :class="kardo.stateErrorClass"
                      >
                        <option value="">استان</option>
                        <option
                          v-for="state in states"
                          :key="state.name"
                          :value="state.name"
                        >
                          {{ state.name }}
                        </option>
                      </select>
                    </div>
                    <div
                      class="col-12 col-xl-5 input__container d-flex justify-content-between border-0"
                      :class="kardo.cityErrorClass"
                    >
                      <i class="bi bi-pin px-5"></i>
                      <select
                        v-if="kardo.state.length > 0"
                        class="w-100 input__container"
                        v-model="kardo.city"
                        :class="kardo.cityErrorClass"
                      >
                        <option value="">شهرستان</option>
                        <option
                          v-for="city in states[stateIndex].cities"
                          :key="city"
                          :value="city.name"
                        >
                          {{ city.name }}
                        </option>
                      </select>
                      <input
                        v-else
                        :disabled="true"
                        v-model="kardo.city"
                        class="text-center flex-grow-1 fs-5 me-3"
                        type="text"
                        placeholder="شهرستان"
                      />
                    </div>
                  </div>
                  <div
                    class="col-12 input__container d-flex justify-content-between"
                    :class="kardo.addressErrorClass"
                  >
                    <i class="bi bi-pin px-5"></i>
                    <input
                      v-model="kardo.address"
                      class="text-center flex-grow-1 fs-5 me-3"
                      type="text"
                      placeholder="خیابان، کوچه، پلاک"
                    />
                  </div>
                </div>
              </div>
              <div class="mb-10">
                <h3 class="mb-3" for="">
                  نوع فعالیت <span class="text-danger">*</span>
                </h3>
                <div
                  class="d-flex justify-content-between"
                  :class="kardo.typeErrorClass"
                >
                  <label class="" for="userTypeSaler">فروشنده</label>
                  <input
                    id="userTypeSaler"
                    name="userType"
                    :checked="kardo['type'] == 'saler'"
                    @click="setRadioGroup('type', 'saler')"
                    class="text-center flex-grow-1 fs-5 me-3"
                    type="radio"
                  />
                  <label class="" for="userTypeBuyer">خریدار</label>
                  <input
                    id="userTypeBuyer"
                    name="userType"
                    :checked="kardo['type'] == 'buyer'"
                    @click="setRadioGroup('type', 'buyer')"
                    class="text-center flex-grow-1 fs-5 me-3"
                    type="radio"
                  />
                </div>
              </div>
              <div
                class="pt-5 mb-4 d-flex justify-content-center flex-column flex-md-row"
              >
                <button
                  ref="testFocus"
                  class="col-12 col-md-6 btn btn-primary px-15 m-1"
                  type="button"
                  @click="nextStep"
                >
                  ثبت اطلاعات
                </button>
                <button
                  class="col-12 col-md-6 btn btn-secondary px-15 m-1"
                  type="button"
                  @click="previousStep"
                >
                  بازگشت
                </button>
              </div>
            </div>
            <div v-if="kardoStep == 3">
              <h3 class="fw-boldest fs-2x mb-3 pb-4">
                تعهدنامه <span class="text-danger">*</span>
              </h3>
              <p>
                لطفا متن زیر را بر روی یک کاغذ سفید بدون خط نوشته و مشابه عکس
                مقابل خود قرار داده و عکس گرفته و آن را بارگذاری بفرمایید:
              </p>
              <div
                class="d-flex flex-center flex-column border border-dashed border-1 border-primary rounded p-5 m-5"
              >
                <p class="text-center fw-bold fs-2">
                  این عکس جهت احراز هویت اینجانب...............در کاردو میباشدو
                  متعهد می شوم حساب کاربری خود را به افراد غیره اجاره ندهم.
                </p>
                <div class="d-flex flex-wrap gap-3">
                  <a class="d-block overlay cursor-pointer" @click="softBox(`/assets/images/kardoAccept.jpeg`)">
                    <div
                      class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-100px w-100px bg-secondary"
                      :style="`background-image: url(/assets/images/kardoAccept.jpeg);`"
                    ></div>
                    <!--begin::Action-->
                    <div
                      class="overlay-layer card-rounded bg-dark bg-opacity-25"
                    >
                      <i class="bi bi-eye-fill fs-2x text-white"></i>
                    </div>
                    <!--end::Action-->
                  </a>
                </div>
                <!-- <img class="w-200px" src="/assets/images/kardoAccept.jpeg" alt="acceptPhoto"> -->
              </div>
              <!--begin::Dropzone-->
              <div
                class="dropzone dropzone-queue mb-2 bg-light-primary border border-dashed border-1 border-primary"
                :class="kardo.acceptPhotoErrorClass"
                id="kt_dropzonejs_example_0"
              >
                <!--begin::Controls-->
                <div class="dropzone-panel mb-lg-0 mb-2 col-12">
                  <div class="dropzone-select btn w-100">
                    <!--begin::Icon-->
                    <i
                      class="bi bi-file-earmark-arrow-up text-primary fs-4x me-2 mb-3"
                    ></i>
                    <div class="text-muted">برای بارگذاری فایل ضربه بزنید</div>
                    <!--begin::Hint-->
                    <span class="form-text text-muted fs-8"
                      >حداکثر تعداد فایل مجاز: 1 عدد</span
                    >
                    <!--end::Hint-->
                    <!--end::Icon-->
                  </div>
                  <a class="dropzone-remove-all btn btn-sm btn-light-primary"
                    >حذف همه</a
                  >
                </div>
                <!--end::Controls-->

                <!--begin::Items-->
                <div
                  class="dropzone-items wm-200px d-flex flex-center flex-wrap scroll-y mh-200px"
                  style="overflow-x: hidden"
                ></div>
                <!--end::Items-->
              </div>
              <!--end::Dropzone-->
              <div
                class="pt-5 mb-4 d-flex justify-content-center flex-column flex-md-row"
              >
                <button
                  ref="testFocus"
                  class="col-12 col-md-6 btn btn-primary px-15 m-1"
                  type="button"
                  @click="userRegistration"
                >
                  ارسال اطلاعات
                </button>
                <button
                  class="col-12 col-md-6 btn btn-secondary px-15 m-1"
                  type="button"
                  @click="previousStep"
                >
                  بازگشت
                </button>
              </div>
            </div>
          </form>
        </div>
        <!-- end::Content -->
      </div>
    </div>
    <div
      v-else-if="$store.state.uploadsPath == 'behzadroohparvar.com'"
      class="card card-bordered border-gray-400 p-0 px-md-20 py-md-10"
      style="border-radius: 0.5rem"
    >
      <div
        class="card-body scroll-y px-md-15 mt-5"
        :style="currentState == 0 ? 'height: 80vh' : 'height: 75vh'"
      >
        <div>
          <!-- begin::Content -->
          <div>
            <h3 class="text-center">ثبت نام در انجمن صوفی</h3>
            <p class="text-center text-gray-700">
              لطفا جهت تکمیل ثبت نام، هزینه اشتراک را به شماره کارت زیر انتقال
              دهید و رسید پرداختی را در فیلد مربوطه بارگذاری نمایید:
            </p>
            <p class="text-center text-gray-700">
              <span class="me-3">شماره کارت:</span
              ><span class="fw-boldest">123456789</span>
            </p>
            <p class="text-center text-gray-700">
              <span class="me-3">هزینه اشتراک:</span
              ><span class="fw-boldest">123456789</span
              ><span class="ms-3 fw-boldest">ریال</span>
            </p>
            <form class="form text-center text-md-start">
              <div class="my-10 my-md-5">
                <!--begin::Label-->
                <label class="form-label"> نام و نام خانوادگی </label>
                <!--end::Label-->
                <!--begin::Input-->
                <div class="d-flex">
                  <div class="position-relative flex-grow-1 me-3">
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder="نام و نام خانوادگی خود را وارد نمایید"
                      v-model="fullName"
                    />
                  </div>
                </div>
              </div>
              <div class="my-10 my-md-5">
                <!--begin::Label-->
                <label class="form-label"> کدملی </label>
                <!--end::Label-->
                <!--begin::Input-->
                <div class="d-flex">
                  <div class="position-relative flex-grow-1 me-3">
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder="کدملی خود را وارد نمایید"
                      v-model="fullName"
                    />
                  </div>
                </div>
              </div>
              <div class="my-10 my-md-5">
                <!--begin::Label-->
                <label class="form-label"> شماره تماس </label>
                <!--end::Label-->
                <!--begin::Input-->
                <div class="d-flex">
                  <div class="position-relative flex-grow-1 me-3">
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder="شماره تماس خود را وارد نمایید"
                      v-model="fullName"
                    />
                  </div>
                </div>
              </div>
              <div class="my-10 my-md-5">
                <!--begin::Label-->
                <label class="form-label"> کد اعتبار سنجی پیامک شده </label>
                <!--end::Label-->
                <!--begin::Input-->
                <div class="d-flex">
                  <div class="position-relative flex-grow-1 me-3">
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder="کد اعتبار سنجی ارسال شده را وارد نمایید"
                      v-model="fullName"
                    />
                  </div>
                </div>
              </div>
              <div class="my-10 my-md-5">
                <!--begin::Label-->
                <label class="form-label"> رسید پرداخت هزینه اشتراک </label>
                <!--end::Label-->
                <!--begin::Input-->
                <div class="d-flex">
                  <div
                    @click="
                      setModalConfigs('choose', {
                        root: field.name,
                        depth: 1,
                      })
                    "
                    class="fv-row w-100"
                  >
                    <!--begin::Dropzone-->
                    <div
                      class="dropzone bg-light-primary rounded border-primary border border-dashed"
                      id="kt_dropzonejs_example_1"
                    >
                      <!--begin::Message-->
                      <!-- v-if="
                            (info.photos && info.photos.length > 0) ||
                            (info[field.name] && info[field.name].length > 0)
                          " -->
                      <div
                        class="d-flex flex-center flex-wrap scroll-y mh-200px"
                      >
                        <!-- <div
                            v-for="photo in info.photos || info[field.name]"
                            :key="photo"
                            class="d-flex flex-column flex-center bg-dark bg-opacity-5 rounded border border-dashed border-primary p-5 m-2 col-6 col-md-3 col-lg-2"
                          >
                            <div class="symbol symbol-100px">
                              <img
                                :src="`${backendHost}/uploads/${$store.state.uploadsPath}/${photo}`"
                              />
                            </div>
                          </div> -->
                      </div>
                      <div class="dz-message needsclick">
                        <!--begin::Icon-->
                        <i
                          class="bi bi-file-earmark-arrow-up text-primary fs-3x"
                        ></i>
                        <!--end::Icon-->

                        <!--begin::Info-->
                        <div class="ms-4">
                          <h3 class="fs-5 fw-bolder text-gray-900 mb-1">
                            Click here to upload an image
                          </h3>
                          <span class="fs-7 fw-bold text-gray-400"
                            >Upload up to 10 files</span
                          >
                        </div>
                        <!--end::Info-->
                      </div>
                    </div>
                    <!--end::Dropzone-->
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- end::Content -->
        </div>
      </div>
      <div class="card-footer py-2" v-if="currentState > 0">
        <!-- begin::Actions -->
        <div class="d-flex flex-center w-100">
          <a
            class="btn btn-primary"
            data-kt-stepper-action="submit"
            @click="submitForm()"
          >
            <span class="indicator-label fs-3">ثبت نام</span>
          </a>
        </div>
        <!-- end::Actions -->
      </div>
    </div>
    <div
      v-else
      class="card card-bordered border-gray-300"
      style="border-radius: 0.5rem"
    >
      <div
        class="card-body scroll-y px-md-15 mt-5"
        :style="currentState == 0 ? 'height: 80vh' : 'height: 75vh'"
      >
        <div>
          <!-- begin::Content -->
          <div>
            <form
              @keydown.enter="changeState('next')"
              class="form"
              v-if="contents.status"
            >
              <!-- begin::Step 0 -->
              <div
                v-for="(step, stepNo) in contents.data.page.form.steps"
                :key="stepNo"
              >
                <div v-if="currentState == Number(step.step)">
                  <h3 class="text-center">{{ step.title }}</h3>
                  <h6
                    class="text-muted text-justify fs-6 mb-10"
                    v-html="step.description"
                  ></h6>
                  <div
                    v-for="(row, rowNo) in step.rows"
                    :key="rowNo"
                    class="d-flex flex-wrap"
                  >
                    <div
                      v-for="(col, colNo) in row"
                      :key="colNo"
                      class="col-12 mb-5"
                    >
                      <div v-if="col.type == 'text'">
                        <!--begin::Label-->
                        <label
                          class="form-label"
                          :class="col.required ? 'required' : ''"
                          >{{ col.title }}</label
                        >
                        <i
                          v-if="col.hint && col.hint.length > 0"
                          class="bi bi-question-circle-fill ms-2 fs-7"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          :title="col.hint"
                        ></i>
                        <!--end::Label-->
                        <!--begin::Input-->
                        <div class="d-flex">
                          <div class="position-relative flex-grow-1 me-3">
                            <input
                              :ref="col.name"
                              :type="col.type"
                              class="form-control form-control-solid"
                              :class="col.errorCode > 0 ? 'border-danger' : ''"
                              :placeholder="col.placeholder"
                              v-model="registerInfo[col.key]"
                            />
                            <!--begin::CVV icon-->
                            <div
                              class="position-absolute translate-middle-y top-50 end-0 me-3"
                            >
                              <i
                                class="fs-1 me-3"
                                :class="col.icon"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Tooltip on top"
                              ></i>
                            </div>
                            <!--end::CVV icon-->
                          </div>
                          <!--begin:Radio-->
                          <span
                            v-if="
                              data.info.customerEmail.length &&
                              data.info.customerPhoneNumber.length
                            "
                            class="form-check form-check-custom"
                          >
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Auth"
                              value="email"
                              @click="selcetedItems($event)"
                            />
                          </span>
                          <!--end:Radio-->
                        </div>
                        <!--end::Input-->
                        <div
                          v-if="col.required && col.errorCode > 0"
                          id="validationErrorFeedback"
                          class="invalid-feedback d-block"
                        >
                          {{ col.validation[col.errorCode - 1].error }}
                        </div>
                      </div>
                      <div v-else-if="col.type == 'email'">
                        <!--begin::Label-->
                        <label
                          class="form-label"
                          :class="col.required ? 'required' : ''"
                          >{{ col.title }}</label
                        >
                        <i
                          v-if="col.hint && col.hint.length > 0"
                          class="bi bi-question-circle-fill ms-2 fs-7"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          :title="col.hint"
                        ></i>
                        <!--end::Label-->
                        <!--begin::Input-->
                        <div class="d-flex">
                          <div class="position-relative flex-grow-1 me-3">
                            <input
                              :ref="col.name"
                              :type="col.type"
                              class="form-control form-control-solid"
                              :class="col.errorCode > 0 ? 'border-danger' : ''"
                              :placeholder="col.placeholder"
                              v-model="registerInfo[col.key]"
                            />
                            <!--begin::CVV icon-->
                            <div
                              class="position-absolute translate-middle-y top-50 end-0 me-3"
                            >
                              <i
                                class="fs-1 me-3"
                                :class="col.icon"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Tooltip on top"
                              ></i>
                            </div>
                            <!--end::CVV icon-->
                          </div>
                          <!--begin:Radio-->
                          <span
                            v-if="
                              data.info.customerEmail.length &&
                              data.info.customerPhoneNumber.length
                            "
                            class="form-check form-check-custom"
                          >
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Auth"
                              value="email"
                              @click="selcetedItems($event)"
                            />
                          </span>
                          <!--end:Radio-->
                        </div>
                        <!--end::Input-->
                        <div
                          v-if="col.required && col.errorCode > 0"
                          id="validationErrorFeedback"
                          class="invalid-feedback d-block"
                        >
                          {{ col.validation[col.errorCode - 1].error }}
                        </div>
                      </div>
                      <div v-else-if="col.type == 'tel'">
                        <!--begin::Label-->
                        <label
                          class="form-label"
                          :class="col.required ? 'required' : ''"
                          >{{ col.title }}</label
                        >
                        <i
                          v-if="col.hint && col.hint.length > 0"
                          class="bi bi-question-circle-fill ms-2 fs-7"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          :title="col.hint"
                        ></i>
                        <!--end::Label-->
                        <!--begin::Input-->
                        <div class="d-flex">
                          <div class="position-relative flex-grow-1 me-3">
                            <input
                              :ref="col.name"
                              :type="col.type"
                              class="form-control form-control-solid"
                              :class="col.errorCode > 0 ? 'border-danger' : ''"
                              :placeholder="col.placeholder"
                              v-model="registerInfo[col.key]"
                            />
                            <!--begin::CVV icon-->
                            <div
                              class="position-absolute translate-middle-y top-50 end-0 me-3"
                            >
                              <i
                                class="fs-1 me-3"
                                :class="col.icon"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Tooltip on top"
                              ></i>
                            </div>
                            <!--end::CVV icon-->
                          </div>
                          <!--begin:Radio-->
                          <span
                            v-if="
                              data.info.customerEmail.length &&
                              data.info.customerPhoneNumber.length
                            "
                            class="form-check form-check-custom"
                          >
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Auth"
                              value="email"
                              @click="selcetedItems($event)"
                            />
                          </span>
                          <!--end:Radio-->
                        </div>
                        <!--end::Input-->
                        <div
                          v-if="col.required && col.errorCode > 0"
                          id="validationErrorFeedback"
                          class="invalid-feedback d-block"
                        >
                          {{ col.validation[col.errorCode - 1].error }}
                        </div>
                      </div>
                      <div v-else-if="col.type == 'password'">
                        <!--begin::Label-->
                        <label
                          class="form-label"
                          :class="col.required ? 'required' : ''"
                          >{{ col.title }}</label
                        >
                        <i
                          v-if="col.hint && col.hint.length > 0"
                          class="bi bi-question-circle-fill ms-2 fs-7"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          :title="col.hint"
                        ></i>
                        <!--end::Label-->
                        <!--begin::Input-->
                        <div class="d-flex">
                          <div class="position-relative flex-grow-1 me-3">
                            <input
                              :ref="col.name"
                              :type="
                                col.type == 'password'
                                  ? visibility[col.name]
                                    ? 'text'
                                    : 'password'
                                  : col.type
                              "
                              class="form-control form-control-solid"
                              :class="col.errorCode > 0 ? 'border-danger' : ''"
                              :placeholder="col.placeholder"
                              v-model="registerInfo[col.key]"
                            />
                            <!--begin::CVV icon-->
                            <div
                              class="position-absolute translate-middle-y top-50 end-0 me-3"
                            >
                              <i
                                v-if="col.type == 'password'"
                                @click="passwordVisibilitySwitch(col.name)"
                                class="fs-1 me-3"
                                :class="
                                  visibility[0]
                                    ? 'bi bi-eye-slash'
                                    : 'bi bi-eye'
                                "
                                style="cursor: pointer"
                              ></i>
                              <i
                                class="fs-1 me-3"
                                :class="col.icon"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Tooltip on top"
                              ></i>
                            </div>
                            <!--end::CVV icon-->
                          </div>
                          <!--begin:Radio-->
                          <span
                            v-if="
                              data.info.customerEmail.length &&
                              data.info.customerPhoneNumber.length
                            "
                            class="form-check form-check-custom"
                          >
                            <input
                              class="form-check-input"
                              type="radio"
                              name="Auth"
                              value="email"
                              @click="selcetedItems($event)"
                            />
                          </span>
                          <!--end:Radio-->
                        </div>
                        <!--end::Input-->
                        <div
                          v-if="col.required && col.errorCode > 0"
                          id="validationErrorFeedback"
                          class="invalid-feedback d-block"
                        >
                          {{ col.validation[col.errorCode - 1].error }}
                        </div>
                      </div>
                      <div v-else-if="col.type == 'combox'">
                        <div class="fv-row mb-10">
                          <!--begin::Label-->
                          <label
                            class="form-label"
                            :class="col.required ? 'required' : ''"
                            >{{ col.title }}</label
                          >
                          <i
                            v-if="col.hint && col.hint.length > 0"
                            class="bi bi-question-circle-fill ms-2 fs-7"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            :title="col.hint"
                          ></i>
                          <!--end::Label-->
                          <select
                            :ref="col.name"
                            v-model="registerInfo[col.key]"
                            class="form-select form-select-solid form-select-lg"
                            :class="col.errorCode > 0 ? 'border-danger' : ''"
                          >
                            <option
                              v-if="
                                col.placeholder && col.placeholder.length > 0
                              "
                              value=""
                              disabled
                              selected
                              hidden
                            >
                              {{ col.placeholder }}
                            </option>
                            <option
                              v-for="(option, i) in col.options"
                              :key="i"
                              :value="`${option.locale}`"
                            >
                              {{ option.title }}
                            </option>
                          </select>
                          <div
                            v-if="col.required && col.errorCode > 0"
                            id="validationErrorFeedback"
                            class="invalid-feedback d-block"
                          >
                            {{ col.validation[col.errorCode - 1].error }}
                          </div>
                        </div>
                      </div>
                      <div v-if="col.type == 'checkbox'">
                        <!--begin::Label-->
                        <div
                          class="form-check form-check-custom form-check-solid"
                        >
                          <input
                            class="form-check-input"
                            :ref="col.name"
                            :type="col.type"
                            :id="col.name"
                            v-model="registerInfo[col.key]"
                            :class="
                              col.errorCode > 0 ? 'border border-danger' : ''
                            "
                          />
                          <label
                            class="form-check-label fs-6 fw-bold"
                            :for="col.name"
                          >
                            {{ col.summary }}
                          </label>
                        </div>
                        <div
                          v-if="col.required && col.errorCode > 0"
                          id="validationErrorFeedback"
                          class="invalid-feedback d-block"
                        >
                          {{ col.validation[col.errorCode - 1].error }}
                        </div>
                      </div>
                      <div v-else-if="col.type == 'readText'">
                        <div
                          class="d-flex align-items-center bg-light-primary rounded p-5 mb-5"
                        >
                          <i
                            class="fs-2x text-primary me-5"
                            :class="col.icon"
                          ></i>
                          <!--begin::Title-->
                          <div class="flex-grow-1 me-2">
                            <a
                              class="fw-bold text-gray-800 text-hover-primary fs-6"
                              >{{ col.title }}</a
                            >
                            <span class="text-muted fw-semibold d-block">{{
                              col.summary
                            }}</span>
                          </div>
                          <!--end::Title-->
                          <!--begin::Lable-->
                          <div v-if="col.valueKey && col.valueKey[0].rel">
                            <div v-for="k in col.valueKey" :key="k">
                              <span
                                v-if="
                                  registerInfo[k.rel] ==
                                  k.expression.replace('equalTo ', '')
                                "
                                class="fw-bold text-primary py-1"
                                >{{ registerInfo[k.value] }}</span
                              >
                            </div>
                          </div>
                          <span v-else class="fw-bold text-primary py-1">{{
                            col.value
                              ? col.value
                              : col.valueKey
                              ? registerInfo[col.valueKey]
                              : ""
                          }}</span>
                          <!--end::Lable-->
                        </div>
                      </div>
                      <div v-else-if="col.type == 'tab'">
                        <div class="d-flex flex-column flex-md-row p-md-10">
                          <ul
                            class="nav nav-tabs nav-pills flex-row border-0 flex-md-column me-5 mb-3 mb-md-0 fs-6 min-w-lg-200px"
                            role="tablist"
                          >
                            <li
                              v-for="(tab, tabNo) in col.tabs"
                              :key="tabNo"
                              class="nav-item me-0 mb-md-2"
                              @click="changeTab(col.key, tab.tab)"
                            >
                              <a
                                class="nav-link w-100 btn btn-flex btn-active-light-primary"
                                :class="
                                  registerInfo[col.key] == tab.tab
                                    ? 'active'
                                    : ''
                                "
                              >
                                <span
                                  class="d-flex flex-column align-items-start min-w-lg-100px"
                                >
                                  <span class="fs-4 fw-bold">{{
                                    tab.navTitle
                                  }}</span>
                                  <span class="fs-7">{{
                                    tab.navSubtitle
                                  }}</span>
                                </span>
                              </a>
                            </li>
                          </ul>
                          <div class="tab-content w-100">
                            <div v-for="(tab, tabNo) in col.tabs" :key="tabNo">
                              <div
                                v-if="registerInfo[col.key] == tab.tab"
                                class="tab-pane"
                              >
                                <h4>{{ tab.title }}</h4>
                                <p class="text-muted mb-7">
                                  {{ tab.description }}
                                </p>
                                <!-- begin::Input -->
                                <div
                                  v-for="(tabRow, tabRowNo) in tab.rows"
                                  :key="tabRowNo"
                                  class="mb-5"
                                >
                                  <div
                                    v-for="(tabCol, tabColNo) in tabRow"
                                    :key="tabColNo"
                                  >
                                    <!-- begin::Input -->
                                    <div v-if="tabCol.type == 'text'">
                                      <!--begin::Label-->
                                      <label
                                        class="form-label"
                                        :class="
                                          tabCol.required ? 'required' : ''
                                        "
                                        >{{ tabCol.title }}</label
                                      >
                                      <i
                                        v-if="
                                          tabCol.hint && tabCol.hint.length > 0
                                        "
                                        class="bi bi-question-circle-fill ms-2 fs-7"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        :title="tabCol.hint"
                                      ></i>
                                      <!--end::Label-->
                                      <!--begin::Input-->
                                      <div class="d-flex">
                                        <div
                                          class="position-relative flex-grow-1 me-3"
                                        >
                                          <input
                                            :ref="tabCol.name"
                                            :type="tabCol.type"
                                            class="form-control form-control-solid"
                                            :class="
                                              tabCol.errorCode > 0
                                                ? 'border-danger'
                                                : ''
                                            "
                                            :placeholder="tabCol.placeholder"
                                            v-model="registerInfo[tabCol.key]"
                                          />
                                          <!--begin::CVV icon-->
                                          <div
                                            class="position-absolute translate-middle-y top-50 end-0 me-3"
                                          >
                                            <i
                                              class="fs-1 me-3"
                                              :class="tabCol.icon"
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="top"
                                              title="Tooltip on top"
                                            ></i>
                                          </div>
                                          <!--end::CVV icon-->
                                        </div>
                                        <!--begin:Radio-->
                                        <span
                                          v-if="
                                            data.info.customerEmail.length &&
                                            data.info.customerPhoneNumber.length
                                          "
                                          class="form-check form-check-custom"
                                        >
                                          <input
                                            class="form-check-input"
                                            type="radio"
                                            name="Auth"
                                            value="email"
                                            @click="selcetedItems($event)"
                                          />
                                        </span>
                                        <!--end:Radio-->
                                      </div>
                                      <!--end::Input-->
                                      <div
                                        v-if="
                                          tabCol.required &&
                                          tabCol.errorCode > 0
                                        "
                                        id="validationErrorFeedback"
                                        class="invalid-feedback d-block"
                                      >
                                        {{
                                          tabCol.validation[
                                            tabCol.errorCode - 1
                                          ].error
                                        }}
                                      </div>
                                    </div>
                                    <div v-if="tabCol.type == 'color'">
                                      <!--begin::Label-->
                                      <label
                                        class="form-label"
                                        :class="
                                          tabCol.required ? 'required' : ''
                                        "
                                        >{{ tabCol.title }}</label
                                      >
                                      <i
                                        v-if="
                                          tabCol.hint && tabCol.hint.length > 0
                                        "
                                        class="bi bi-question-circle-fill ms-2 fs-7"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        :title="tabCol.hint"
                                      ></i>
                                      <!--end::Label-->
                                      <!--begin::Input-->
                                      <input
                                        :ref="tabCol.name"
                                        :type="tabCol.type"
                                        class="form-control form-control-solid w-100px h-40px"
                                        :class="
                                          tabCol.errorCode > 0
                                            ? 'border-danger'
                                            : ''
                                        "
                                        :placeholder="tabCol.placeholder"
                                        v-model="registerInfo[tabCol.key]"
                                      />
                                      <!--end::Input-->
                                      <div
                                        v-if="
                                          tabCol.required &&
                                          tabCol.errorCode > 0
                                        "
                                        id="validationErrorFeedback"
                                        class="invalid-feedback d-block"
                                      >
                                        {{
                                          tabCol.validation[
                                            tabCol.errorCode - 1
                                          ].error
                                        }}
                                      </div>
                                    </div>
                                    <div v-else-if="tabCol.type == 'textarea'">
                                      <!--begin::Label-->
                                      <label
                                        class="form-label"
                                        :class="
                                          tabCol.required ? 'required' : ''
                                        "
                                        >{{ tabCol.title }}</label
                                      >
                                      <i
                                        v-if="
                                          tabCol.hint && tabCol.hint.length > 0
                                        "
                                        class="bi bi-question-circle-fill ms-2 fs-7"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        :title="tabCol.hint"
                                      ></i>
                                      <!--end::Label-->
                                      <!--begin::Input-->
                                      <div class="d-flex">
                                        <div
                                          class="position-relative flex-grow-1 me-3"
                                        >
                                          <textarea
                                            rows="7"
                                            :ref="tabCol.name"
                                            :type="tabCol.type"
                                            class="form-control form-control-solid"
                                            :class="
                                              tabCol.errorCode > 0
                                                ? 'border-danger'
                                                : ''
                                            "
                                            :placeholder="tabCol.placeholder"
                                            v-model="registerInfo[tabCol.key]"
                                          />
                                          <!--begin::CVV icon-->
                                          <div
                                            class="position-absolute translate-middle-y top-50 end-0 me-3"
                                          >
                                            <i
                                              class="fs-1 me-3"
                                              :class="tabCol.icon"
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="top"
                                              title="Tooltip on top"
                                            ></i>
                                          </div>
                                          <!--end::CVV icon-->
                                        </div>
                                        <!--begin:Radio-->
                                        <span
                                          v-if="
                                            data.info.customerEmail.length &&
                                            data.info.customerPhoneNumber.length
                                          "
                                          class="form-check form-check-custom"
                                        >
                                          <input
                                            class="form-check-input"
                                            type="radio"
                                            name="Auth"
                                            value="email"
                                            @click="selcetedItems($event)"
                                          />
                                        </span>
                                        <!--end:Radio-->
                                      </div>
                                      <!--end::Input-->
                                      <div
                                        v-if="
                                          tabCol.required &&
                                          tabCol.errorCode > 0
                                        "
                                        id="validationErrorFeedback"
                                        class="invalid-feedback d-block"
                                      >
                                        {{
                                          tabCol.validation[
                                            tabCol.errorCode - 1
                                          ].error
                                        }}
                                      </div>
                                    </div>
                                    <div v-else-if="tabCol.type == 'textGroup'">
                                      <!--begin::Label-->
                                      <label
                                        class="form-label"
                                        :class="
                                          tabCol.required ? 'required' : ''
                                        "
                                        >{{ tabCol.title }}</label
                                      >
                                      <i
                                        v-if="
                                          tabCol.hint && tabCol.hint.length > 0
                                        "
                                        class="bi bi-question-circle-fill ms-2 fs-7"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        :title="tabCol.hint"
                                      ></i>
                                      <!--end::Label-->
                                      <!--begin::Input-->
                                      <div class="d-flex">
                                        <!--begin::Input-->
                                        <div class="input-group mb-5">
                                          <span class="input-group-text"
                                            >https://www.</span
                                          >
                                          <input
                                            :ref="tabCol.name"
                                            :type="tabCol.type"
                                            class="form-control"
                                            :class="
                                              tabCol.errorCode > 0
                                                ? 'border-danger'
                                                : ''
                                            "
                                            :placeholder="tabCol.placeholder"
                                            v-model="registerInfo[tabCol.key]"
                                          />
                                          <span class="input-group-text"
                                            >.webplate.info</span
                                          >
                                        </div>
                                        <!--end::Input-->
                                        <!--begin:Radio-->
                                        <span
                                          v-if="
                                            data.info.customerEmail.length &&
                                            data.info.customerPhoneNumber.length
                                          "
                                          class="form-check form-check-custom"
                                        >
                                          <input
                                            class="form-check-input"
                                            type="radio"
                                            name="Auth"
                                            value="email"
                                            @click="selcetedItems($event)"
                                          />
                                        </span>
                                        <!--end:Radio-->
                                      </div>
                                      <div
                                        v-if="
                                          tabCol.required &&
                                          tabCol.errorCode > 0
                                        "
                                        id="validationErrorFeedback"
                                        class="invalid-feedback d-block"
                                      >
                                        {{
                                          tabCol.validation[
                                            tabCol.errorCode - 1
                                          ].error
                                        }}
                                      </div>
                                      <!--end::Input-->
                                    </div>
                                    <div v-else-if="tabCol.type == 'tel'">
                                      <!--begin::Label-->
                                      <label
                                        class="form-label"
                                        :class="
                                          tabCol.required ? 'required' : ''
                                        "
                                        >{{ tabCol.title }}</label
                                      >
                                      <i
                                        v-if="
                                          tabCol.hint && tabCol.hint.length > 0
                                        "
                                        class="bi bi-question-circle-fill ms-2 fs-7"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        :title="tabCol.hint"
                                      ></i>
                                      <!--end::Label-->
                                      <!--begin::Input-->
                                      <div class="d-flex">
                                        <div
                                          class="position-relative flex-grow-1 me-3"
                                        >
                                          <input
                                            :ref="tabCol.name"
                                            :type="tabCol.type"
                                            class="form-control form-control-solid"
                                            :class="
                                              tabCol.errorCode > 0
                                                ? 'border-danger'
                                                : ''
                                            "
                                            :placeholder="tabCol.placeholder"
                                            v-model="registerInfo[tabCol.key]"
                                          />
                                          <!--begin::CVV icon-->
                                          <div
                                            class="position-absolute translate-middle-y top-50 end-0 me-3"
                                          >
                                            <i
                                              class="fs-1 me-3"
                                              :class="tabCol.icon"
                                              data-bs-toggle="tooltip"
                                              data-bs-placement="top"
                                              title="Tooltip on top"
                                            ></i>
                                          </div>
                                          <!--end::CVV icon-->
                                        </div>
                                        <!--begin:Radio-->
                                        <span
                                          v-if="
                                            data.info.customerEmail.length &&
                                            data.info.customerPhoneNumber.length
                                          "
                                          class="form-check form-check-custom"
                                        >
                                          <input
                                            class="form-check-input"
                                            type="radio"
                                            name="Auth"
                                            value="email"
                                            @click="selcetedItems($event)"
                                          />
                                        </span>
                                        <!--end:Radio-->
                                      </div>
                                      <!--end::Input-->
                                      <div
                                        v-if="
                                          tabCol.required &&
                                          tabCol.errorCode > 0
                                        "
                                        id="validationErrorFeedback"
                                        class="invalid-feedback d-block"
                                      >
                                        {{
                                          tabCol.validation[
                                            tabCol.errorCode - 1
                                          ].error
                                        }}
                                      </div>
                                    </div>
                                    <div v-else-if="tabCol.type == 'radio'">
                                      <div
                                        :ref="tabCol.name"
                                        class="d-flex mb-10 justify-content-between"
                                      >
                                        <div
                                          v-for="(
                                            option, optionNo
                                          ) in tabCol.options"
                                          :key="optionNo"
                                          class="form-check form-check-custom form-check-solid me-5"
                                        >
                                          <input
                                            class="form-check-input"
                                            type="radio"
                                            :id="`singlePageTemplate_${optionNo}`"
                                            name="templatePages"
                                          />
                                          <label
                                            class="form-check-label"
                                            :for="`singlePageTemplate_${optionNo}`"
                                          >
                                            {{ option.title }}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      v-else-if="tabCol.type == 'imgRadio'"
                                      :ref="tabCol.name"
                                    >
                                      <div
                                        v-if="
                                          tabCol.required &&
                                          tabCol.errorCode > 0
                                        "
                                        id="validationErrorFeedback"
                                        class="invalid-feedback d-block mb-10"
                                      >
                                        {{
                                          tabCol.validation[
                                            tabCol.errorCode - 1
                                          ].error
                                        }}
                                      </div>
                                      <div
                                        class="d-flex justify-content-center row-cols-auto gap-5 gap-lg-10 flex-wrap"
                                        data-kt-buttons="true"
                                      >
                                        <div
                                          v-for="(template, index) in templates[
                                            tabCol.src
                                          ]"
                                          :key="index"
                                          class="d-flex flex-center border border-dashed rounded-3 border-secondary p-6"
                                          :class="
                                            tabCol.errorCode > 0
                                              ? 'border-danger'
                                              : ''
                                          "
                                        >
                                          <label class="active btn p-6"
                                            ><!--end::Description-->
                                            <div
                                              class="d-flex align-items-center me-2"
                                            >
                                              <!--begin::Radio-->
                                              <div
                                                class="form-check form-check-lg form-check-custom form-check-solid form-check-primary me-6"
                                              >
                                                <input
                                                  class="form-check-input"
                                                  type="radio"
                                                  name="webTemplate"
                                                  :checked="
                                                    registerInfo[tabCol.key] ==
                                                    template.templateName
                                                  "
                                                  @click="
                                                    changeRadioTab(
                                                      tabCol.key,
                                                      template.templateName
                                                    )
                                                  "
                                                />
                                              </div>
                                              <!--end::Radio-->
                                            </div>
                                            <!--end::Description-->
                                          </label>
                                          <!--begin::Info-->
                                          <a
                                            class="d-block overlay cursor-pointer"
                                            @click="
                                              softBox(
                                                tabCol.src,
                                                index,
                                                template.templateName
                                              )
                                            "
                                          >
                                            <div
                                              class="overlay-wrapper bgi-no-repeat bgi-position-top bgi-size-cover card-rounded h-100px w-200px h-md-125px w-md-250px bg-secondary"
                                              :style="`${
                                                tabCol.src == 'email'
                                                  ? `background-image: url(${backendHost}/images/preview/${
                                                      template.templateName
                                                    }/${
                                                      themes[
                                                        registerInfo[tabCol.rel]
                                                      ].themePreview[
                                                        tabCol.src
                                                      ][template.templateName]
                                                    });`
                                                  : `background-image: url(${backendHost}/images/preview/${template.templatePreview});`
                                              }`"
                                            ></div>
                                            <!--begin::Action-->
                                            <div
                                              class="overlay-layer card-rounded bg-dark bg-opacity-25"
                                            >
                                              <i
                                                class="bi bi-eye-fill fs-2x text-white"
                                              ></i>
                                            </div>
                                            <!--end::Action-->
                                          </a>
                                          <!--end::Info-->
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      v-else-if="tabCol.type == 'mulImgRadio'"
                                      :ref="tabCol.name"
                                    >
                                      <div
                                        v-if="
                                          tabCol.required &&
                                          tabCol.errorCode > 0
                                        "
                                        id="validationErrorFeedback"
                                        class="invalid-feedback d-block mb-10"
                                      >
                                        {{
                                          tabCol.validation[
                                            tabCol.errorCode - 1
                                          ].error
                                        }}
                                      </div>
                                      <div
                                        v-for="(theme, index) in themes"
                                        :key="index"
                                        class="d-flex justify-content-center row-cols-auto gap-5 gap-lg-10 flex-wrap mb-5"
                                        data-kt-buttons="true"
                                      >
                                        <!--begin::Radio button-->
                                        <label
                                          class="btn btn-outline btn-outline-dashed d-flex text-start p-6"
                                          :class="
                                            tabCol.errorCode > 0
                                              ? 'border-danger'
                                              : ''
                                          "
                                          ><!--end::Description-->
                                          <div
                                            class="d-flex align-items-center me-2 w-100"
                                          >
                                            <!--begin::Radio-->
                                            <div
                                              class="form-check form-check-custom form-check-solid form-check-primary me-6"
                                            >
                                              <input
                                                class="form-check-input"
                                                type="radio"
                                                name="colorTheme"
                                                :checked="
                                                  registerInfo[tabCol.key] ==
                                                  index
                                                "
                                                @click="
                                                  changeRadioTab(
                                                    tabCol.key,
                                                    index
                                                  )
                                                "
                                              />
                                            </div>
                                            <!--end::Radio-->
                                            <div
                                              class="d-flex flex-column flex-md-row flex-wrap"
                                            >
                                              <!--begin::Info-->
                                              <div class="mb-5 me-5">
                                                <img
                                                  :src="`${backendHost}/images/preview/${
                                                    registerInfo[
                                                      'projectTemplate'
                                                    ]
                                                  }/${
                                                    theme.themePreview[
                                                      tabCol.rel
                                                    ][
                                                      registerInfo[
                                                        'projectTemplate'
                                                      ]
                                                    ]
                                                  }`"
                                                  class="h-100px w-175px h-xl-200px w-xl-350px rounded"
                                                  alt=""
                                                  style="object-fit: cover"
                                                />
                                              </div>
                                              <!--end::Info-->
                                              <!--begin::Info-->
                                              <div class="">
                                                <img
                                                  :src="`${backendHost}/images/preview/${theme.themeImage}`"
                                                  class="h-100px w-175px h-xl-200px w-xl-350px rounded"
                                                  :alt="`${theme.themeName} Preview`"
                                                  style="object-fit: cover"
                                                />
                                              </div>
                                              <!--end::Info-->
                                            </div>
                                          </div>
                                          <!--end::Description-->
                                        </label>
                                        <!--end::Radio button-->
                                      </div>
                                    </div>
                                    <div
                                      v-else-if="tabCol.type == 'selectGroup'"
                                    >
                                      <div
                                        :ref="tabCol.name"
                                        class="border border-dashed border-primary rounded p-10"
                                      >
                                        <h4 class="text-center mb-7">
                                          {{ tabCol.title }}
                                        </h4>
                                        <!--begin::Item-->
                                        <div
                                          v-for="(
                                            option, optionNo
                                          ) in tabCol.options"
                                          :key="optionNo"
                                        >
                                          <div class="d-flex flex-stack">
                                            <div class="d-flex flex-column">
                                              <a
                                                class="fs-5 text-dark text-hover-primary fw-bolder"
                                                >{{ option.title }}</a
                                              >
                                            </div>
                                            <!--begin::Switch-->
                                            <label
                                              class="form-check form-switch form-switch-sm form-check-custom form-check-solid"
                                            >
                                              <!--begin::Input-->
                                              <input
                                                class="form-check-input"
                                                name="test"
                                                type="checkbox"
                                              />
                                              <!--end::Input-->
                                              <!--begin::Label-->
                                              <span
                                                class="form-check-label fw-bold text-muted"
                                              ></span>
                                              <!--end::Label-->
                                            </label>
                                            <!--end::Switch-->
                                          </div>
                                          <!--end::Item-->
                                          <div
                                            v-if="
                                              Number(optionNo) !=
                                              Number(tabCol.options.length) - 1
                                            "
                                            class="separator separator-dashed my-5"
                                          ></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- end::Input -->
                                </div>
                                <!-- end::Input -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else-if="col.type == 'radioTab'">
                        <div
                          v-for="(tab, tabNo) in col.tabs"
                          :key="tabNo"
                          class="mb-5"
                        >
                          <label class="d-flex flex-stack mb-5">
                            <!--begin:Input-->
                            <span
                              class="form-check form-check-custom form-check-solid me-3"
                            >
                              <input
                                class="form-check-input"
                                type="radio"
                                name="logo"
                                value="upload"
                                :checked="registerInfo[col.key] == tabNo"
                                @click="changeRadioTab(col.key, tab.tab)"
                              />
                            </span>
                            <!--end:Input-->
                            <!--begin:Label-->
                            <span class="d-flex flex-grow-1 align-items-center">
                              <!--begin:Icon-->
                              <span class="symbol symbol-50px me-6">
                                <span
                                  class="symbol-label fs-1 fw-semibold bg-light-primary text-primary"
                                >
                                  {{ Number(tabNo) + 1 }}
                                </span>
                              </span>
                              <!--end:Icon-->

                              <!--begin:Info-->
                              <span class="d-flex flex-column">
                                <span class="fw-bold fs-6">{{
                                  tab.title
                                }}</span>
                                <span class="fs-7 text-muted">{{
                                  tab.description
                                }}</span>
                              </span>
                              <!--end:Info-->
                            </span>
                            <!--end:Label-->
                          </label>
                          <div v-if="registerInfo[col.key] == tab.tab">
                            <div
                              v-for="(tabRow, tabRowNo) in tab.rows"
                              :key="tabRowNo"
                              class="d-flex mb-5 align-items-center align-items-md-start justify-content-center flex-column flex-md-row flex-wrap"
                            >
                              <div
                                v-for="(tabCol, tabColNo) in tabRow"
                                :key="tabColNo"
                                :class="
                                  tabCol.type == 'selectMiniFile'
                                    ? ''
                                    : 'col-12'
                                "
                              >
                                <div v-if="tabCol.type == 'text'">
                                  <!--begin::Label-->
                                  <label
                                    class="form-label"
                                    :class="tabCol.required ? 'required' : ''"
                                    >{{ tabCol.title }}</label
                                  >
                                  <i
                                    v-if="tabCol.hint && tabCol.hint.length > 0"
                                    class="bi bi-question-circle-fill ms-2 fs-7"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    :title="tabCol.hint"
                                  ></i>
                                  <!--end::Label-->
                                  <!--begin::Input-->
                                  <div class="d-flex">
                                    <div
                                      class="position-relative flex-grow-1 me-3"
                                    >
                                      <input
                                        :ref="tabCol.name"
                                        :type="tabCol.type"
                                        class="form-control form-control-solid"
                                        :class="
                                          tabCol.errorCode > 0
                                            ? 'border-danger'
                                            : ''
                                        "
                                        :placeholder="tabCol.placeholder"
                                        v-model="registerInfo[tabCol.key]"
                                      />
                                      <!--begin::CVV icon-->
                                      <div
                                        class="position-absolute translate-middle-y top-50 end-0 me-3"
                                      >
                                        <i
                                          class="fs-1 me-3"
                                          :class="tabCol.icon"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          title="Tooltip on top"
                                        ></i>
                                      </div>
                                      <!--end::CVV icon-->
                                    </div>
                                    <!--begin:Radio-->
                                    <span
                                      v-if="
                                        data.info.customerEmail.length &&
                                        data.info.customerPhoneNumber.length
                                      "
                                      class="form-check form-check-custom"
                                    >
                                      <input
                                        class="form-check-input"
                                        type="radio"
                                        name="Auth"
                                        value="email"
                                        @click="selcetedItems($event)"
                                      />
                                    </span>
                                    <!--end:Radio-->
                                  </div>
                                  <div
                                    v-if="
                                      tabCol.required && tabCol.errorCode > 0
                                    "
                                    id="validationErrorFeedback"
                                    class="invalid-feedback d-block"
                                  >
                                    {{
                                      tabCol.validation[tabCol.errorCode - 1]
                                        .error
                                    }}
                                  </div>
                                  <!--end::Input-->
                                </div>
                                <div v-else-if="tabCol.type == 'textarea'">
                                  <!--begin::Label-->
                                  <label
                                    class="form-label"
                                    :class="tabCol.required ? 'required' : ''"
                                    >{{ tabCol.title }}</label
                                  >
                                  <i
                                    v-if="tabCol.hint && tabCol.hint.length > 0"
                                    class="bi bi-question-circle-fill ms-2 fs-7"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    :title="tabCol.hint"
                                  ></i>
                                  <!--end::Label-->
                                  <!--begin::Input-->
                                  <div class="d-flex">
                                    <div
                                      class="position-relative flex-grow-1 me-3"
                                    >
                                      <textarea
                                        rows="7"
                                        :ref="tabCol.name"
                                        :type="tabCol.type"
                                        class="form-control form-control-solid"
                                        :class="
                                          tabCol.errorCode > 0
                                            ? 'border-danger'
                                            : ''
                                        "
                                        :placeholder="tabCol.placeholder"
                                        v-model="registerInfo[tabCol.key]"
                                      />
                                      <!--begin::CVV icon-->
                                      <div
                                        class="position-absolute translate-middle-y top-50 end-0 me-3"
                                      >
                                        <i
                                          class="fs-1 me-3"
                                          :class="tabCol.icon"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          title="Tooltip on top"
                                        ></i>
                                      </div>
                                      <!--end::CVV icon-->
                                    </div>
                                    <!--begin:Radio-->
                                    <span
                                      v-if="
                                        data.info.customerEmail.length &&
                                        data.info.customerPhoneNumber.length
                                      "
                                      class="form-check form-check-custom"
                                    >
                                      <input
                                        class="form-check-input"
                                        type="radio"
                                        name="Auth"
                                        value="email"
                                        @click="selcetedItems($event)"
                                      />
                                    </span>
                                    <!--end:Radio-->
                                  </div>
                                  <!--end::Input-->
                                  <div
                                    v-if="
                                      tabCol.required && tabCol.errorCode > 0
                                    "
                                    id="validationErrorFeedback"
                                    class="invalid-feedback d-block"
                                  >
                                    {{
                                      tabCol.validation[tabCol.errorCode - 1]
                                        .error
                                    }}
                                  </div>
                                </div>
                                <div v-else-if="tabCol.type == 'tel'">
                                  <!--begin::Label-->
                                  <label
                                    class="form-label"
                                    :class="tabCol.required ? 'required' : ''"
                                    >{{ tabCol.title }}</label
                                  >
                                  <i
                                    v-if="tabCol.hint && tabCol.hint.length > 0"
                                    class="bi bi-question-circle-fill ms-2 fs-7"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    :title="tabCol.hint"
                                  ></i>
                                  <!--end::Label-->
                                  <!--begin::Input-->
                                  <div class="d-flex">
                                    <div
                                      class="position-relative flex-grow-1 me-3"
                                    >
                                      <input
                                        :ref="tabCol.name"
                                        :type="tabCol.type"
                                        class="form-control form-control-solid"
                                        :class="
                                          tabCol.errorCode > 0
                                            ? 'border-danger'
                                            : ''
                                        "
                                        :placeholder="tabCol.placeholder"
                                        v-model="registerInfo[tabCol.key]"
                                      />
                                      <!--begin::CVV icon-->
                                      <div
                                        class="position-absolute translate-middle-y top-50 end-0 me-3"
                                      >
                                        <i
                                          class="fs-1 me-3"
                                          :class="tabCol.icon"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          title="Tooltip on top"
                                        ></i>
                                      </div>
                                      <!--end::CVV icon-->
                                    </div>
                                    <!--begin:Radio-->
                                    <span
                                      v-if="
                                        data.info.customerEmail.length &&
                                        data.info.customerPhoneNumber.length
                                      "
                                      class="form-check form-check-custom"
                                    >
                                      <input
                                        class="form-check-input"
                                        type="radio"
                                        name="Auth"
                                        value="email"
                                        @click="selcetedItems($event)"
                                      />
                                    </span>
                                    <!--end:Radio-->
                                  </div>
                                  <!--end::Input-->
                                  <div
                                    v-if="
                                      tabCol.required && tabCol.errorCode > 0
                                    "
                                    id="validationErrorFeedback"
                                    class="invalid-feedback d-block"
                                  >
                                    {{
                                      tabCol.validation[tabCol.errorCode - 1]
                                        .error
                                    }}
                                  </div>
                                </div>
                                <div v-else-if="tabCol.type == 'selectFile'">
                                  <!--begin::Form-->
                                  <!--begin::Notice-->
                                  <div
                                    class="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6"
                                    :class="
                                      tabCol.errorCode > 0
                                        ? 'border-danger'
                                        : ''
                                    "
                                  >
                                    <!--begin::Icon-->
                                    <!--begin::Svg Icon | -->
                                    <i
                                      class="bi bi-file-earmark-arrow-up text-primary fs-3x me-2"
                                    ></i>
                                    <!--end::Svg Icon-->
                                    <!--end::Icon-->
                                    <!--begin::Wrapper-->
                                    <div class="d-flex flex-stack flex-grow-1">
                                      <!--begin::Content-->
                                      <form
                                        class="form"
                                        action="#"
                                        method="post"
                                      >
                                        <!--begin::Input group-->
                                        <div class="form-group row">
                                          <!--begin::Col-->
                                          <div class="col-12">
                                            <!--begin::Dropzone-->
                                            <div
                                              class="dropzone dropzone-queue mb-2"
                                              id="kt_dropzonejs_example_2"
                                            >
                                              <!--begin::Controls-->
                                              <div class="d-flex">
                                                <div
                                                  class="dropzone-panel mb-lg-0 mb-2"
                                                >
                                                  <a
                                                    class="dropzone-select btn btn-sm btn-primary me-2"
                                                    >الصاق فایل</a
                                                  >
                                                  <a
                                                    class="dropzone-upload btn btn-sm btn-light-primary me-2"
                                                    >بارگذاری همه</a
                                                  >
                                                  <a
                                                    class="dropzone-remove-all btn btn-sm btn-light-primary me-2"
                                                    >حذف همه</a
                                                  >
                                                </div>
                                              </div>
                                              <!--end::Controls-->

                                              <!--begin::Items-->
                                              <div
                                                class="dropzone-items d-flex flex-wrap w-100 gap-2"
                                              >
                                                <div
                                                  class="dropzone-item bg-light border border-primary border-dashed w-150px"
                                                  style="display: none"
                                                >
                                                  <!--begin::File-->
                                                  <div
                                                    class="dropzone-file w-100 mt-5"
                                                  >
                                                    <div
                                                      class="image-input"
                                                      data-kt-image-input="true"
                                                    >
                                                      <div
                                                        class="image-input-wrapper"
                                                      >
                                                        <img
                                                          data-dz-thumbnail=""
                                                          class="dz-image"
                                                          alt=""
                                                        />
                                                      </div>
                                                      <!--begin::Edit button-->
                                                      <label
                                                        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow dropzone-delete"
                                                        data-dz-remove
                                                        data-kt-image-input-action="change"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-dismiss="click"
                                                      >
                                                        <i
                                                          class="bi bi-trash-fill fs-7"
                                                        ></i>
                                                      </label>
                                                      <!--end::Edit button-->
                                                    </div>
                                                    <div
                                                      class="dropzone-filename d-flex"
                                                      title="some_image_file_name.jpg"
                                                    >
                                                      <div
                                                        class="w-50 turncating"
                                                      >
                                                        <span data-dz-name
                                                          >some_image_file_name.jpg</span
                                                        >
                                                      </div>
                                                      <strong class="W-50"
                                                        >(<span data-dz-size
                                                          >340kb</span
                                                        >)</strong
                                                      >
                                                    </div>
                                                    <div
                                                      class="dropzone-error"
                                                      data-dz-errormessage
                                                    ></div>
                                                  </div>
                                                  <!--end::File-->

                                                  <!--begin::Progress-->
                                                  <div
                                                    class="dropzone-progress"
                                                  >
                                                    <div class="progress">
                                                      <div
                                                        class="progress-bar bg-primary"
                                                        role="progressbar"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                        aria-valuenow="0"
                                                        data-dz-uploadprogress
                                                      ></div>
                                                    </div>
                                                  </div>
                                                  <!--end::Progress-->

                                                  <!--begin::Toolbar-->
                                                  <div class="dropzone-toolbar">
                                                    <span class="dropzone-start"
                                                      ><i
                                                        class="bi bi-play-fill fs-3"
                                                      ></i
                                                    ></span>
                                                    <span
                                                      class="dropzone-cancel"
                                                      data-dz-remove
                                                      style="display: none"
                                                      ><i
                                                        class="bi bi-x fs-3"
                                                      ></i
                                                    ></span>
                                                  </div>
                                                  <!--end::Toolbar-->
                                                </div>
                                              </div>
                                              <!--end::Items-->
                                            </div>
                                            <!--end::Dropzone-->

                                            <!--begin::Hint-->
                                            <span class="form-text text-muted"
                                              >Max file size is 1MB and max
                                              number of files is 5.</span
                                            >
                                            <!--end::Hint-->
                                          </div>
                                          <!--end::Col-->
                                        </div>
                                        <!--end::Input group-->
                                      </form>
                                      <!--end::Content-->
                                    </div>
                                    <!--end::Wrapper-->
                                  </div>
                                  <!--end::Notice-->
                                  <!--end::Form-->
                                  <div
                                    v-if="
                                      tabCol.required && tabCol.errorCode > 0
                                    "
                                    id="validationErrorFeedback"
                                    class="invalid-feedback d-block"
                                  >
                                    {{
                                      tabCol.validation[tabCol.errorCode - 1]
                                        .error
                                    }}
                                  </div>
                                </div>
                                <div
                                  v-else-if="tabCol.type == 'selectMiniFile'"
                                  class="me-3"
                                >
                                  <!--begin::Upload-->
                                  <div
                                    class="notice d-flex flex-center bg-light-primary rounded border-primary border border-dashed mb-9 p-4 min-w-225px min-h-225px"
                                  >
                                    <!--begin::Wrapper-->
                                    <div class="w-100">
                                      <!--begin::Content-->
                                      <!--begin::Form-->
                                      <form
                                        class="form"
                                        action="#"
                                        method="post"
                                      >
                                        <!--begin::Input group-->
                                        <div class="form-group m-0 col-12">
                                          <!--begin::Col-->
                                          <!--begin::Dropzone-->
                                          <div
                                            class="dropzone dropzone-queue mb-2"
                                            :id="`kt_dropzonejs_example_${tabColNo}`"
                                          >
                                            <!--begin::Controls-->
                                            <div
                                              class="dropzone-panel mb-lg-0 mb-2 col-12"
                                            >
                                              <div
                                                class="dropzone-select btn w-100"
                                              >
                                                <!--begin::Icon-->
                                                <i
                                                  class="bi bi-file-earmark-arrow-up text-primary fs-4x me-2 mb-3"
                                                ></i>
                                                <div class="text-muted fs-3">
                                                  {{ tabCol.title }}
                                                </div>
                                                <div class="text-muted">
                                                  Select to upload files
                                                </div>
                                                <!--end::Icon-->
                                              </div>
                                              <a
                                                class="dropzone-remove-all btn btn-sm btn-light-primary"
                                                >Remove All</a
                                              >
                                            </div>
                                            <!--end::Controls-->

                                            <!--begin::Items-->
                                            <div
                                              class="dropzone-items wm-200px d-flex flex-center flex-wrap scroll-y mh-200px"
                                              style="overflow-x: hidden"
                                            ></div>
                                            <!--end::Items-->
                                          </div>
                                          <!--end::Dropzone-->

                                          <!--end::Col-->
                                        </div>
                                        <!--end::Input group-->
                                      </form>
                                      <!--end::Form-->
                                      <!--end::Content-->
                                    </div>
                                    <!--end::Wrapper-->
                                  </div>
                                  <div>
                                    <input
                                      :ref="tabCol.name"
                                      class="d-none"
                                      :id="tabCol.key"
                                      type="text"
                                      value=""
                                    />
                                  </div>
                                  <!--end::Upload-->
                                  <div
                                    v-if="
                                      tabCol.required && tabCol.errorCode > 0
                                    "
                                    id="validationErrorFeedback"
                                    class="invalid-feedback d-block"
                                  >
                                    <p class="d-flex flex-wrap mw-225px">
                                      {{
                                        tabCol.validation[tabCol.errorCode - 1]
                                          .error
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="currentState == 0"
                    class="pt-5 mb-4 d-flex justify-content-center flex-column flex-xl-row"
                  >
                    <a
                      class="btn btn-primary px-15 m-1"
                      @click="changeState('next')"
                    >
                      Register
                    </a>
                    <!-- <button class="btn btn-light px-15 m-1">
                      Login with Google
                      <span>
                        <img
                          src="/assets/images/svg/brand-logos/google-icon.svg"
                          alt=""
                        />
                      </span>
                    </button> -->
                  </div>
                </div>
              </div>
              <!-- end::Step 0 -->
              <!-- begin::Step 9 -->
              <div v-if="currentState == 9">
                <h3 class="text-center">Invoice preview</h3>
                <h6 class="text-muted text-center fs-6 mb-10">
                  In this section, the preview of your order invoice can be
                  seen.
                </h6>
                <div
                  class="border border-dashed border-primary rounded p-10 mb-10"
                >
                  <div class="d-flex flex-column gap-7">
                    <!--begin::Order details-->
                    <div class="d-flex flex-column flex-sm-row gap-7 fw-bold">
                      <div class="flex-root d-flex flex-column fs-8">
                        <span class="text-muted">Order ID</span>
                        <span class="">#14534</span>
                      </div>
                      <div class="flex-root d-flex flex-column fs-8">
                        <span class="text-muted">Date</span>
                        <span class="">{{ Date() }}</span>
                      </div>
                      <div class="flex-root d-flex flex-column fs-8">
                        <span class="text-muted">Name</span>
                        <span class="fs-5">{{
                          registerInfo["userEmail"]
                        }}</span>
                      </div>
                      <div class="flex-root d-flex flex-column fs-8">
                        <span class="text-muted">Username</span>
                        <span class="fs-5">{{
                          registerInfo["userPhoneNumber"]
                        }}</span>
                      </div>
                    </div>
                    <!--end::Order details-->
                    <!--begin::Info-->
                    <div class="d-flex flex-column flex-sm-row gap-7 fw-bold">
                      <div class="flex-root d-flex flex-column fs-8">
                        <span class="text-muted">Email Address</span>
                        <span class="">{{ data.info.customerEmail }}</span>
                      </div>
                      <div class="flex-root d-flex flex-column fs-8">
                        <span class="text-muted">Phone number</span>
                        <span class="">{{
                          data.info.customerPhoneNumber
                        }}</span>
                      </div>
                    </div>
                    <!--end::Info-->
                    <!--begin:Order summary-->
                    <div class="d-flex justify-content-between flex-column">
                      <!--begin::Table-->
                      <div class="table-responsive border-bottom">
                        <table
                          class="table align-middle table-row-dashed fs-9 mb-0"
                        >
                          <thead>
                            <tr class="border-bottom fs-8 fw-bold text-muted">
                              <th class="min-w-175px pb-1">Services</th>
                              <th class="min-w-70px text-end pb-1">#</th>
                              <th class="min-w-80px text-end pb-1">QTY</th>
                              <th class="min-w-100px text-end pb-1">Total</th>
                            </tr>
                          </thead>
                          <tbody class="fw-semibold text-gray-600">
                            <!--begin::Services-->
                            <tr>
                              <!--begin::Service-->
                              <td>
                                <!--begin::Title-->
                                <div class="">
                                  <div class="fw-bold">Basic</div>
                                  <div class="fs-7 text-muted">
                                    Basic services init
                                  </div>
                                </div>
                                <!--end::Title-->
                              </td>
                              <!--end::Service-->
                              <!--begin::SKU-->
                              <td class="text-end"></td>
                              <!--end::SKU-->
                              <!--begin::Quantity-->
                              <td class="text-end">1</td>
                              <!--end::Quantity-->
                              <!--begin::Total-->
                              <td class="text-end">5,000,000 T</td>
                              <!--end::Total-->
                            </tr>
                            <tr>
                              <!--begin::Service-->
                              <td>
                                <!--begin::Title-->
                                <div class="">
                                  <div class="fw-bold">Domain</div>
                                  <div class="fs-7 text-muted">
                                    Custom domain
                                  </div>
                                </div>
                                <!--end::Title-->
                              </td>
                              <!--end::Service-->
                              <!--begin::SKU-->
                              <td class="text-end"></td>
                              <!--end::SKU-->
                              <!--begin::Quantity-->
                              <td class="text-end">1</td>
                              <!--end::Quantity-->
                              <!--begin::Total-->
                              <td class="text-end">200,000 T</td>
                              <!--end::Total-->
                            </tr>
                            <tr>
                              <!--begin::Service-->
                              <td>
                                <!--begin::Title-->
                                <div class="">
                                  <div class="fw-bold">Email Tepmplate</div>
                                  <div class="fs-7 text-muted">
                                    Free email template
                                  </div>
                                </div>
                                <!--end::Title-->
                              </td>
                              <!--end::Service-->
                              <!--begin::SKU-->
                              <td class="text-end"></td>
                              <!--end::SKU-->
                              <!--begin::Quantity-->
                              <td class="text-end">1</td>
                              <!--end::Quantity-->
                              <!--begin::Total-->
                              <td class="text-end">0 T</td>
                              <!--end::Total-->
                            </tr>
                            <tr>
                              <!--begin::Service-->
                              <td>
                                <!--begin::Title-->
                                <div class="">
                                  <div class="fw-bold">Color theme</div>
                                  <div class="fs-7 text-muted">
                                    Free colot theme
                                  </div>
                                </div>
                                <!--end::Title-->
                              </td>
                              <!--end::Service-->
                              <!--begin::SKU-->
                              <td class="text-end"></td>
                              <!--end::SKU-->
                              <!--begin::Quantity-->
                              <td class="text-end">1</td>
                              <!--end::Quantity-->
                              <!--begin::Total-->
                              <td class="text-end">0 T</td>
                              <!--end::Total-->
                            </tr>
                            <tr>
                              <!--begin::Service-->
                              <td>
                                <!--begin::Title-->
                                <div class="">
                                  <div class="fw-bold">Tepmplate design</div>
                                  <div class="fs-7 text-muted">
                                    Custom site design
                                  </div>
                                </div>
                                <!--end::Title-->
                              </td>
                              <!--end::Service-->
                              <!--begin::SKU-->
                              <td class="text-end"></td>
                              <!--end::SKU-->
                              <!--begin::Quantity-->
                              <td class="text-end">1</td>
                              <!--end::Quantity-->
                              <!--begin::Total-->
                              <td class="text-end">5,000,000 T</td>
                              <!--end::Total-->
                            </tr>
                            <tr>
                              <!--begin::Service-->
                              <td>
                                <!--begin::Title-->
                                <div class="">
                                  <div class="fw-bold">Cloudflare</div>
                                  <div class="fs-7 text-muted">
                                    Cloudflare CDN
                                  </div>
                                </div>
                                <!--end::Title-->
                              </td>
                              <!--end::Service-->
                              <!--begin::SKU-->
                              <td class="text-end"></td>
                              <!--end::SKU-->
                              <!--begin::Quantity-->
                              <td class="text-end">1</td>
                              <!--end::Quantity-->
                              <!--begin::Total-->
                              <td class="text-end">0 T</td>
                              <!--end::Total-->
                            </tr>
                            <!--end::Services-->
                            <!--begin::Subtotal-->
                            <tr>
                              <td colspan="3" class="text-end">Subtotal</td>
                              <td class="text-end">10,200,000 T</td>
                            </tr>
                            <!--end::Subtotal-->
                            <!--begin::Grand total-->
                            <tr>
                              <td
                                colspan="3"
                                class="fs-6 text-dark fw-bold text-end"
                              >
                                Grand Total
                              </td>
                              <td class="text-dark fs-6 fw-bolder text-end">
                                10,200,000 T
                              </td>
                            </tr>
                            <!--end::Grand total-->
                          </tbody>
                        </table>
                      </div>
                      <!--end::Table-->
                    </div>
                    <!--end:Order summary-->
                  </div>
                </div>
              </div>
              <!-- end::Step 9 -->
            </form>
          </div>
          <!-- end::Content -->
        </div>
      </div>
      <div class="card-footer py-2" v-if="currentState > 0">
        <!-- begin::Actions -->
        <div class="d-flex flex-stack w-100">
          <!--begin::Wrapper-->
          <div class="me-2">
            <a
              v-if="currentState > 0"
              class="btn btn-light btn-active-light-primary"
              data-kt-stepper-action="previous"
              @click="changeState('previous')"
            >
              <i class="bi bi-arrow-left-short fs-2 p-0"></i>
              Back
            </a>
          </div>
          <!--end::Wrapper-->
          <div class="mb-5 flex-shrink-1 flex-grow-1 mx-10">
            <div class="d-flex flex-stack mb-1">
              <span class="text-dark">Step {{ currentState }}</span>
              <span class="text-muted">{{ currentState }}0%</span>
            </div>
            <div class="progress h-20px">
              <div
                class="progress-bar progress-bar-striped progress-bar-animated bg-primary"
                role="progressbar"
                :style="`width: ${currentState}0%`"
                :aria-valuenow="`${currentState}0`"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
          <!--begin::Wrapper-->
          <div>
            <a
              v-if="currentState < 9"
              class="btn btn-primary"
              data-kt-stepper-action="next"
              @click="changeState('next')"
            >
              Continue
              <i class="bi bi-arrow-right-short fs-2 p-0"></i>
            </a>
            <a
              v-else
              class="btn btn-primary"
              data-kt-stepper-action="submit"
              @click="submitForm()"
            >
              <span class="indicator-label">Submit</span>
            </a>
          </div>
          <!--end::Wrapper-->
        </div>
        <!-- end::Actions -->
      </div>
    </div>
    <!--begin::SoftBox-->
    <div>
      <FsLightbox :toggler="toggler" :sources="[`${softBoxSource}`]" />
    </div>
    <!--end::SoftBox-->
    <create-update
      v-if="modalDisplay"
      :contents="contents.data.modal"
      :modalInfo="signInfo"
      :enableTimer="verifyTime"
      @close-modal="setModalConfigs"
    ></create-update>
    <message-box
      v-if="dialogDisplay"
      :contents="contents.data.modal"
      :modalInfo="messageInfo"
      @close-modal="setModalConfigs"
    >
    </message-box>
  </div>
  <!--end::Container-->
</template>

<script>
import dataService from "../../../services/data";
import serverConfigs from "../../../configs/server.json";
// import Multiselect from "@vueform/multiselect";
// import { ColorPicker } from "vue3-colorpicker";
// import "vue3-colorpicker/style.css";
import Dropzone from "dropzone";
import FsLightbox from "fslightbox-vue/v3";
import MessageBox from "../../modals/MessageBox.vue";

// Validators
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";
import isHexColor from "validator/lib/isHexColor";
import isFQDN from "validator/lib/isFQDN";
import isStrongPassword from "validator/lib/isStrongPassword";
import isIdentityCard from "validator/lib/isIdentityCard";

// Custom Validators
import isExistUser from "../../../validators/isExistUser";
import isExistDomain from "../../../validators/isExistDomain";

// Modals
import CreateUpdate from "../../modals/CreateUpdate.vue";

import statesIRAN from "../../../configs/states.json";

export default {
  components: {
    // multiselect: Multiselect,
    // ColorPicker,
    FsLightbox,
    CreateUpdate,
    MessageBox,
  },
  setup() {},
  data() {
    return {
      hafezli: {
        userID: null,
        userName: "",
        verificationCode: "",
        userNameErrorClass: "",
        verificationCodeErrorClass: "",
      },
      kardoStep: 0,
      notiferbot: {
        botToken: "",
        userID: null,
        userName: "",
        verificationCode: "",
      },
      saranit: {
        domain: "",
        userID: null,
        userName: "",
        verificationCode: "",
      },
      kardo: {
        id: "",
        vcode: "",
        phoneNumber: "",
        fullName: "",
        idNumber: "",
        state: "",
        city: "",
        address: "",
        acceptPhoto: "",
        userKind: "",
        type: "",
        token: null,
        phoneNumberErrorClass: "",
        vcodeErrorClass: "",
        userKindErrorClass: "",
        fullNameErrorClass: "",
        idNumberErrorClass: "",
        stateErrorClass: "",
        cityErrorClass: "",
        addressErrorClass: "",
        typeErrorClass: "",
        acceptPhotoErrorClass: "",
      },
      displayResgisterBtn: false,
      dialogDisplay: false,
      messageInfo: {},
      signInfo: {},
      modalDisplay: false,
      registerInfo: {},
      currentLocale: "",
      currentRadioTab: 0,
      currentTab: 0,
      info: {},
      toggler: false,
      selectedLanding: 0,
      selectedTheme: 0,
      selectedEmail: 0,
      contents: {
        name: "",
        data: {},
        status: false,
      },
      templates: {
        landing: [],
        email: [],
      },
      themes: [],
      test: false,
      currentState: 0,
      multiplePage: false,
      logoState: "",
      visibility: {},
      items: ["Persian", "English"],
      dropzoneCounter: 0,
      softBoxSource: "",
      verifyCode: "",
      verifyCodeErrorClass: "",
      verifyTime: false,
      // countDown: 5,
      countDown: this.$store.state.resendTime,
      data: {
        info: {
          customerEmail: "",
          customerPhoneNumber: "",
          customerAuthMethod: "",
          customerFullname: "",
          customerUsername: "",
          customerPassword: "",
          customerConfirmPassword: "",
        },
        domain: {
          customerDomain: "",
          subDomainName: "",
          customDomain: "",
          phoneNumber: "",
        },
        logo: {
          images: "",
          phoneNumber: "",
          description: "",
          default: false,
          noDispaly: false,
        },
        language: {
          value: "",
        },
        webTemplate: {
          templateName: "",
          phoneNumber: "",
          description: "",
          services: {
            aboutUs: false,
            blog: false,
            FAQ: false,
            contactUs: false,
            services: false,
          },
        },
        colorTheme: {
          themeName: "",
          phoneNumber: "",
          primaryColor: "",
          description: "",
        },
        eamilTemplate: {
          templateName: "",
          phoneNumber: "",
          description: "",
        },
      },
    };
  },
  computed: {
    backendHost() {
      return serverConfigs["serverDomain"]["host"];
    },
    routePath() {
      return this.$route.path;
    },
    routeLocale() {
      return this.$route.params.locale;
    },
    states() {
      return statesIRAN;
    },
    stateIndex() {
      if (this.kardo.state.length > 0) {
        for (let i = 0; i < this.states.length; i++) {
          if (this.states[i].name == this.kardo.state) {
            return i;
          }
        }
      }
      return -1;
    }
  },
  methods: {
    setModalConfigs(opt, index) {
      this.$store.commit("setLoader", true);

      switch (opt) {
        case "create":
          this.setupSignInfo();
          this.modalDisplay = !this.modalDisplay;
          this.$store.commit("setLoader", false);
          break;
        case "timer":
          // this.resendVerificationCode();
          this.verifyTime = true;
          this.$store.commit("setLoader", false);
          setTimeout(() => {
            this.verifyTime = false;
          }, 100);
          break;
        case "validate":
          if (this.modalInputValidation(index)) {
            this.verificationCode(index);
          } else {
            this.$store.commit("setLoader", false);
          }
          break;
        default:
          this.resetSignInfo();
          this.$store.commit("setLoader", false);

          break;
      }
    },
    setupSignInfo() {
      for (let counter in this.contents.data.modal.fields) {
        switch (this.contents.data.modal.fields[counter].type) {
          case "text":
            this.signInfo[this.contents.data.modal.fields[counter].name] = "";
            break;
          case "textarea":
            this.signInfo[this.contents.data.modal.fields[counter].name] = "";
            break;
          case "file":
            this.signInfo[this.contents.data.modal.fields[counter].name] = [];
            break;
          case "imageInput":
            this.signInfo[this.contents.data.modal.fields[counter].name] = [];
            break;
          default:
            break;
        }
      }
      this.signInfo["status"] = true;
    },
    resetSignInfo() {
      this.signInfo = {};
      this.modalDisplay = false;
      for (let index in this.contents.data.modal.fields) {
        if (this.contents.data.modal.fields[index].required) {
          this.contents.data.modal.fields[index].errorCode = 0;
        }
      }
    },
    modalInputValidation(obj) {
      let errorCount = 0;
      for (
        let index = 0;
        index < this.contents.data.modal.fields.length;
        index++
      ) {
        if (this.contents.data.modal.fields[index].required) {
          for (let expression in this.contents.data.modal.fields[index]
            .validation) {
            switch (
              this.contents.data.modal.fields[index].validation[expression]
                .expression
            ) {
              case "isEmpty":
                this.contents.data.modal.fields[index].errorCode =
                  !obj[this.contents.data.modal.fields[index].name] ||
                  obj[this.contents.data.modal.fields[index].name].length == 0
                    ? this.contents.data.modal.fields[index].validation[
                        expression
                      ].id
                    : 0;
                if (this.contents.data.modal.fields[index].errorCode > 0) {
                  errorCount += 1;
                }
                break;
              case "isSpace":
                this.contents.data.modal.fields[index].errorCode =
                  this.contents.data.modal.fields[index].validation.length >
                    1 && this.contents.data.modal.fields[index].errorCode > 0
                    ? this.contents.data.modal.fields[index].errorCode
                    : String(
                        obj[this.contents.data.modal.fields[index].name]
                      ).trim().length == 0
                    ? this.contents.data.modal.fields[index].validation[
                        expression
                      ].id
                    : 0;
                if (this.contents.data.modal.fields[index].errorCode > 0) {
                  errorCount += 1;
                }
                break;
              default:
                this.contents.data.modal.fields[index].errorCode = 0;
                break;
            }
          }
        }
      }
      return errorCount == 0 ? true : false;
    },
    // verificationCode(obj) {

    // },
    selcetedItems(opt, index) {
      switch (opt.target.name) {
        case "Auth":
          this.data.info.customerAuthMethod = opt.target.value;
          break;
        case "logo":
          this.data.logo.default = false;
          this.data.logo.noDispaly = false;
          this.logoState = "";
          switch (opt.target.value) {
            case "upload":
              this.logoState = "uploadLogo";
              break;
            case "order":
              this.logoState = "orderLogo";
              break;
            case "defaultLogo":
              this.data.logo.default = true;
              break;
            case "noDisplay":
              this.data.logo.noDispaly = true;
              break;
            default:
              break;
          }
          break;
        case "webTemplate":
          this.selectedLanding = index;
          // this.data.webTemplate.templateName = opt.target.value;
          break;
        case "templatePages":
          if (opt.target.value == "multiple") {
            this.multiplePage = true;
          } else {
            this.multiplePage = false;
          }
          break;
        case "colorTheme":
          this.data.colorTheme.themeName = opt.target.value;
          break;
        case "emailTemplate":
          this.selectedEmail = index;
          // this.data.eamilTemplate.templateName = opt.target.value;
          break;
      }
    },
    async changeState(f) {
      switch (f) {
        case "next":
          if (await this.inputValidation(this.registerInfo)) {
            this.currentState < 9 ? (this.currentState += 1) : null;
            if (this.currentState > 0 && this.currentState < 9) {
              this.defaultSetupRegisterInfo(this.currentState);
            }
            if (this.currentState == 3) {
              setTimeout(() => {
                this.dropzoneInput(
                  this,
                  this.contents.data.page.form.steps[3].rows[0][0].tabs[0]
                    .rows[0]
                );
              }, 100);
            }
          }
          break;
        case "previous":
          this.currentState > 0 ? (this.currentState -= 1) : null;
          break;
      }
    },
    passwordVisibilitySwitch(i) {
      this.visibility[i] = !this.visibility[i];
    },
    dropzoneInput(thisElement, thisItems) {
      for (let i in thisItems) {
        // set the dropzone container id
        const id = `#kt_dropzonejs_example_${i}`;
        const dropzone = document.querySelector(id);

        var previewTemplate = `<div
                          class="dropzone-item bg-dark bg-opacity-10 col-12 m-3"
                          style="display: none"
                        >
                          <!--begin::File-->
                          <div
                            class="dropzone-file d-flex flex-column flex-center w-100"
                          >
                            <div
                              class="image-input mt-3"
                              data-kt-image-input="true"
                            >
                              <div
                                class="image-input-wrapper w-100px d-flex flex-center"
                              >
                                <img
                                  data-dz-thumbnail=""
                                  class="dz-image w-100 mh-100"
                                  style="object-fit: cover"
                                  alt=""
                                />
                              </div>
                              <!--begin::Edit button-->
                              <label
                                class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow dropzone-delete"
                                data-dz-remove
                                data-kt-image-input-action="change"
                                data-bs-toggle="tooltip"
                                data-bs-dismiss="click"
                              >
                                <i class="bi bi-trash-fill fs-7"></i>
                              </label>
                              <!--end::Edit button-->
                            </div>
                            <!--begin::Progress-->
                            <div class="dropzone-progress">
                              <div class="progress">
                                <div
                                  class="progress-bar bg-primary"
                                  role="progressbar"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                  aria-valuenow="0"
                                  data-dz-uploadprogress
                                ></div>
                              </div>
                            </div>
                            <!--end::Progress-->
                            <div
                              class="dropzone-error"
                              data-dz-errormessage
                            ></div>
                          </div>
                          <!--end::File-->
                        </div>`;

        var myDropzone = new Dropzone(id, {
          // Make the whole body a dropzone
          // url: `${
          //   serverConfigs["serverDomain"]["host"]
          // }/api/v1/uploads/upload?locale=fa&pid=${sessionStorage.getItem(
          //   "projectUUID"
          // )}`,
          // url: "http://localhost:3077/api/v1/uploads",
          url: "https://def.webplate.info/api/v1/uploads",
          parallelUploads: 20,
          maxFiles:
            !thisElement.mode || thisElement.mode == "single" ? 1 : null,
          maxFilesize: 100, // Max filesize in MB
          previewTemplate: previewTemplate,
          previewsContainer: id + " .dropzone-items", // Define the container to display the previews
          clickable: id + " .dropzone-select", // Define the element that should be used as click trigger to select files.
        });

        myDropzone.on("addedfile", function () {
          // Hookup the start button
          const dropzoneItems = dropzone.querySelectorAll(".dropzone-item");
          dropzoneItems.forEach((dropzoneItem) => {
            dropzoneItem.style.display = "";
          });
          dropzone.querySelector(".dropzone-select").style.display = "none";
        });

        // Update the total progress bar
        myDropzone.on("totaluploadprogress", function (progress) {
          const progressBars = dropzone.querySelectorAll(".progress-bar");
          progressBars.forEach((progressBar) => {
            progressBar.style.width = progress + "%";
          });
        });

        myDropzone.on("sending", function () {
          // Show the total progress bar when upload starts
          const progressBars = dropzone.querySelectorAll(".progress-bar");
          progressBars.forEach((progressBar) => {
            progressBar.style.opacity = "1";
          });
        });

        myDropzone.on("success", function (file) {
          let temp =
            document.getElementById(`${thisItems[i].key}`).value.length > 0
              ? JSON.parse(document.getElementById(`${thisItems[i].key}`).value)
              : [];
          temp.push(JSON.parse(file.xhr.response)[0].filename);
          thisElement.registerInfo[`${thisItems[i].key}`] = temp;
          document.getElementById(`${thisItems[i].key}`).value =
            JSON.stringify(temp);
          if (
            (!thisElement.mode || thisElement.mode == "single") &&
            document.getElementById("kt_modal_new_ticket_form")
          ) {
            document
              .getElementById("kt_modal_new_ticket_form")
              .classList.add("d-none");
          }
        });

        // Hide the total progress bar when nothing"s uploading anymore
        myDropzone.on("complete", function () {
          const progressBars = dropzone.querySelectorAll(".dz-complete");

          setTimeout(function () {
            progressBars.forEach((progressBar) => {
              progressBar.querySelector(".progress-bar").style.opacity = "0";
              progressBar.querySelector(".progress").style.opacity = "0";
            });
          }, 300);
        });
        // On all files removed
        myDropzone.on("removedfile", function () {
          thisElement.registerInfo[`${thisItems[i].key}`] = "";
          if (myDropzone.files.length < 1) {
            dropzone.querySelector(".dropzone-select").style.display =
              "inline-block";
          }
        });

        // Setup the button for remove all files
        dropzone
          .querySelector(".dropzone-remove-all")
          .addEventListener("click", function () {
            myDropzone.removeAllFiles();
          });
      }
    },
    softBox(src, index, folder) {
      this.softBoxSource = "";
      this.toggler = !this.toggler;
      if (index) {
        this.softBoxSource =
          src == "email"
            ? `${this.backendHost}/images/preview/${folder}/${
                this.themes[this.registerInfo.projectTheme].themePreview[src][
                  this.templates["email"][index].templateName
                ]
              }`
            : `${this.backendHost}/images/preview/${this.templates["landing"][index].templatePreview}`;
      } else {
        this.softBoxSource = src
      }
    },
    changeTab(key, index) {
      this.registerInfo[key] = index;
    },
    changeRadioTab(key, index) {
      this.registerInfo[key] = index;
    },
    initialSetupRegisterInfo() {
      for (let step in this.contents.data.page.form.steps) {
        let tempStep = this.contents.data.page.form.steps[step].rows;
        for (let i in tempStep) {
          for (let j in tempStep[i]) {
            switch (tempStep[i][j].type) {
              case "tel":
              case "text":
              case "textGroup":
              case "email":
              case "textarea":
              case "password":
              case "checkbox":
                this.registerInfo[tempStep[i][j].key] = "";
                break;
              case "combox":
                this.registerInfo[tempStep[i][j].key] = "";
                break;
              case "tab":
              case "radioTab":
              case "imgRadio":
              case "mulImgRadio":
                this.registerInfo[tempStep[i][j].key] = 0;
                for (let tab in tempStep[i][j].tabs) {
                  for (let iTab in tempStep[i][j].tabs[tab].rows) {
                    for (let jTab in tempStep[i][j].tabs[tab].rows[i]) {
                      if (
                        tempStep[i][j].tabs[tab].rows[iTab][jTab].type ==
                        "color"
                      ) {
                        this.registerInfo[
                          tempStep[i][j].tabs[tab].rows[iTab][jTab].key
                        ] = "#ffffff";
                      } else {
                        this.registerInfo[
                          tempStep[i][j].tabs[tab].rows[iTab][jTab].key
                        ] =
                          tempStep[i][j].tabs[tab].rows[iTab][jTab].value == 0
                            ? tempStep[i][j].tabs[tab].rows[iTab][jTab].value
                            : "";
                      }
                    }
                  }
                }
                break;
              default:
                break;
            }
          }
        }
      }
    },
    defaultSetupRegisterInfo(step) {
      let tempStep = this.contents.data.page.form.steps[step].rows;
      for (let i in tempStep) {
        for (let j in tempStep[i]) {
          switch (tempStep[i][j].type) {
            case "tel":
            case "text":
            case "textGroup":
            case "email":
            case "textarea":
            case "password":
            case "checkbox":
              if (
                tempStep[i][j].default.rel &&
                tempStep[i][j].default.rel.length > 0
              ) {
                this.registerInfo[tempStep[i][j].key] =
                  this.registerInfo[tempStep[i][j].default.rel];
              }
              break;
            case "combox":
              if (
                tempStep[i][j].default.rel &&
                tempStep[i][j].default.rel.length > 0
              ) {
                this.registerInfo[tempStep[i][j].key] =
                  this.registerInfo[tempStep[i][j].default.rel];
              }
              break;
            case "tab":
            case "radioTab":
            case "imgRadio":
            case "mulImgRadio":
              this.registerInfo[tempStep[i][j].key] = 0;
              for (let tab in tempStep[i][j].tabs) {
                for (let iTab in tempStep[i][j].tabs[tab].rows) {
                  for (let jTab in tempStep[i][j].tabs[tab].rows[i]) {
                    if (
                      tempStep[i][j].tabs[tab].rows[iTab][jTab].default.rel &&
                      tempStep[i][j].tabs[tab].rows[iTab][jTab].default.rel
                        .length > 0
                    ) {
                      this.registerInfo[
                        tempStep[i][j].tabs[tab].rows[iTab][jTab].key
                      ] =
                        this.registerInfo[
                          tempStep[i][j].tabs[tab].rows[iTab][jTab].default.rel
                        ];
                    }
                  }
                }
              }
              break;
            default:
              break;
          }
        }
      }
    },
    async inputValidation(obj) {
      let errorCount = 0;
      let tempItem = {};
      let tempStep = this.contents.data.page.form.steps[this.currentState].rows;
      for (let i in tempStep) {
        for (let j in tempStep[i]) {
          if (
            tempStep[i][j].type == "tab" ||
            tempStep[i][j].type == "radioTab" ||
            tempStep[i][j].type == "imgRadio" ||
            tempStep[i][j].type == "mulImgRadio"
          ) {
            tempItem =
              tempStep[i][j].tabs[this.registerInfo[tempStep[i][j].key]].rows;
          } else {
            tempItem = tempStep;
          }
        }
      }
      for (let i in tempItem) {
        for (let j in tempItem[i]) {
          if (tempItem[i][j].required) {
            for (let v in tempItem[i][j].validation) {
              switch (tempItem[i][j].validation[v].expression) {
                case "isEmpty":
                  tempItem[i][j].errorCode =
                    !obj[tempItem[i][j].key] ||
                    obj[tempItem[i][j].key].length == 0
                      ? tempItem[i][j].validation[v].id
                      : 0;
                  if (tempItem[i][j].errorCode > 0) {
                    errorCount += 1;
                  }
                  tempItem[i][j].type == "selectMiniFile"
                    ? null
                    : this.goto(tempItem[i][j].name);
                  break;
                case "isSpace":
                  tempItem[i][j].errorCode =
                    tempItem[i][j].validation.length > 1 &&
                    tempItem[i][j].errorCode > 0
                      ? tempItem[i][j].errorCode
                      : String(obj[tempItem[i][j].name]).trim().length == 0
                      ? tempItem[i][j].validation[v].id
                      : 0;
                  if (tempItem[i][j].errorCode > 0) {
                    errorCount += 1;
                  }
                  this.goto(tempItem[i][j].name);
                  break;
                case "isEmail":
                  tempItem[i][j].errorCode =
                    tempItem[i][j].validation.length > 1 &&
                    tempItem[i][j].errorCode > 0
                      ? tempItem[i][j].errorCode
                      : !isEmail(obj[tempItem[i][j].key])
                      ? tempItem[i][j].validation[v].id
                      : 0;
                  if (tempItem[i][j].errorCode > 0) {
                    errorCount += 1;
                  }
                  this.goto(tempItem[i][j].name);
                  break;
                case "isPhoneNumber":
                  tempItem[i][j].errorCode =
                    tempItem[i][j].validation.length > 1 &&
                    tempItem[i][j].errorCode > 0
                      ? tempItem[i][j].errorCode
                      : !isMobilePhone(obj[tempItem[i][j].key], ["fa-IR"])
                      ? tempItem[i][j].validation[v].id
                      : 0;
                  if (tempItem[i][j].errorCode > 0) {
                    errorCount += 1;
                  }
                  this.goto(tempItem[i][j].name);
                  break;
                case "isStrongPassword":
                  tempItem[i][j].errorCode =
                    tempItem[i][j].validation.length > 1 &&
                    tempItem[i][j].errorCode > 0
                      ? tempItem[i][j].errorCode
                      : !isStrongPassword(obj[tempItem[i][j].key])
                      ? tempItem[i][j].validation[v].id
                      : 0;
                  if (tempItem[i][j].errorCode > 0) {
                    errorCount += 1;
                  }
                  this.goto(tempItem[i][j].name);
                  break;
                case "isHexColor":
                  tempItem[i][j].errorCode =
                    tempItem[i][j].validation.length > 1 &&
                    tempItem[i][j].errorCode > 0
                      ? tempItem[i][j].errorCode
                      : !isHexColor(obj[tempItem[i][j].key], ["fa-IR"])
                      ? tempItem[i][j].validation[v].id
                      : 0;
                  if (tempItem[i][j].errorCode > 0) {
                    errorCount += 1;
                  }
                  this.goto(tempItem[i][j].name);
                  break;
                case "isEqual":
                  tempItem[i][j].errorCode =
                    tempItem[i][j].validation.length > 1 &&
                    tempItem[i][j].errorCode > 0
                      ? tempItem[i][j].errorCode
                      : !(
                          obj[tempItem[i][j].key] ==
                          obj[tempItem[i][j].validation[v].rel]
                        )
                      ? tempItem[i][j].validation[v].id
                      : 0;
                  if (tempItem[i][j].errorCode > 0) {
                    errorCount += 1;
                  }
                  this.goto(tempItem[i][j].name);
                  break;
                case "isFQDN":
                  tempItem[i][j].errorCode =
                    tempItem[i][j].validation.length > 1 &&
                    tempItem[i][j].errorCode > 0
                      ? tempItem[i][j].errorCode
                      : !isFQDN(obj[tempItem[i][j].key])
                      ? tempItem[i][j].validation[v].id
                      : 0;
                  if (tempItem[i][j].errorCode > 0) {
                    errorCount += 1;
                  }
                  this.goto(tempItem[i][j].name);
                  break;
                case "isExistUser":
                  tempItem[i][j].errorCode =
                    tempItem[i][j].validation.length > 1 &&
                    tempItem[i][j].errorCode > 0
                      ? tempItem[i][j].errorCode
                      : !(await isExistUser(
                          obj[tempItem[i][j].key],
                          this.routeLocale
                        ))
                      ? tempItem[i][j].validation[v].id
                      : 0;
                  if (tempItem[i][j].errorCode > 0) {
                    errorCount += 1;
                  }
                  break;
                case "isExistDomain":
                  tempItem[i][j].errorCode =
                    tempItem[i][j].validation.length > 1 &&
                    tempItem[i][j].errorCode > 0
                      ? tempItem[i][j].errorCode
                      : !(await isExistDomain(
                          obj[tempItem[i][j].key],
                          this.routeLocale
                        ))
                      ? tempItem[i][j].validation[v].id
                      : 0;
                  if (tempItem[i][j].errorCode > 0) {
                    errorCount += 1;
                  }
                  break;
                default:
                  tempItem[i][j].errorCode = 0;
                  break;
              }
            }
          }
        }
      }
      return errorCount == 0 ? true : false;
    },
    setupLanguagesOptions() {
      let tempOptions =
        this.contents.data.page.form.steps[4].rows[0][0].options;
      for (let l in this.$store.state.languages.value) {
        tempOptions.push(this.$store.state.languages.value[l]);
      }
      // this.currentLocale = this.routeLocale;
    },
    getContents() {
      let data = {
        endPoint: `contents/r/${this.routePath.substring(4)}`,
        locale: `${this.routeLocale}`,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          this.contents = {
            name: res.data.data[0].contentName,
            data: res.data.data[0].contentJson,
            status: true,
          };
          this.setupLanguagesOptions();
          this.initialSetupRegisterInfo();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        });
    },
    submitForm() {
      // this.registerInfo = {
      //   defaultLanguage: "en",
      //   domainMethod: 1,
      //   domainPhoneNumber: "09358718598",
      //   emailDescription: "",
      //   emailMethod: 0,
      //   emailPhoneNumber: "09358718598",
      //   logoDescription: "",
      //   logoMethod: 2,
      //   logoPhoneNumber: "09358718598",
      //   projectDomain: "",
      //   projectEmail: "email3",
      //   projectMainLogo: ['1667392378353_default_avatar.png'][0],
      //   projectMainLogoLight: ['1667392378754_default_avatar.png'][0],
      //   projectMobileLogo: ['1667392380853_default_avatar.png'][0],
      //   projectMobileLogoLight: ['1667392384021_default_avatar.png'][0],
      //   projectOrderDomain: "",
      //   projectSubDomain: "brox",
      //   projectTemplate: "landing2",
      //   projectTheme: 5,
      //   templateDescription: "",
      //   templateMethod: 0,
      //   templatePageType: "",
      //   templatePages: "",
      //   templatePhoneNumber: "09358718597",
      //   termsConfirm: true,
      //   themeDescription: "",
      //   themeMethod: 0,
      //   themePhoneNumber: "09358718598",
      //   themePrimaryColor: "#ffffff",
      //   themeSecondaryColor: "#ffffff",
      //   userConfirmPassword: "Test1234",
      //   userEmail: "brox@gmail.com",
      //   userFullName: "behzad roohparvar",
      //   userPassword: "Test1234",
      //   userPhoneNumber: "09358718598",
      //   userUserName: "brox@gmail.com",
      // };

      let data = {
        endPoint: "projects",
        locale: `${this.routeLocale}`,
        data: this.registerInfo,
      };
      // let endPoint = "https://def.webplate.info/api"
      let endPoint = "http://localhost:3077/api";
      this.$store.commit("setLoader", true);

      dataService
        .post(data, endPoint)
        .then((res) => {
          this.dialogDisplay = true;
          this.messageInfo = res.data.data;
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
        });
    },
    readThemes() {
      let data = {
        endPoint: `themes`,
        // locale: `${this.routeLocale}`,
        locale: "en",
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          this.themes = res.data.data;
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          // this.loaderStatus[0] = true;
          // this.loaderDone();
        });
    },
    readTemplates() {
      let data = {
        endPoint: `templates`,
        // locale: `${this.routeLocale}`,
        locale: "en",
        token: this.$store.state.token,
        data: {},
        // query: {
        //   category: "",
        // },
      };
      // data.query = JSON.stringify(data.query)
      //   .replace("{", "")
      //   .replace(",", "&")
      //   .replace("}", "")
      //   .replace(/":"/g, "=")
      //   .replace(/"/g, "");
      dataService
        .get(data)
        .then((res) => {
          let tempLanding = [];
          let tempEmail = [];
          for (let t in res.data.data) {
            if (res.data.data[t].templateName.includes("landing")) {
              tempLanding.push(res.data.data[t]);
            } else if (res.data.data[t].templateName.includes("email")) {
              tempEmail.push(res.data.data[t]);
            }
          }
          this.templates = {
            landing: tempLanding,
            email: tempEmail,
          };
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          // this.loaderStatus[1] = true;
          // this.loaderDone();
        });
    },
    goto(refName) {
      this.$refs[refName][0].scrollIntoView({ behavior: "smooth" });
    },
    VerificationCode() {
      if (this.inputValidation()) {
        let data = {
          endPoint: "users/cc",
          locale: `${this.routeLocale}`,
          data: {
            userName: this.userName,
            verificationCode: this.verifyCode,
          },
        };
        dataService
          .post(data)
          .then((res) => {
            if (res.data.success) {
              console.log(res.data);
            }
            this.$notify({
              type: res.data.type,
              title: res.data.type,
              text: res.data.message,
            });
          })
          .catch((error) => {
            this.$notify({
              type: error.response.data.type,
              title: error.response.data.type,
              text: error.response.data.message,
            });
          });
      }
    },
    resendVerificationCode() {
      let data = {
        endPoint: "users/vc",
        locale: `${this.routeLocale}`,
        uuid: this.$store.state.projectUUID,
        data: {
          userName: this.saranit.userName.length > 0 ? this.saranit.userName : this.hafezli.userName.length > 0 ? this.hafezli.userName : this.notiferbot.userName,
        },
      };
      dataService
        .post(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        });
    },
    countDownTimer() {
      if (this.countDown == 0) {
        this.verifyTimerSwitch();
      } else {
        if (this.countDown > 0) {
          setTimeout(() => {
            this.countDown -= 1;
            this.countDownTimer();
          }, 1000);
        }
      }
    },
    verifyTimerSwitch() {
      this.verifyTime = !this.verifyTime;
    },
    createUsersAtWebsIRAN() {
      let data = {
        endPoint: "projects/users",
        locale: `${this.routeLocale}`,
        uuid: this.$store.state.projectUUID,
        token: "56a7927fce1a4296b326ad20b73277e4",
        data: {
          userName: this.saranit.userName,
        },
      };
      dataService
        .post(data)
        .then((res) => {
          console.log(res.data.data[0]);
          this.displayResgisterBtn = true;
          this.saranit.userID = res.data.data.id;
          this.$store.commit("setToken", res.data.data.token);

          this.verifyTime = true;
          this.countDown = this.$store.state.resendTime;
          this.countDownTimer();

          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        });
    },
    resendCode() {
      if (
        this.saranit.userName.length > 0 &&
        (isEmail(this.saranit.userName) ||
          isMobilePhone(this.saranit.userName, ["fa-IR"]))
      ) {
        if (this.saranit.userID) {
          this.resendVerificationCode();
        } else {
          this.createUsersAtWebsIRAN();
        }
      } else {
        alert("لطفا شماره تلفن همراه یا ایمیل معتبر وارد نمایید.");
      }
    },
    registerAtWebsIRAN() {
      let data = {
        endPoint: "projects",
        locale: `${this.routeLocale}`,
        uuid: this.$store.state.projectUUID,
        token: "56a7927fce1a4296b326ad20b73277e4",
        data: {
          userName: this.saranit.userName,
          domain: this.saranit.domain,
          vCode: this.saranit.verificationCode,
        },
      };
      dataService
        .post(data)
        .then((res) => {
          this.$store.commit("setProjectUUID", res.data.uuid);
          sessionStorage.setItem("projectUUID", res.data.uuid);
          alert(res.data.message);
          window.location = "/fa/profile"
          // this.$notify({
          //   type: res.data.type,
          //   title: res.data.type,
          //   text: res.data.message,
          // });
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        });
    },
    previousStep() {
      this.kardoStep -= 1;
    },
    nextStep() {
      if (this.kardoStep == 2) {
          if (this.kardo.userKind.length > 0 &&
            this.kardo.fullName.length > 0 &&
            this.kardo.idNumber.length > 0 &&
            (this.kardo.userKind == "company" || this.kardo.userKind == "person" && isIdentityCard(this.kardo.idNumber, ["IR"])) &&
            this.kardo.state.length > 0 &&
            // this.kardo.city.length > 0 &&
            this.kardo.address.length > 0 &&
            this.kardo.type.length > 0) {
            this.kardoStep += 1;
        } else {
          if (this.kardo.userKind.length == 0) {
            this.kardo.userKindErrorClass = "wrong-input"
          } else {
            this.kardo.userKindErrorClass = ""
          }

          if (this.kardo.fullName.length == 0) {
            this.kardo.fullNameErrorClass = "wrong-input"
          } else {
            this.kardo.fullNameErrorClass = ""
          }

          if (this.kardo.idNumber.length == 0 || (this.kardo.userKind.length > 0 && this.kardo.userKind == "person" && !isIdentityCard(this.kardo.idNumber, ["IR"]))) {
            this.kardo.idNumberErrorClass = "wrong-input"
          } else {
            this.kardo.idNumberErrorClass = ""
          }

          if (this.kardo.state.length == 0) {
            this.kardo.stateErrorClass = "wrong-input"
          } else {
            this.kardo.stateErrorClass = ""
          }

          if (this.kardo.city.length == 0) {
            this.kardo.cityErrorClass = "wrong-input"
          } else {
            this.kardo.cityErrorClass = ""
          }

          if (this.kardo.address.length == 0) {
            this.kardo.addressErrorClass = "wrong-input"
          } else {
            this.kardo.addressErrorClass = ""
          }

          if (this.kardo.type.length == 0) {
            this.kardo.typeErrorClass = "wrong-input"
          } else {
            this.kardo.typeErrorClass = ""
          }
        }
      }
      if (this.kardoStep == 3) {
        setTimeout(() => {
          this.initDropzone(this)
        }, 1000)
      }
    },
    setRadioGroup(name, value) {
      this.kardo[name] = value;
    },
    userRegistration() {
      if (this.kardo.acceptPhoto.length > 0) {
        console.log(this.kardo)
        let data = {
          endPoint: `profiles/u/${this.kardo.id}`,
          locale: `${this.routeLocale}`,
          token: this.kardo.token,
          uuid: this.$store.state.projectUUID,
          data: {
            fullName: this.kardo.fullName,
            photo: "",
            extra: {
              acceptPhoto: this.kardo.acceptPhoto,
              nationalID: this.kardo.idNumber,
              address: `${this.kardo.state} - ${this.kardo.city} - ${this.kardo.state} - ${this.kardo.address}`,
              personType: this.kardo.userKind,
              role: this.kardo.type == "saler" ? 42 : 43
            },
            status: 1,
          },
        };
        dataService
          .post(data)
          .then((res) => {
            this.$notify({
              type: res.data.type,
              title: res.data.type,
              text: res.data.message,
            });
            this.kardo.acceptPhotoErrorClass = ""
            setTimeout(() => {
              // window.location = `https://panel.websiran.com/fa/login?pid=57174120b8ad4b6ab36b8b362e3fb11d`
              window.location = `/${this.routeLocale}/profile`
            }, 3000)
          })
          .catch((error) => {
            this.$notify({
              type: error.response.data.type,
              title: error.response.data.type,
              text: error.response.data.message,
            });
            this.kardo.acceptPhotoErrorClass = "wrong-input"
          })
          .finally(() => {
            this.$store.commit("setLoader", false);
          });
      } else {
        this.kardo.acceptPhotoErrorClass = "wrong-input text-danger"
      }
    },
    userVerification() {
      if (this.kardo.vcode > 0) {
        let data = {
          endPoint: `users/cc`,
          locale: `${this.routeLocale}`,
          // token: this.$store.state.token,
          uuid: this.$store.state.projectUUID,
          data: {
            verificationCode: this.kardo.vcode,
            userName: this.kardo.phoneNumber,
          }
        };
        dataService
          .post(data)
          .then((res) => {
            this.$notify({
              type: res.data.type,
              title: res.data.type,
              text: res.data.message,
            });

            this.kardo.vcodeErrorClass = ""

            this.kardoStep += 1
          })
          .catch((error) => {
            this.$notify({
              type: error.response.data.type,
              title: error.response.data.type,
              text: error.response.data.message,
            });
            this.kardo.vcodeErrorClass = "wrong-input"
          })
          .finally(() => {
            this.$store.commit("setLoader", false);

          });
      } else {
        this.kardo.vcodeErrorClass = "wrong-input"
      }
    },
    userSignUp() {
      if (this.kardo.phoneNumber.length > 0 && isMobilePhone(this.kardo.phoneNumber, ["fa-IR"])) {
        let data = {
          endPoint: `users`,
          locale: `${this.routeLocale}`,
          // token: this.$store.state.token,
          uuid: this.$store.state.projectUUID,
          data: {
            role: 34,
            phoneNumber: this.kardo.phoneNumber,
            active: "inActive",
          }
        };
        dataService
          .post(data)
          .then((res) => {
            this.$notify({
              type: res.data.type,
              title: res.data.type,
              text: res.data.message,
            });
            this.kardo.id = res.data.data.id
            this.kardo.token = res.data.data.token

            this.kardo.phoneNumberErrorClass = ""

            this.kardoStep += 1
          })
          .catch((error) => {
            this.$notify({
              type: error.response.data.type,
              title: error.response.data.type,
              text: error.response.data.message,
            });
            this.kardo.phoneNumberErrorClass = "wrong-input"
          })
          .finally(() => {
            this.$store.commit("setLoader", false);
          });
      } else {
        this.kardo.phoneNumberErrorClass = "wrong-input"
      }
    },
    sendResendCode() {
      let data = {
        endPoint: `users/vc`,
        locale: `${this.routeLocale}`,
        // token: this.$store.state.token,
        uuid: this.$store.state.projectUUID,
        data: {
          userName: this.kardo.phoneNumber,
        }
      };
      dataService
        .post(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.kardo.id = res.data.data[0]
          this.kardoStep += 1
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
        });
    },
    initDropzone(el) {
      // set the dropzone container id
      const id = `#kt_dropzonejs_example_0`;
      const dropzone = document.querySelector(id);

      var previewTemplate = `<div
                          class="dropzone-item bg-dark bg-opacity-10 col-12 m-3"
                          style="display: none"
                        >
                          <!--begin::File-->
                          <div
                            class="dropzone-file d-flex flex-column flex-center w-100"
                          >
                            <div
                              class="image-input mt-3"
                              data-kt-image-input="true"
                            >
                              <div
                                class="image-input-wrapper w-100px d-flex flex-center"
                              >
                                <img
                                  data-dz-thumbnail=""
                                  class="dz-image w-100 mh-100"
                                  style="object-fit: cover"
                                  alt=""
                                />
                              </div>
                              <!--begin::Edit button-->
                              <label
                                class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow dropzone-delete"
                                data-dz-remove
                                data-kt-image-input-action="change"
                                data-bs-toggle="tooltip"
                                data-bs-dismiss="click"
                              >
                                <i class="bi bi-trash-fill fs-7"></i>
                              </label>
                              <!--end::Edit button-->
                            </div>
                            <!--begin::Progress-->
                            <div class="dropzone-progress">
                              <div class="progress">
                                <div
                                  class="progress-bar bg-primary"
                                  role="progressbar"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                  aria-valuenow="0"
                                  data-dz-uploadprogress
                                ></div>
                              </div>
                            </div>
                            <!--end::Progress-->
                            <div
                              class="dropzone-error"
                              data-dz-errormessage
                            ></div>
                          </div>
                          <!--end::File-->
                        </div>`;

      var myDropzone = new Dropzone(id, {
        // Make the whole body a dropzone
        // url: `${
        //   serverConfigs["serverDomain"]["host"]
        // }/api/v1/uploads/upload?locale=fa&pid=${sessionStorage.getItem(
        //   "projectUUID"
        // )}`,
        // url: "http://localhost:3077/api/v1/uploads",
        // url: `https://websiran.com/api/v1/uploads/upload?locale=fa&pid=${this.$store.state.projectUUID}`,
        url: `http://10.0.3.245:3001/api/v1/uploads/upload?locale=fa&pid=${this.$store.state.projectUUID}`,
        parallelUploads: 20,
        maxFiles: 1,
        maxFilesize: 100, // Max filesize in MB
        previewTemplate: previewTemplate,
        previewsContainer: id + " .dropzone-items", // Define the container to display the previews
        clickable: id + " .dropzone-select", // Define the element that should be used as click trigger to select files.
      });

      myDropzone.on("addedfile", function () {
        // Hookup the start button
        const dropzoneItems = dropzone.querySelectorAll(".dropzone-item");
        dropzoneItems.forEach((dropzoneItem) => {
          dropzoneItem.style.display = "";
        });
        dropzone.querySelector(".dropzone-select").style.display = "none";
      });

      // Update the total progress bar
      myDropzone.on("totaluploadprogress", function (progress) {
        const progressBars = dropzone.querySelectorAll(".progress-bar");
        progressBars.forEach((progressBar) => {
          progressBar.style.width = progress + "%";
        });
      });

      myDropzone.on("sending", function () {
        // Show the total progress bar when upload starts
        const progressBars = dropzone.querySelectorAll(".progress-bar");
        progressBars.forEach((progressBar) => {
          progressBar.style.opacity = "1";
        });
      });

      myDropzone.on("success", function (file) {
        el.kardo.acceptPhoto = JSON.parse(file.xhr.response)[0].filename
        console.log(JSON.parse(file.xhr.response)[0].filename)
        // let temp =
        //   document.getElementById(`${thisItems[i].key}`).value.length > 0
        //     ? JSON.parse(document.getElementById(`${thisItems[i].key}`).value)
        //     : [];
        // temp.push(JSON.parse(file.xhr.response)[0].filename);
        // thisElement.registerInfo[`${thisItems[i].key}`] = temp;
        // document.getElementById(`${thisItems[i].key}`).value =
        //   JSON.stringify(temp);
        // if (
        //   (!thisElement.mode || thisElement.mode == "single") &&
        //   document.getElementById("kt_modal_new_ticket_form")
        // ) {
        //   document
        //     .getElementById("kt_modal_new_ticket_form")
        //     .classList.add("d-none");
        // }
      });

      // Hide the total progress bar when nothing"s uploading anymore
      myDropzone.on("complete", function () {
        const progressBars = dropzone.querySelectorAll(".dz-complete");

        setTimeout(function () {
          progressBars.forEach((progressBar) => {
            progressBar.querySelector(".progress-bar").style.opacity = "0";
            progressBar.querySelector(".progress").style.opacity = "0";
          });
        }, 300);
      });
      // On all files removed
      myDropzone.on("removedfile", function () {
        console.log("Here")
        // thisElement.registerInfo[`${thisItems[i].key}`] = "";
        // if (myDropzone.files.length < 1) {
        //   dropzone.querySelector(".dropzone-select").style.display =
        //     "inline-block";
        // }
      });

      // Setup the button for remove all files
      dropzone
        .querySelector(".dropzone-remove-all")
        .addEventListener("click", function () {
          myDropzone.removeAllFiles();
        });
    },
    resendCodeHafezLi() {
      if (
        this.hafezli.userName.length > 0 &&
        (isEmail(this.hafezli.userName) ||
          isMobilePhone(this.hafezli.userName, ["fa-IR"]))
      ) {
        if (this.hafezli.userID) {
          this.resendVerificationCode();
        } else {
          this.userSignUpAtHafezLi();
        }
      } else {
        alert("لطفا شماره تلفن همراه یا ایمیل معتبر وارد نمایید.");
      }
    },
    userSignUpAtHafezLi() {
      let data = {
        endPoint: `users`,
        locale: `${this.routeLocale}`,
        uuid: this.$store.state.projectUUID,
        data: {
          userName: this.hafezli.userName,
        }
      };
      dataService
        .post(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });

          this.displayResgisterBtn = true;
          this.hafezli.userID = res.data.data.id;
          this.$store.commit("setToken", res.data.data.token);

          this.verifyTime = true;
          this.countDown = this.$store.state.resendTime;
          this.countDownTimer();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
        });
    },
    userVerificationHafezLi() {
      if (this.hafezli.verificationCode.length > 0) {
        let data = {
          endPoint: `users/cc`,
          locale: `${this.routeLocale}`,
          // token: this.$store.state.token,
          uuid: this.$store.state.projectUUID,
          data: {
            verificationCode: this.hafezli.verificationCode,
            userName: this.hafezli.userName,
          }
        };
        dataService
          .post(data)
          .then((res) => {
            this.$notify({
              type: res.data.type,
              title: res.data.type,
              text: res.data.message,
            });

            this.hafezli.verificationCodeErrorClass = ""

            window.location = `/${this.routeLocale}/profile`
          })
          .catch((error) => {
            this.$notify({
              type: error.response.data.type,
              title: error.response.data.type,
              text: error.response.data.message,
            });
            this.hafezli.verificationCodeErrorClass = "wrong-input"
          })
          .finally(() => {
            this.$store.commit("setLoader", false);

          });
      } else {
        this.hafezli.verificationCodeErrorClass = "wrong-input"
      }
    },
    resendCodeNotiferBot() {
      if (
        this.notiferbot.userName.length > 0 &&
        (isEmail(this.notiferbot.userName) ||
          isMobilePhone(this.notiferbot.userName, ["fa-IR"]))
      ) {
        if (this.notiferbot.userID) {
          this.resendVerificationCode();
        } else {
          this.userSignUpAtNotiferBot();
        }
      } else {
        alert("لطفا شماره تلفن همراه یا ایمیل معتبر وارد نمایید.");
      }
    },
    userSignUpAtNotiferBot() {
      let data = {
        endPoint: `users`,
        locale: `${this.routeLocale}`,
        uuid: this.$store.state.projectUUID,
        data: {
          extra: {
            botToken: this.notiferbot.botToken
          },
          userName: this.notiferbot.userName,
        }
      };
      dataService
        .post(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });

          this.displayResgisterBtn = true;
          this.hafezli.userID = res.data.data.id;
          this.$store.commit("setToken", res.data.data.token);

          this.verifyTime = true;
          this.countDown = this.$store.state.resendTime;
          this.countDownTimer();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
        });
    },
    userVerificationNotiferBot() {
      if (this.notiferbot.verificationCode.length > 0) {
        let data = {
          endPoint: `users/cc`,
          locale: `${this.routeLocale}`,
          // token: this.$store.state.token,
          uuid: this.$store.state.projectUUID,
          data: {
            verificationCode: this.notiferbot.verificationCode,
            userName: this.notiferbot.userName,
          }
        };
        dataService
          .post(data)
          .then((res) => {
            this.$notify({
              type: res.data.type,
              title: res.data.type,
              text: res.data.message,
            });

            window.location = `/${this.routeLocale}/profile`
          })
          .catch((error) => {
            this.$notify({
              type: error.response.data.type,
              title: error.response.data.type,
              text: error.response.data.message,
            });
          })
          .finally(() => {
            this.$store.commit("setLoader", false);

          });
      } else {
        // this.notiferbot.verificationCodeErrorClass = "wrong-input"
        console.log("")
      }
    },
  },
  mounted() {
    this.getContents();
    // this.readThemes();
    // this.readTemplates();
  },
};
</script>

<style>
.wizard_register .fslightbox-source {
  width: 90% !important;
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
}
.wizard_register .fslightbox-container {
  overflow: scroll !important;
}
.wizard_register .fslightbox-flex-centered {
  align-items: start !important;
}
.wizard_register .fslightbox-toolbar-button.fslightbox-flex-centered {
  align-items: center !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-webkit-input-placeholder {
  text-align: right;
}

.wrong-input {
  border-color: red !important;
  color: red !important;
}
</style>
