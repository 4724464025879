import { Store } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default new Store({
  state() {
    return {
      projectUUID: "",
      host: "",
      token: "",
      theme: "",
      themeFile: "",
      languages: Object,
      routes: Object,
      sidebar: Object,
      navbar: Object,
      logos: Object,
      loader: Boolean,
      landing: Object,
      profile: Object,
      newNotification: Boolean,
      template: "",
      uploadsPath: "",
      pages: "",
      minFileSize: 0,
      maxFileSize: 1,
      resendTime: 0,
      shopType: "",
      tempPages: "",
      curProduct: Object
    };
  },
  plugins: [createPersistedState()],
  mutations: {
    setProjectUUID(state, projectUUID) {
      state.projectUUID = projectUUID;
    },
    setHost(state, host) {
      state.host = host;
    },
    setToken(state, token) {
      state.token = token;
    },
    setTheme(state, theme) {
      state.theme = theme;
    },
    setThemeFile(state, themeFile) {
      state.themeFile = themeFile;
    },
    setLanguages(state, languages) {
      state.languages = languages;
    },
    setRoutes(state, routes) {
      state.routes = routes;
    },
    setSidebar(state, routes) {
      state.sidebar = routes;
    },
    setNavbar(state, routes) {
      state.navbar = routes;
    },
    setLogos(state, logos) {
      state.logos = logos;
    },
    setLoader(state, loader) {
      state.loader = loader;
    },
    setNewNotification(state, newNotification) {
      state.newNotification = newNotification;
    },
    setLanding(state, landing) {
      state.landing = landing;
    },
    setPages(state, pages) {
      state.pages = pages;
    },
    setProfile(state, profile) {
      state.profile = profile;
    },
    setTemplate(state, template) {
      state.template = template;
    },
    setUploadsPath(state, uploadsPath) {
      state.uploadsPath = uploadsPath;
    },
    setMinFileSize(state, size) {
      state.minFileSize = size;
    },
    setMaxFileSize(state, size) {
      state.maxFileSize = size;
    },
    setResendTime(state, time) {
      state.resendTime = time;
    },
    setShopType(state, type) {
      state.shopType = type;
    },
    setTempPages(state, pages) {
      state.tempPages = pages;
    },
    setCurProduct(state, product) {
      state.curProduct = product;
    },
  },
});
