<template>
  <div
    class="d-flex justify-content-center align-items-center verification__content"
  >
    <div
      v-if="contents.status"
      class="card shadow col-11 col-md-10 col-lg-6 d-flex justify-content-center align-items-center my-4 my-lg-0"
    >
      <h3 class="fw-bold mt-5">{{ contents.data.title }}</h3>
      <p class="text-muted">{{ contents.data.subTitle }}</p>
      <p class="text-dark fw-bold mb-4">{{ userName }}</p>

      <div
        @keydown.enter="verificationCode"
        class="col-10 col-md-9 col-lg-7 mt-2"
      >
        <div class="mb-5">
          <label class="form-label">{{ contents.data.fields[0].title }}</label>
          <div class="d-flex justify-content-between mb-3">
            <input
              ref="testFocus"
              @keyup="codePattern(1)"
              type="number"
              name="code_1"
              maxlength="1"
              class="form-control text-center code__input__pattern col rounded fs-1 mx-1 my-2"
              value=""
              :class="verifyCodeErrorClass"
            />
            <input
              @keyup="codePattern(2)"
              type="number"
              name="code_2"
              maxlength="1"
              class="form-control text-center code__input__pattern col rounded fs-1 mx-1 my-2"
              value=""
              :class="verifyCodeErrorClass"
            />
            <input
              @keyup="codePattern(3)"
              type="number"
              name="code_3"
              maxlength="1"
              class="form-control text-center code__input__pattern col rounded fs-1 mx-1 my-2"
              value=""
              :class="verifyCodeErrorClass"
            />
            <input
              @keyup="codePattern(4)"
              type="number"
              name="code_4"
              maxlength="1"
              class="form-control text-center code__input__pattern col rounded fs-1 mx-1 my-2"
              value=""
              :class="verifyCodeErrorClass"
            />
            <input
              @keyup="codePattern(5)"
              type="number"
              name="code_5"
              maxlength="1"
              class="form-control text-center code__input__pattern col rounded fs-1 mx-1 my-2"
              value=""
              :class="verifyCodeErrorClass"
            />
            <input
              @keyup="codePattern(6)"
              type="number"
              name="code_6"
              maxlength="1"
              class="form-control text-center code__input__pattern col rounded fs-1 mx-1 my-2"
              value=""
              :class="verifyCodeErrorClass"
            />
          </div>
          <!-- <input
            ref="testFocus"
            maxlength="6"
            v-model="verifyCode"
            type="text"
            class="text-center form-control form-control-lg border-0 __bg__light mb-2"
            aria-describedby="validationErrorFeedback"
            :placeholder="contents.data.fields[0].placeholder"
            :class="verifyCodeErrorClass"
          /> -->
          <div id="validationErrorFeedback" class="invalid-feedback">
            {{ contents.data.fields[0].error }}
          </div>
          <div class="d-flex align-items-center">
            <div class="col-auto me-3">
              <label
                v-if="verifyTime"
                class="form-label text-muted __bottom__label mb-0"
                >{{ contents.data.fields[1].title }}</label
              >
              <label v-else class="form-label text-muted __bottom__label mb-0">
                <a
                  href="#"
                  class="btn btn-sm btn-primary text-decoration-none"
                  @click="resendCode"
                  >{{ contents.data.fields[2].title }}</a
                ></label
              >
              <!-- <span class="ms-2 __text__primary fs-5"
                >{{ contents.data.fields[3].title }} {{ countDown }}</span
              > -->
            </div>
            <div v-if="verifyTime" class="progress col">
              <div
                class="progress-bar progress-bar-striped progress-bar-animated bg-primary"
                :style="`width: ${
                  (countDown * 100) / $store.state.resendTime
                }%`"
                role="progressbar"
                aria-label="Example with label"
                :aria-valuenow="countDown"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                {{ countDown }} s
              </div>
            </div>
          </div>
        </div>
        <div
          class="pt-1 mb-5 d-flex justify-content-center flex-column flex-lg-row"
        >
          <a
            v-for="button in contents.data.buttons"
            :key="button"
            @click="handleButtonAction(button.function)"
            class="btn btn-lg px-5 m-1"
            :class="button.class"
          >
            {{ button.title }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dataService from "../../../services/data";

export default {
  setup() {},
  data() {
    return {
      testFocus: false,
      verifyCode: "",
      verifyCodeErrorClass: "",
      tempCode: [],

      verifyTime: true,
      countDown: this.$store.state.resendTime,
      contents: {
        data: {},
        status: false,
      },
    };
  },
  computed: {
    // countDown() {
    //   return this.$store.state.resendTime
    // },
    userName() {
      return this.$route.query.id;
    },
    routePath() {
      return this.$route.path;
    },
    routeLocale() {
      return this.$route.params.locale;
    },
  },
  methods: {
    handleButtonAction(func) {
      switch (func.name) {
        case "redirectToPage":
          window.location = `/${this.routeLocale}/${func.args}`;
          break;
        default:
          
          break;
      }
    },
    getContents() {
      let data = {
        endPoint: `contents/r/${this.routePath.substring(4)}`,
        locale: `${this.routeLocale}`,
        data: {},
      };
      
      dataService
        .get(data)
        .then((res) => {
          this.contents = {
            data: res.data.data[0].contentJson,
            status: true,
          };
        })
        .catch((error) => {
          
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        });
    },
    verificationCode() {
      if (this.inputValidation()) {
        
        let data = {
          endPoint: "users/cc",
          locale: `${this.routeLocale}`,
          data: {
            userName: this.userName,
            verificationCode: this.verifyCode,
          },
        };
        dataService
          .post(data)
          .then((res) => {
            
            if (res.data.success) {
              window.location = `/${this.routeLocale}/new-password?id=${this.userName}&code=${this.verifyCode}`;
            }
            this.$notify({
              type: res.data.type,
              title: res.data.type,
              text: res.data.message,
            });
          })
          .catch((error) => {
            
            this.verifyCodeErrorClass = "wrong-inputs";
            this.$notify({
              type: error.response.data.type,
              title: error.response.data.type,
              text: error.response.data.message,
            });
          });
      }
    },
    inputValidation() {
      if (this.verifyCode.length == 0) {
        this.verifyCodeErrorClass = "wrong-inputs";
        return false;
      } else if (this.verifyCode.length < 6) {
        this.verifyCodeErrorClass = "wrong-inputs";
        return false;
      }
      return true;
    },
    countDownTimer() {
      if (this.countDown == 0) {
        this.verifyTimerSwitch();
      } else {
        if (this.countDown > 0) {
          setTimeout(() => {
            this.countDown -= 1;
            this.countDownTimer();
          }, 1000);
        }
      }
    },
    resendVerificationCode() {
      let data = {
        endPoint: "users/vc",
        locale: `${this.routeLocale}`,
        data: {
          userName: this.userName,
        },
      };
      dataService
        .post(data)
        .then((res) => {
          
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
        })
        .catch((error) => {
          
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        });
    },
    verifyTimerSwitch() {
      this.verifyTime = !this.verifyTime;
    },
    resendCode() {
      this.resendVerificationCode();
      this.verifyTime = true;
      this.countDown = this.$store.state.resendTime;
      this.countDownTimer();
    },
    codePattern(index) {
      let input = document.querySelector(`[name=code_${index}]`);
      this.tempCode[index - 1] = input.value;
      if (input.value && input.value.length === 1 && input.value != " ") {
        if (index != 6) {
          document.querySelector(`[name=code_${index + 1}]`).focus();
        } else if (index == 6) {
          this.verifyCode = this.tempCode.toString().replace(/,/g, "");
          this.verifyCode.length > 5 ? this.verificationCode() : null;
        }
      } else {
        if (index != 1) {
          document.querySelector(`[name=code_${index - 1}]`).focus();
        }
      }
    },
  },
  mounted() {
    this.getContents();
    
  },
  created() {
    this.countDownTimer();
  },
  updated() {
    if (this.$refs["testFocus"] && !this.testFocus) {
      this.$refs["testFocus"].focus();
      this.testFocus = true;
    }
  },
};
</script>

<style scoped>
.wrong-inputs {
  border: 2px solid !important;
  border-color: red !important;
  background-color: #fff;
}
.code__input__pattern {
  height: 60px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
