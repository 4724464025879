<template>
  <!-- begin::Content -->
  <div
    v-if="contents.status"
    class="d-flex justify-content-center align-items-center h-100 my-5 my-lg-0"
  >
    <div class="col-10 col-md-8 col-lg-6 mt-3 mt-lg-0">
      <h3 class="fw-boldest fs-2x">{{ contents.data.title }}</h3>
      <p class="text-muted mb-10">
        {{ contents.data.subTitle }}
      </p>
      <div v-for="fields in contents.data.row" :key="fields">
        <div
          v-for="(field, index) in fields"
          :key="index"
          class="card card__hover mb-5 border border-gray-300 p-3"
        >
          <a
            :href="`/${routeLocale}${field.href}`"
            class="text-decoration-none d-flex align-items-center justify-content-between mx-3"
          >
            <div class="m-2 d-flex align-items-center">
              <img :src="`${backendHost}/images/svg/${field.image}`" alt="" />
              <p class="fs-4 fw-bold text-dark m-2">{{ field.title }}</p>
            </div>
            <i class="bi fs-2x text-primary" :class="field.icon"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- end::Content -->
</template>

<script>
import dataService from "../../../services/data";
import serverConfigs from "../../../configs/server.json";

export default {
  setup() {},
  data() {
    return {
      contents: {
        data: {},
        status: false,
      },
    };
  },
  computed: {
    backendHost() {
      return serverConfigs["serverDomain"]["host"];
    },
    routePath() {
      return this.$route.path;
    },
    routeLocale() {
      return this.$route.params.locale;
    },
  },
  methods: {
    getContents() {
      let data = {
        endPoint: `contents/r/${this.routePath.substring(4)}`,
        locale: `${this.routeLocale}`,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          this.contents = {
            data: res.data.data[0].contentJson,
            status: true,
          };
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        });
    },
  },
  mounted() {
    this.getContents();
  },
};
</script>
